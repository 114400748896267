.lv-dividends-schedule-source-controls {
  width: 100%;
  margin-top: 0px;

  button {
    width: 147px;
    margin-right: $base-padding-y;

    &:last-child {
      margin-right: 0;
    }
  }
}

.lv-dividends-grid-title {
  padding-top: 5px;
}