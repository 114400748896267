.lv-change-password {
  min-width: 360px;

  .k-form-field {
    &.padded {
      padding: 8px 0;
    }

    &.padded-top {
      padding-top: 8px;
    }
  }

  .info-text {
    text-align: left;

    &.login-again {
      div {
        display: inline-block;
        color: $base-accent-color;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .logo {
    width: 60%;
    height: auto;
  }

  @include bp($point_4) {
    width: 100%;
    min-width: auto;
  }
}
