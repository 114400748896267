.k-grid {
  th {
    background-color: $header-bg;
    font-weight: 600;
    color: $base-text-color;
  }
}

.k-grid-content {
  outline: none;

  tr,
  td {
    cursor: default;
  }

  // Selected state
  td.k-state-selected,
  tr.k-selected {
    td {
      color: $base-accent-contrast-color;
      background-color: #25313f;
    }
  } 

  .k-alt {
    &.k-selected {
      td {
        color: $base-accent-contrast-color!important;
        background-color: #34404d;
      }
    } 
  }

  // Hover state
  tbody tr:hover,
  tbody tr.k-state-hover {
    color: $base-text-color;
    background-color: transparent;

    &.k-alt {
      background-color: $base-component-header-color;
    }
  }

  &:focus {
    outline: none;
  }
}