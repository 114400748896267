.lv-volatility-surface {

  .lv-volatility-surface-radio-buttons {
    margin-left: 20px;
  }
  .lv-datePicker-container {
    margin-left: 1px;
    .k-button {
      &.k-input-button {
        margin-right: 2px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

  }

  .lv-volatility-surface-container {
    .lv-advanced-grid {
      .k-grid {
        table {
          thead {
            //Volatility Surface has header row, which is transparent(invisible) and makes vertical distance between grid and above title larger.
            //This is solved with display none property.
            display: none;
          }
        }
      }
    }

    .lv-advanced-grid-stripped {
      &.lv-advanced-grid {
        .k-grid {
          table {
            tbody {
              tr {
                &:first-child {
                  td {
                    &:first-child {
                      border: transparent;
                    }
                  }
                }
                td {
                  border-right: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

