.lv-estimates-settings-section {
  .lv-input-field {
    height: 25px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .lv-estimates-radio-buttons {
    .lv-input-field {
      width: 225px;
    }
  }

  .lv-estimates-settings-section-price-format {
    .lv-input-field {
      width: 125px;
      padding-left: 2px;
      padding-top: 4px;

      &:last-child {
        width: 150px;
      }
    }
    .lv-label.lv-label-field.lv-checkbox-row-label {
      width: 239px;
      margin-right: 17px;
      padding-bottom: 5px;
      padding-top: 4px;
    }
    .lv-input-field.lv-field.lv-field--radio {
      .k-radio-label{
        margin-left: 12px;
      }
    }
  }

  .k-radio-label {
    margin-left: 13px;
  }
}
