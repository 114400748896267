.page-not-found {
  min-width: 420px;
  
  .number {
    font-size: 120px;
    font-weight: 300;
    margin-bottom: 12px;
  }
  
  .text {
    margin-bottom: 16px;
  }
}