.lv-tools-custom-selection-dialog {
  .lv-label {
    width: 200px;
  }

  .lv-custom-tools-selection-section {

    margin-top: 10px;

    &:first-child {
      margin-top: 0px;
    }
  }
}
