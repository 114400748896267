.lv-asw-terms {
  .lv-asw-terms-instrument-type {
    padding-top: 4px;
    padding-left: 6px;
  }

  .lv-flex-box {
    &:nth-child(1) {
      padding-top: 10px;
    }

    &.lv-flex-box--row {
      padding-left: 6px;
    }
  }
  .lv-input-field {
    &.date-field {
      margin-left: 1px;
    }

    &.lv-input-field--full {
      &.lv-input-field-instrument {
        width: 550px;
      }
    }
  }

  .lv-asw-type {
    .lv-input-field {
        .k-dropdownlist {
          min-width: 121px;
        }
    }
  }

  .lv-asw-strike-bond-redemption,
  .lv-asw-recall-spread {
    .lv-suffix {
      padding-left: 4px;
      padding-top: 5px;
    }
  }

  .asw-underlying-name {
    .lv-text-box {
      &.lv-text-box-searchable {
        input  {
          color: $base-color-orange;
        }
      }
    }
  }

  .lv-label {
    min-width: 160px;
  }

  .k-textbox {
    background-color: $base-input-background-color !important;
    border: 1px solid $base-background-color!important;
    padding: 4px 6px;

    &:focus:not(:hover) {
      border: 1px solid $base-color-blue !important;
    }
  }

  .lv-asw-fieldset {
    margin-top: 11px;
    margin-bottom: 3px;
    .lv-fieldset-panel {
      .lv-fieldset-panel-legend {
        left: 3px;
      }

      .lv-fieldset-panel-content {
        padding-left: 0px;
        padding-top: 2px;
        padding-right: 11px;
        padding-bottom: 8px;
        width: 282px;
  
      }

      &.lv-asw-conversion-additional-width {
        .lv-fieldset-panel-content {
          width: 328px;
        }
      }

      &.lv-reference-ir-swap-additional-width {
        .lv-fieldset-panel-content {
          width: 418px;
          padding-right: 0px;
        }
      }
    }

    &.lv-asw-fieldset-reference-ir-swap {
      margin-top: 3px;
      margin-bottom: 6px;
      .lv-fieldset-panel-content {
        width: 369px
      }
    }

    &.lv-asw-fieldset-floating-schedule {
      margin-top: 0px;
      .lv-fieldset-panel-content {
        width: 243px
      }
      .lv-fieldset-panel {
        .lv-fieldset-panel-legend {
          left: 4px;
        }
      }
    }
  }

  :last-child.lv-asw-terms-save-reload-controls {
    margin-top: 10px;
    position: absolute;
    right: 0px;

    .lv-icon-square {
      margin-right: 6px;
      width: 24px;

      .k-icon {
        line-height: normal;
      }

      &:nth-child(1) {
        height: 25px;
        padding: 0.5px 0px;

        .k-icon {
          font-size: 24px;
        }
      }

      &:nth-child(2) {
        height: 24px;
        padding: 2px;
        margin-right: 8px;

        .k-icon {
          font-size: 20px;
        }
      }
    }
  }
}