.lv-environments-settings {
  
  .lv-environments-settings-control-panel {
    height: 49px;
    padding: 0 20px;
    border-top: 1px solid $base-border-color;
    justify-content: flex-end;

    button {
      width: 90px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}