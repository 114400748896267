.lv-environment-setup-settings {
  padding-left: 8px;
  padding-top: 6px;

  .lv-environment-setup-settings-table {

    .lv-environment-setup-settings-table-row {
      display: flex;
      flex-direction: row;
      height: 25px;

      &:last-child {
        .lv-environment-setup-settings-table-row-cell {
         &.lv-environment-setup-settings-table-row-data-cell {
            border-bottom-width: 1px;
          } 
        }
      }

      &.lv-environment-setup-settings-table-row-gray {
        .lv-environment-setup-settings-table-row-cell {
          background-color: $base-component-header-color;
        }
      }

      .lv-environment-setup-settings-table-row-cell {
        width: 70px;
        text-align: left;
        padding-left: 5px;
        padding-top: 3px;

        &:nth-child(1) {
          width: 125px;
        }
  
        &:nth-child(2) {
          width: 400px;
        }

        &.lv-environment-setup-settings-table-row-data-cell {
          border: 1px solid $base-border-color;
          border-width: 1px 0 0 1px;

          &:last-child {
            border-right-width: 1px;
          }
        }
  
        .lv-label {
          height: 25px;
        }
      }

    }
  }
}
