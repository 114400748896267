.lv-edit-system-environment-settings-dialog {
  .lv-edit-system-environment-settings-dialog-content {
    height: 109px;

    .lv-sortable-list {
      display: flex;
      width: 277px;
      height: 35px;
      margin: auto;
      padding: 4px;
      margin-top: 10px;

      .lv-edit-system-environment-settings-dialog-content-item,
      .lv-edit-system-environment-settings-dialog-content-item--active {
        display: flex;
        width: 135px;
        border: 1px solid $base-border-color;
        height: 25px;
        padding-top: 4px;
  
        &:first-child {
          border-right: none;
        }
  
        &:last-child {
          border-right: 1px solid $base-border-color;
        }

        .k-icon {
          margin-left: 4px;
          font-size: 17px;
        }
  
        .lv-edit-system-environment-settings-dialog-content-item-label {
          margin-left: -2px;
        }
      }

      .lv-edit-system-environment-settings-dialog-content-item--active {
        background-color: $base-accent-highlight-color;
      }

      &.lv-edit-system-environment-settings-dialog-content--active {
        border-color: $base-color-orange;
      }
    }

    .lv-edit-system-environment-settings-dialog-content-checkbox {
      margin-left: 14px;
      margin-top: 20px;
      display: flex;

      .lv-label {
        width: 240px;
      }

      .lv-edit-system-environment-settings-dialog-content-checkbox-wrapper {
        padding-top: 3px;
      }
    }    
  }

  .lv-edit-system-environment-settings-dialog-control-panel {
    height: 49px;
    padding: 0;
    border-top: 1px solid $base-border-color;
    justify-content: flex-end;
    padding-right: 14px;
    padding-top: 3px;

    button {
      width: 90px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 15px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.lv-edit-system-environment-settings-dialog-wrapper {
  .k-window-content {
    padding-bottom: 0;
  }
}
