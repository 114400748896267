.lv-equity-terms-settings {
  height: 100%;
  .lv-terms-equity-settings {
    height: inherit;
    flex: 1;
    overflow: hidden;
    .lv-terms-section-settings-system-defaults {
      padding-top: 8px;
    }
  }
}