.lv-new-issue-section-settings {
  .lv-input-field {
    margin-right: 15px;
    padding-left: 6px;
    height: 25px;
    width: 95px;

    .k-radio-label {
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 3px;
      width: 105px;
    }
  }

  .lv-checkbox-column-label {
    width: 120px;
    padding: 6px 24px 0 0;
    margin-right: -3px;
    color: #a5a6a9;
  }

  .lv-field.lv-field--radio {
    .k-radio {
      margin-bottom: 0px;
      margin-top: 5px;
      margin-left: 3px;
    }
  }

  .lv-field.lv-field--radio .k-radio-label {
    padding-left: 1px;
    padding-top: 4px;

    &:nth-child(2) {
      margin-left: 12px;
    }
  }
}
