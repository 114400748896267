.lv-variable-conversion {

  .lv-label-short {
    width: 142px;
  }

  .lv-variable-first-col {
    min-width: 302px;
  }

  .lv-lock-in-checkbox {
    margin-left: 7px;
    margin-right: 0px;
  }
}
