.k-dialog-wrapper {
  .k-dialog-titlebar {
    height: 25px;
    padding-right: 2px;
  }

  .k-window-titlebar-actions {
    &.k-dialog-titlebar-actions {
      width: 23px;
      height: 21px;
    }

    &:hover {
      border-radius: 2px;
      height: 21px;
      background-color: #3098e3;
    }
  }
}