.lv-estimates-settings-dialog {

  label {
    color: $base-text-color-dimmed;
  }

  .lv-estimates-settings-dialog-content {

    padding-left: 10px;
    padding-top: 8px;

    .lv-label {

      &:last-child {
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .lv-pricing-estimates-settings-dialog-tabstrip {
    height: 295px;
    border-bottom: #41454d solid 1px;

    .k-tabstrip-content {
      border:  none;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 8px;
    }

    .lv-estimates-settings-environment-checkbox-label {
      width: 170px;
    }
  }

  .lv-estimates-settings-dialog-separator {
      border-bottom: $base-text-color-dimmed solid 1px;
      margin-bottom: $base-padding-x - 3;
      margin-top: $base-padding-x - 3;
  }

  .lv-pricing-estimates-settings-dialog-footer {
    align-items: center;
    justify-content: flex-end;
    padding-top: 4px;
    padding-right: 0px;
    padding-bottom: 4px;
    margin: 5px 15px 2px 0;

    button {
      width: 90px;
      margin-bottom: 4px;

      &:last-child {
        margin-right: 5px;
        margin-left: 12px;
      }
    }
  }
}
