.lv-instrument-source-settings-dialog-radio-buttons {

  .lv-input-field {
    margin-right: 15px;
    height: 25px;
    width: 95px;

    .k-radio-label {
      margin-left: 13px;
      margin-top: 2px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 11px;
      width: 105px;
    }
  }
  .lv-label.lv-label-field.lv-checkbox-row-label {
    width: 140px;
    padding: 4px 24px 4px 0;
  }
}