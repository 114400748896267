.lv-model-customization-section-settings {
  .lv-model-customization-section-column {
    width: 312px;
    margin-right: 85px;

    &.second {
      width: 340px;
      margin-right: 61px;
      .lv-model-customization-section-column-include-terms {
        margin-bottom: 10px;
      }

      .lv-label {
        width: 180px;
      }
    }

    &.third {
      width: 335px;
      margin-right: 0;
    }
  }
}
