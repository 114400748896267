.lv-asw-conversion {
 .lv-asw-conversion-exercise-dates {
  .lv-label {
    min-width: 140px;
    max-width: 140px;
  }

  .lv-field {
    &.lv-field--checkbox {
      .k-checkbox {
        margin-left: 2px;
      }
    }
  }
 }

 .lv-asw-early-exercise-fee-title {
    margin-top: 5px;
 }

 .lv-asw-early-exercise-fee-field {
    .lv-label {
      &.lv-label-field {
        min-width: 180px;
      }
    }
    .lv-suffix {
      padding-left: 4px;
      padding-top: 5px;
    }
    &.lv-early-exercise-date {
      .lv-label {
        &.lv-label-field {
          min-width: 162px;
        }
      }
    }
  }
}