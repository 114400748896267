.lv-model-customization-bond-section-container{

  &.lv-flex-box {
    &.lv-flex-box--column {
      flex-direction: row;
    }
  }

  .lv-model-customization-bond-section-other {
    margin-left: 73px;
    .lv-label--title {
      padding-top: 5px;
    }
  }
}