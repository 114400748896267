.lv-contingent-conversion {

  .lv-contingent-first-col {
    min-width: 310px;
  }

  .lv-contingent-schedule {
    margin-top: 5px;
    .lv-contingent-conversion-schedule {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
            &.k-grid-edit-row {
            .lv-input-field {
              .k-dropdownlist {
                min-width: auto;
                &.k-focus,  &.k-selected,  &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
              &:last-child {
                .lv-input-field {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                  .k-dropdownlist {
                    &.k-focus,  &.k-selected, &:not(:focus) {
                      border-top: 1px solid $base-background-color;
                      border-bottom: 1px solid $base-background-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .trigger-period-type {
    width: 191px;
  }

  .lv-trigger-type {
    width: 175px;
  }

}
