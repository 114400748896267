.lv-scenario {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: $base-padding-y;

  .lv-scenario-header {
    display: flex;
    flex-direction: row;

    .lv-scenario-header-item {
      &.dimensions {
        min-width: 80px;
        margin-right: $base-padding-x * 2;
        padding-top: 2px;
      }

      &.inputs {
        flex: 1;
        overflow-y: hidden;
        overflow-x: auto;

        .second-dimension {
          .lv-scenario-inputs {
            margin-top: $base-padding-x * 2;
          }
        }

        &.inputs--two-dimensional {
          .lv-scenario-inputs {
            .lv-scenario-input {
              &.shift {
                .lv-label {
                  min-width: 86px;
                }
              }
            }
          }
        }
      }

      &.price-talk {
        display: inline-flex;
        flex-direction: row;
        
        margin-left: $base-padding-x;
        margin-right: $base-padding-x;
        
        .lv-label {
          margin-right: $base-padding-x;
          width: 54px;
        }
        
        .k-multiselect--not-empty {
          kendo-taglist {
            background-color: $base-input-background-color;
            .k-chip {
              border: 1px $base-border-color;
              background-color: $base-color-blue;
              border-radius: 2px;
              margin: 1px 0px 1px 1px;

              &:not(:hover) {
                color: $base-text-color;
              }

              .k-text-ellipsis {
                font-size: 12px;
              }
            }
          }
          .k-clear-value {
            display: none;
          }
        }
      }

      &.options {
        min-width: 40px;

        .lv-field {
          display: inline-flex;
          margin-right: $base-padding-x;
          
          .lv-icon {
            font-size: $base-font-size + $base-padding-y;
            line-height: $base-font-size + $base-padding-y;
            margin: auto 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
  
      .k-multiselect--inline {
        .k-searchbar {
          width: auto;
          max-width: 230px;
          margin-left: 0px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lv-scenario-content {
    flex: 1;
    position: relative;
    margin-top: 4px;
    overflow: hidden;

    .k-splitter {
      border: 1px solid $base-border-color;
    }

    .k-splitter {
      width: 100%;
      height: 100%;

      .lv-scenario-splitter-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        padding: $base-padding-y;
      }
    }
  }

  .lv-scenario-settings {
    .scenario-settings-checkbox {
      text-align: right;
    } 
  }
}