.lv-private-instrument {
  width: 100%;
  height: 100%;
  min-width: 250px;

  .lv-dates-logic {
    display: inline-block;
    width: $base-padding-x * 2;
    height: $base-padding-x * 2;

    .k-icon.k-i-gear {
      font-size: $base-font-size;
      color: $base-icon-color;

      &:hover {
        cursor: pointer;
        color: $base-text-color;
      }
    }
  }

  .lv-environment-settings-controls {
    position: absolute;
    top: $base-padding-y;
    right: $base-padding-x;

    z-index: 2;

    @include lv_icon_square(24px, 2px);

    .lv-icon-square {
      margin-right: $base-padding-x;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lv-private-instrument-convertible-bond-terms {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .lv-icon-disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }
}
