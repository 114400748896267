$notification-panel-angle: -45deg;
$link-icon-size: 18px;

.lv-notification-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .lv-notification-panel-header {
    display: flex;
    flex-direction: row;    
    width: 100%;

    padding: $base-padding-y $base-padding-x;

    .lv-notification-panel-header-content {
      flex: 1;
      overflow: hidden;

      font-size: 9px;
      text-transform: uppercase;
    }

    .lv-notification-panel-header-options {
      display: flex;
      flex-direction: row;
      user-select: none;
      
      .lv-notification-panel-header-option {
        display: flex;
        width: $link-icon-size;
        height: $link-icon-size;      
        margin: auto;
        margin-left: 4px;

        color: rgba($base-color-white, 0.8);

        .lv-notification-panel-link {
          width: $link-icon-size;
          height: $link-icon-size;
          line-height: $link-icon-size - 2px;

          border-radius: 50%;

          text-align: center;

          .k-icon {
            font-size: 14px;
          }

          .k-i-link-vertical {
            &.rotate-left {
              -webkit-transform: rotate($notification-panel-angle);
              -moz-transform: rotate($notification-panel-angle);
              -o-transform: rotate($notification-panel-angle);
              -ms-transform: rotate($notification-panel-angle);
              transform: rotate($notification-panel-angle);
            }
          }
        }
        

        &:hover {
          cursor: pointer;
          color: $base-text-color-highlight;
        }
      }

      &.top {
        .lv-notification-panel-header-option {
          margin: 0;
          margin-bottom: auto;
          margin-left: 4px;
        }
      }

      &.bottom {
        .lv-notification-panel-header-option {
          margin: 0;
          margin-top: auto;
          margin-left: 4px;
        }
      }
    }
  }

  .lv-notification-panel-content {
    flex: 1;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  &.lv-notification-panel-floating-header {
    position: relative;

    .lv-notification-panel-header {
      position: absolute;
      top: $base-padding-y;
      right: 0;
      width: auto;
      z-index: 1;
    }
  }
}