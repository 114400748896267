.lv-asset-swap-settings {
    height: 100%;

    .lv-asset-swap-terms-settings {
      height: inherit;
      flex: 1;
      overflow: hidden;
      .lv-terms-section-settings-system-defaults {
        padding-top: 8px;
      }
    }
  }