.lv-convertible-bond-terms-estimates {
  width: 921px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-estimates {
    &--padded {
      padding-left: 10px;
    }
    &-value {
      display: flex;
      align-items: center;
    }
    &-label {
      width: 193px;
    }
    &-space {
      margin-left: 12px;
    }
    &-schedule {
      margin-top: 10px;
    }
  }
}
