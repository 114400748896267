.terms-puts-put-parameters {
    flex: 0.3333;
    margin-right: 5px;

    .k-checkbox-div {
      vertical-align: middle;
      width: 20px;
      margin: auto 0;
      height: 26.797px;
    }

    .lv-puts-put-schedule {
      height: 300px;
    }
  
    :last-child {
      margin-right: 0;
    }

    .lv-flex-box {
      &.lv-flex-box--row {
          .lv-label {
            &.lv-label-suffix {
              margin-top: 4px;
              margin-left: 5px;
            }
          } 
        }
    }
  }