.lv-equity-sync-settings-modal {

  .lv-equity-sync-settings-modal-content {
    height: 43.5px;
    flex-direction: row;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    padding-left: 10px;
  
    .k-checkbox {
      margin-top: 7px;
    }

    .lv-label-field {
      width: 190px;
    }
  }

  .lv-equity-sync-settings-modal-footer {
    width: 100%;
    height: 41px;
    border-top: $base-border-color solid 1px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 90px;

      &:first-child {
        &:not(:hover) {
          color: $base-text-color;
        }
      }

      &:last-child {
        margin-right: $base-padding-x*2-2;
        margin-left: 15px;
      }
    }
  }
}