.lv-market-data-setting-wrapper {
  .lv-market-data-settings-container {
    height: 100%;

    .lv-credit-equity-option-settings {
      .lv-section-settings-base {
        .lv-section-settings-system-defaults {
          padding-top: 7px;
        }
      }
    }
  }
}