.k-checkbox-label {
  color: $base-text-color-dimmed;

  &:empty {
    display: inline-flex!important;
    min-width: 1px;
    min-height: 1px;
    margin-left: $base-padding-x - 1px !important;
  }

  &.xl-label {
    margin-right: 3px;
  }
}