.lv-break-even {

  $line-height: 25px;

  position: relative;
  flex-wrap: wrap;

  padding-top: $base-padding-x + 2;

  .lv-label {
    width: 130px;
    line-height: $line-height;

    &.lv-label-top {
      width: 104px;
    }

    &.lv-label-to-bottom{
      padding-top: $line-height
    }
  }

  .lv-input-field {
    width: 104px;
  }

  .lv-output {
    height: $line-height;

    .lv-negative-label {
      width: 100%;
      display: flex;
      flex: 1;
      height: 100%;

      .lv-negative-label-border {
        width: 104px;
        display: flex;
        flex: 1;
        padding: 0 $base-padding-x;
      }

      .prefix {
        width: 100% !important;
        overflow: hidden;
      }

      .suffix {
        width: 31px;
        padding-left: $base-padding-y;
      }

    }
  }

  .lv-label-suffix {
    width: 31px;
    padding-left: $base-padding-y;
  }

  .break-even-top-content {
    min-width: 280px;
    padding: 0 $base-padding-x + 2;
  }

  .break-even-bottom-content {
    min-width: 280px;
    padding: $base-padding-x + 2;
    padding-top: 0;
  }
}
.popup-breakevan-content {
  max-width: 400px;
  min-width: 320px;
  text-align: justify;
}
