.lv-funding {
  width: 100%;
  height: 100%;
  min-width: 315px;

  .lv-funding-content {
    flex: 1;
    overflow: auto;
  }
}

