.lv-lws-event {

  .lv-lws-event-regions {
    margin-left: 7px;
    width: 375px;
    align-items: center;

    .lv-lws-event-regions-checkbox {
      .k-checkbox-label {
        margin-left: 11px;
        width: 50px;
      }

      &:nth-child(2) {
        margin-left: 17px;
      }

      &:nth-child(3) {
        margin-left: 17px;
      }

      &:nth-child(4) {
        margin-left: 21px;
      }
    }
  }

  .lv-lws-event-notification-type {
    align-items: center;

    .lv-lws-event-notification-type-item {
      .k-radio-label {
        width: 63px;

        &.lv-lws-event-notification-type-item-bulk-label {
          width: 58px;
        }

        &.lv-lws-event-notification-type-item-single-bulk-label {
          width: 75px;
        }
      }
    }
  }
}

.lws-event-tooltip {
  max-width: 400px;
  min-width: 320px;
  text-align: justify;
}
