@mixin align_center {
  margin: 0 auto;
  min-width: 400px;
  max-width: 1200px;
  padding: 10px 0;
}

.lv-editor-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 10px;

  .lv-editor-panel-header-wrapper {
    border-bottom: 1px solid $base-component-border-color;
    font-size: $base-font-size + 4px;
    
    .lv-editor-panel-header {
      @include align_center;
    }
  }

  .lv-editor-panel-content-wrapper {
    position: relative;
    flex: 1 1 auto;
    overflow: auto;    

    .lv-editor-panel-content {      
      @include align_center;
      
      min-width: 800px;
      padding: 10px;

      .lv-editor-panel-error {
        font-size: $base-font-size + 2px;
      }

      .lv-editor-panel-section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 20px;

        .lv-editor-panel-section-description {
          width: 40%;

          .lv-editor-panel-section-title {
            font-size: $base-font-size + 2px;
            margin-bottom: 8px;
          }

          .lv-editor-panel-section-info {
            color: $base-text-color-dimmed;
          }
        }

        .lv-editor-panel-section-content {
          flex: 1 1 auto;
          padding: 10px;
          border: 1px solid $base-component-border-color;
        }
      }

      &.is-loading {
        @include loading_state;
      }
    }
  }

  .lv-editor-panel-footer-wrapper {
    border-top: 1px solid $base-component-border-color;
    text-align: right;

    .lv-editor-panel-footer {
      @include align_center;

      button {
        width: 100px;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
