@mixin lv_icon_square($font-size, $padding: 0px) {
  .lv-icon-square {
    height: $font-size;
    width: $font-size;
    padding: $padding;

    .k-icon {
      line-height: $font-size - ($padding * 2);
      font-size: $font-size - ($padding * 2);
    }
  }
}