.lv-save-terms-detailed-modal {
    width: 600px;
    height: 136px;
    margin-left: 8px;
    margin-top: 1px;

    
    .lv-save-terms-detailed-modal-input {
      margin-bottom: 20px;

      .lv-save-terms-detailed-modal-instrument {
          width: 430px;
      }
    }

    .accessScope-section {
      margin-bottom: 10px;
    }

    .lv-save-terms-detailed-modal-footer {
        height: 41px;
        border-top: $base-border-color solid 1px;
        align-items: center;
        justify-content: flex-end;
    
        button {
          width: 90px;
    
          &:last-child {
            margin-right: $base-padding-x*2-2;
            margin-left: $base-padding-x*2;
          }
        }
    }
}
