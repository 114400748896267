.lv-new-issue-settings-dialog {
  height: 225px;

  .k-window-titlebar-actions {
    &.k-dialog-titlebar-actions {
      width: 23px;
      padding-right: 3px;
      padding-top: 1px;
    }
  }

  label {
    color: $base-text-color-dimmed;
  }

  .lv-new-issue-settings-dialog-content {

    padding-left: 6px;
    padding-top: 4px;

    .lv-label {
      &.lv-label--title {
        margin-bottom: 0px;
        margin-top: -1px;
      }
      &.lv-label--title:last-child {
        margin-bottom: 5px;
        margin-top: 8px;
      }
    }
  }

  .lv-new-issue-settings-dialog-radio-buttons {

    &:first-child {
      margin-top: 0;
    }

    div {
      margin-right: 15px;
      height: 25px;
      width: 95px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-right: 10px;
        width: 105px;
      }
    }
  }

  .lv-pricing-new-issue-settings-dialog-tabstrip {
    height: 95px;
    width: 445px;
    border-bottom: $base-border-color solid 1px;
    .k-tabstrip-content {
      border:  none;
      padding-top: $base-padding-x + 2;
      padding-left: $base-padding-x + 2;
    }

    .lv-new-issue-settings-environment-checkbox-label {
      width: 164px;
    }

    .lv-new-issue-settings-dialog-radio-buttons {
      padding-top: 6px;
      &li:last-child {
        padding: 2px 4px 2px 3px
      }
    }

    .k-tabstrip {
      .k-tabstrip-items-wrapper {
        .k-tabstrip-items {
          &:first-child {
            .k-item {
              .k-link {
                padding: 2px 6px 3px 5px;
              }
            }
          }
        }
      }
    }
  }

  .lv-pricing-new-issue-settings-dialog-footer {
    height: 39px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 90px;

      &:first-child {
        margin-right: 2px;
        margin-top: 5px;
      }
      &:last-child {
        margin-right: 4px;
        margin-left: 12px;
        margin-top: 5px;
      }

      color: $base-color-white;
    }
  }
}
