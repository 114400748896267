.lv-environment-settings {

  @include lv_icon_square(24px, 2px);

  padding: $base-padding-x + 2;

  .lv-environment-settings-button--shared {
    width: 53px
  }

  .lv-environment-settings-button--my {
    width: 30px
  }

  .lv-icon-square {

    margin-right: $base-padding-x;

    &:last-child {
      margin-right: 0;
    }
  }
}
