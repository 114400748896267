.lv-mail-notifications-settings {
  overflow: auto;
  height: 100%;

  .lv-mail-notifications-settings-content {
    margin-top: 6px;
    margin-left: 8px;
    height: 100%;

    .lv-label {

      &.lv-mail-notifications-settings-label {
        color: $base-text-color-dimmed;
        width: 280px;
        margin-right: 20px;
      }

      &.lv-label--title {
        margin-top: 5px;
      }
    }

    .lv-mail-notifications-settings-time-zones {
      width: 289px;
      
      .lv-mail-notifications-settings-time-zones-dropdown {
        border-radius: 2px;
        border: 0px;
        padding-top: 4px;
        padding-left: 7px;

        span {
          color: $base-text-color;
          background-color: $base-input-background-color;
        }
      }
    }

    .lv-mail-notifications-settings-time-picker {
      width: 85px;
      margin-right: 10px;
      border-radius: 2px;

      .k-picker-wrap {

        .k-dateinput-wrap {
          .k-input {
            background-color: $base-input-background-color;
            padding-right: 1px;
          }
        }

        .k-select {
          background-color: $base-input-background-color;

          .k-icon {
            font-size: 17px;
          }
        }
      }
    }
  }
}

.k-time-list-wrapper.k-focus {
    .k-title{
      color: #000;
      opacity: 2;
    }
    background-color: #213543;
}

.k-time-now {
  &:hover {
    background-color: #334755!important;
  }
}

.popup-mail-notifications-tooltip {
  max-width: 400px;
  min-width: 320px;
  text-align: justify;
}

.lv-mail-notifications-settings-time-zones-dropdown-popup {
  li {
    &.k-focus {
      background-color: $base-accent-highlight-color;
    }
  }
}