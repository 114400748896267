
.lv-equity {
  height: 100%;
  .lv-equity-container {
    height: 100%;
    position: relative;

    .lv-equity-content {
      height: inherit;
      .lv-equity-market-data-container {
        height: 100%;
        border: 1px solid $base-border-color;
        border-top-color: transparent;
      }
      .k-tabstrip-items-wrapper {
        .k-tabstrip-items {
          .k-item {
            &:first-child {
              .k-link {
                padding: 2px 5px 2px 4px;
              }
            }
            border-width: 1px;
            border-left-color: transparent; 
            .k-link {
              padding: 2px 4px;
            }
            &.k-disabled {
              opacity: 0.6;
              filter: grayscale(0.1);
              pointer-events: none;
              box-shadow: none;
          }
          }
        }
      }
    }

    .lv-equity-cog {
      position: absolute;
      top: 1px;
      right: 6px;
      line-height: normal;
    
      .k-icon.k-i-gear {
        font-size: $base-font-size;
        color: $base-icon-color;
    
        &:hover {
          cursor: pointer;
          color: $base-text-color;
        }
      }
    }

    .k-tabstrip-content {
      outline: unset;

      .lv-market-data-equity-borrow,
      .lv-equity-market-data-volatility {
        .lv-equity-market-data-tabstrip {
          .k-tabstrip-items-wrapper {
            .k-tabstrip-items {
              background-color: $base-background-color;
              border-top: 1px solid $base-border-color;
              border-bottom: 1px solid $base-border-color;
              height: 21px;
                .k-item {
                  &:first-child {
                    .k-link {
                      padding: 2px 5px 2px 4px;
                    }
                  }
                  background-color: $base-background-color;
                  border-width: 1px;
                  border-left-color: transparent; 
                  .k-link {
                    padding: 2px 4px;
                  }
                }
            }
          }
        }
      }
    }
  }

  .lv-equity-side-panel {
    .lv-side-panel {
      min-height: 41px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
      cursor: pointer;

      &.lv-side-panel--floating {
        &.lv-side-panel-right {
          .lv-sync-all-equity-data {

            .lv-label {
              font-weight: 600;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@mixin icon-square-base {
  width: 24px;
  height: 24px;
  .k-icon {
    line-height: normal;
  }
}

@mixin icon-square-first {
  @include icon-square-base;
  padding: 0.5px 0;
  .k-icon {
    font-size: 22px;

    &.k-i-edit {
      line-height: 20px;
      font-size: 20px;
    }
  }
}

@mixin icon-square-second {
  @include icon-square-base;
  padding: 2px;
  margin-right: 8px;
  .k-icon {
    font-size: 20px;
  }
}

.lv-equity {
  .lv-environment-settings {
    padding-top: 10px;
    padding-left: 7px;
    .lv-icon-square {
      &:nth-child(1) {
        @include icon-square-first;
      }

      &:nth-child(2) {
        .k-icon {
          font-size: 20px;
        }
      }
    }
  }

  .lv-equity-save-reload-controls,
  .lv-equity-terms-save-reload-controls {
    .lv-icon-square {
      @include icon-square-base;
      margin-right: 6px;

      &:nth-child(1) {
        @include icon-square-first;
      }

      &:nth-child(2) {
        @include icon-square-second;
      }
    }
  }

  .lv-equity-terms-save-reload-controls {
    position: absolute;
    bottom: 225px;
    right: 0;
  }
}


.k-tabstrip-content {
  .underlying-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}