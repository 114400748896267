.lv-instrument-monitor-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .lv-instrument-monitor-panel-header {
    display: flex;
    flex-direction: row;

    padding: $base-padding-y $base-padding-x;

    .lv-instrument-monitor-panel-header-spacer {
      flex: 1;
    }

    .lv-instrument-monitor-panel-header-settings {
      display: flex;
      @include field_height;

      .lv-instrument-monitor-panel-header-setting {
        width: $base-font-size;
        height: $base-font-size;
        line-height: $base-font-size;
        color: $base-text-color;

        margin: auto 0;

        .k-icon {
          font-size: $base-font-size;
          color: $base-icon-color;
          vertical-align: top;
        }

        &.loading {
          @include loading_state;

          &:hover {
            cursor: default;
          }
        }

        &:hover {
          cursor: pointer;

          .k-icon {
            color: $base-text-color;
          }
        }
      }
    }

    &.loading {
      @include loading_state;
    }
  }

  .lv-instrument-monitor-panel-content {
    flex: 1;
    overflow: hidden;

    &.loading {
      @include loading_state;
    }
  }

  .centered-spinner {
    z-index: 1;
  }
}