@mixin lv_watch_list_item_selectable() {
  padding: $base-padding-y $base-padding-x;
  padding-left: 12px;
  
  cursor: pointer;

  &.lv-watch-list-item--normal-padding {
    padding-left: $base-padding-x;
  }

  &.lv-watch-list-item--selected {
    color: $base-accent-contrast-color;
    background-color: $base-accent-highlight-color;
  }
  
  &:hover {
    color: $base-accent-contrast-color;
    background-color: $base-accent-highlight-color;
  }
}

.lv-watch-list-item,
.lv-watch-list-group {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.lv-watch-list-item {
  @include lv_icon_square(16px);
  
  .lv-icon-square {
    float: right;
  }

  &.lv-watch-list-item--disabled {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}

.lv-watch-list-group {
  color: $base-accent-color;
  text-transform: uppercase;
  font-size: $base-font-size - 2px;
  letter-spacing: 1px;
}