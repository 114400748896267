.lv-market-data-settings {

  .lv-section-settings {
    height: 100%;

    .lv-section-settings-content {
      padding: 6px 8px 0px 8px;
    }

    .lv-section-settings-checkbox {
      padding-top: 4px;
    }

    .lv-section-settings-tabstrip {
      height: 100%;

      .k-tabstrip-content {
        height: 100%;
        border: none;
      }

      .lv-section-settings-content {
        padding: 0;
      }
    }
  }
}