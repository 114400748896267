button,
.k-button {
  @include field_height;
  padding: $base-padding-y $base-padding-x;
  width: 100%;

  &.k-button-solid-primary {
    color: $base-color-white;
  }
}

.k-dialog-buttongroup .k-button {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: inherit;
  color: inherit;

  :hover {
    background-color: $base-accent-highlight-color;
  }

  &.k-primary {
    color: $base-accent-highlight-color;
    background-color: inherit;
    :hover {
      color: inherit;
    }
  }
}
