.lv-volatility {
  width: 100%;
  height: 100%;

  .lv-volatility-content {
    flex: 1;
    overflow: auto;
    min-width: 325px;

    .lv-volatility-grid-title {
      padding-top: 5px;
    }

    .lv-volatility-checkbox {
      padding-bottom: 4px;
      padding-top: 3px;
    }

    .lv-equity-risky-risk-free {
      .lv-input-field {
        margin-top: 4px;
        margin-left: 68px;
      }
    }

    .lv-equity-volatility {
      .lv-input-field {
        margin-top: 5px;
        margin-left: 81px;
        .lv-suffix-no-value {
          padding-left: 21px;
        }
      }
    }

    .lv-equity-volatility-surface-reference-stock-level {
      .lv-input-field {
        margin-top: 5px;
        margin-left: 82px;
        .lv-suffix-no-value {
          padding-left: 21px;
        }
      }
    }

    .lv-equity-volatility-surface-base-date {
      .lv-input-field {
        margin-top: 5px;
        margin-left: 40px;
      }
    }

    .lv-equity-volatility-surface-use-strike-vol {
      .lv-field {
        margin-top: 2px;
        margin-left: 84px;
      }
    }
  }

  .lv-volatility-terms-structure-grid {
    &.lv-advanced-grid {
      .k-grid {
        table {
          // Move text in header cells to left, so it is in line with Zeplin.
          thead {
            tr {
              th {
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

