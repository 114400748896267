.lv-basic-terms {

  .lv-settings-widget-content {
    padding: 0;
  }

  .lv-basic-terms-content {

    $line-height: 25px;
    flex-wrap: wrap;
    padding: $base-padding-y * 2 0;

    .lv-label {
      width: 160px;
      min-width: 160px;
      line-height: $line-height;
    }

    .lv-output {
      height: $line-height;
      width: 150px;
      line-height: $line-height;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .lv-basic-terms-top-section,
    .lv-basic-terms-bottom-section {
      width: 310px;
      min-width: 300px;
      margin-left: 8px;
      margin-right: 7px;
      flex: 1;
    }

    .lv-basic-terms-bottom-section {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .popup-terms-content {
    max-width: 400px;
    text-align: justify;

    li {
      padding-bottom: calc($base-padding-x / 2);
    }
  } 
}
