.lv-quick-terms-entry {
  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-input-field {
    &.lv-input-field-instrument {
      width: 440px;
    }
  }

  .lv-quick-terms-sufix {
    margin: $base-padding-y;
    color: $base-text-color-dimmed;
  }

  .lv-quick-terms-small-input {
    width: 80px;
    min-width: 80px;
  }

  .lv-quick-terms-input-threshold {
    width: 90px;
  }

  .lv-quick-terms-input {
    width: 120px;
  }

  .lv-quick-terms-big-input {
    width: 170px;
  }

  .lv-quick-terms-checkbox {
      margin-left: 7px;
  }

  .lv-quick-terms-stock-ref {
    .lv-field.lv-field--checkbox {
      margin-right: 0px;
    }
  }

  .lv-label-before-checkbox {
    width: 135px;
  }

  .lv-terms-quick-terms-right-section {
    margin-left: 32px;
  }

  .lv-quick-terms-fieldset {
    margin-top: $base-padding-x;
    .lv-label {
      width: 152px;
    }
    .lv-quick-terms-small-input {
      .lv-label {
        width: 80px;
        text-align: center;

        &.lv-label-field-left {
          text-align: left;
        }
      }
    }
    .lv-label-before-checkbox {
      width: 127px;
    }
  }

  .lv-fieldset-panel {
    .lv-fieldset-panel-content {
      padding: 12px 8px 8px;
      width: 802px;
    }
  }

  .lv-pricing-range {
    margin-top: 2 * $base-padding-x;
    .lv-fieldset-panel-content {
      padding: 0px 8px 8px 8px;
    }
  }
}
