.lv-peps-conversion {

  .lv-label {
    width: 240px;
  }

  .lv-label-short {
    width: 222px;
  }

  .lv-peps-checkbox {
    margin-left: 7px;
    margin-right: 0px;
  }
}
