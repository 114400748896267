.lv-equity-terms {
  .lv-equity-terms-instrument-type-output {
    padding-top: 4px;
    padding-left: 6px;
  }

  .lv-flex-box {
    &:nth-child(1) {
      padding-top: 10px;
    }

    &.lv-flex-box--row {
      padding-left: 9px;
    }
  }
  .lv-input-field {
    &.lv-input-field--full {
      &.lv-input-field-instrument {
        width: 440px;
      }
    }
  }

  .lv-label {
    min-width: 160px;
  }

  .k-textbox {
    background-color: $base-input-background-color !important;
    border: 1px solid $base-background-color!important;
    padding: 4px 6px;

    &:focus:not(:hover) {
      border: 1px solid $base-color-blue !important;
    }
  }
  .lv-equity-terms-country,
  .lv-equity-terms-risk-country {
    min-width: 118px;
  }

  .lv-equity-type,
  .lv-equity-ccy,
  .lv-equity-exchange {
    min-width: 80px;
  }

  .lv-equity-price-scale {
    min-width: 80px;
    max-width: 80px;
    display: flex;
    justify-content: space-around;
      input {
        color:#eee;
      }
  }
}