.lv-market-data-widget {
  display: block;
  width: 100%;
  height: 100%;

  .lv-settings-widget {
    .lv-settings-widget-content {
      padding: 0;
    }
  }
}