.instrument-tab {

  // apply when tools is in tab mode.
  .lv-tools-widget {
     
    .lv-settings-widget {
      border: none;
    }
  }

  .lv-gridster {
    .lv-tools-widget {
      .lv-settings-widget {
        border: 1px solid $base-component-border-color;
      }
    }
  }
}

.lv-tools-widget {
  display: block;
  width: 100%;
  height: 100%;

  .lv-settings-widget {
    .lv-settings-widget-content {
      padding: 0;
    }
  }
}
