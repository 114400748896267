.lv-asw-reference-ir-swap {
  .lv-reference-ir-swap-field {
    .lv-label {
      &.lv-label-field {
        min-width: 247px;
      }
    }
  }

  .lv-reference-ir-swap-dates {
    .lv-label {
      min-width: 222px;
    }
  
    .lv-field {
      &.lv-field--checkbox {
        .k-checkbox {
          margin-left: 8px;
        }
      }
    }
  }

  .lv-reference-ir-swap-additional-payment {
    .lv-label {
      min-width: 247px;
    }
    .lv-suffix {
      padding-left: 4px;
      padding-top: 5px;
    }
  }
}