.lv-instrument-monitor-filter {
  .lv-instrument-monitor-filter-row {
    padding-bottom: 2 * $base-padding-y;

    .lv-instrument-monitor-filter-search {
      width: 320px;

      .k-textbox {
        border:1px solid $base-border-color;
        border-radius: 2px;

        &:focus {
          border: 1px solid $base-color-blue;
        }
      }
    }

    .lv-watch-list-multi-select {
        background-color: $base-component-header-color;
        margin-right: $base-padding-x;
        position: relative;

        .k-selection-multiple {
          border: 1px solid $base-border-color;
        }

        &.k-focus {
          border: none;
          
          .k-selection-multiple {
            border: 1px solid $base-color-blue;
          }

          .k-clear-value {
            .k-icon {
              display: inline-block;
            }
          }
        }

        &:hover {
          .k-clear-value {
            .k-icon {
              display: inline-block;
            }
          }
        }

        .k-chip-list {
          background-color: $base-component-header-color;
          display: flex;
          flex-wrap: wrap;
      
          .k-chip {
            @include kendo_field_default();
            
            z-index: 1;
            border-color: $base-border-color;
            color: $base-text-color;
            background-color: $base-color-blue;
            background-image: none;
            height: 25px;
            font-size: 12px;
            margin-right: 1px;
            margin-left: 0;
            border-radius: 3px;
      
            .k-chip-content {
              padding: 0 2px;
            }
      
            .k-chip-actions {
              margin-left: 4px;
              margin-top: -2px;
              margin-right: 2px;
      
              &:hover {
                .k-icon {
                  color: $base-color-white;
                }
              }
            }
      
            &:hover {
              color: $base-color-white;
              background-color: #1b81cb;
      
              .k-icon {
                color: $base-text-color;
              }
            }
      
            input::placeholder {
              color: darken($base-text-color, 20%);
            }
        
            &.k-state-focused {
              color: $base-color-white;
            }
          }
      
          .k-searchbar {
            background-color: inherit;
            border: none;
            .k-input-inner {
              border: none!important;
              padding-left: 6px;
              color: inherit;
              font-size: 12px;
            }
          }
        }
      
        .k-clear-value {
          background-color: $base-component-header-color;
          padding-top: 0;
          color: $base-text-color;
          position: absolute;
          top: 0;
          right: 6px;

          .k-icon {
            display: none;
            color: $base-text-color;
            opacity: 0.7;

            &:hover {
              opacity: 1;
            }
          }
        }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }


  // watch list multi select
  .lv-watch-list-multi-select {
    .k-button {
      width: auto;
    }

    .lv-watch-list-item {
      padding-left: $base-padding-x;
    }
    .k-list .k-item.k-item.k-state-focused {
      background-color: inherit;
      color: inherit;
    }
    .k-list .k-item.k-item.k-state-selected, .k-list .k-item.k-item:hover {
      color: $base-color-white;
      background-color: $base-accent-highlight-color;
    }
    .k-item {
      &.k-disabled {
        opacity: 1;
        color: $base-accent-color;
      }
    }

    &.k-multiselect {
      max-height: 25px;
      min-width: 230px;
    }

    &.not-empty {
      kendo-taglist {
        ul {
          li {
            &:last-child {
              margin-right: 2px;
            }
          }
        }
      }

      .k-searchbar {
        display: inline-flex;
        float: none;
      }
    }

    &.k-multiselect {

      min-width: 230px;
      width: auto;

      .k-selection-multiple {

        width: 100%;

        .k-input-inner {
          border:1px solid $base-border-color;
          border-radius: 2px;
          color: $base-text-color;
          background-color: $base-component-header-color;
        }
      }
    }
  }
}

// quick watch lists
.lv-quick-watch-lists {
  display: inline-block;
  
  .lv-quick-watch-list {
    position: relative;
    display: inline-block;
    margin-right: $base-padding-x;

    .k-button {
      width: auto;

      background-color: #505a67;
      color: $base-text-color;
      font-weight: 600;

      &.k-active {
        background-color: $base-accent-highlight-color;
        color: $base-text-color-highlight;
      }

      &.k-selected {
        background-color: $base-accent-highlight-color;
        color: $base-text-color-highlight;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .lv-quick-watch-list-close {
      display: none;
      position: absolute;
      top: -6px;
      right: -6px;

      width: $base-font-size;
      height: $base-font-size;
      line-height: $base-font-size - 2px;
      border-radius: 50%;
      text-align: center;
      background-color: $base-text-color-inactive;

      .k-icon {
        font-size: $base-font-size - 2px;
        color: $base-background-color;
      }

      &:hover {
        cursor: pointer;
        background-color: $base-text-color;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .lv-quick-watch-list-close {
        display: block;
      }
    }
  }

  .lv-quick-watch-lists-options {
    display: inline-block;
    vertical-align: middle;

    @include lv_icon_square(16px);

    .lv-icon-square {
      margin-right: $base-padding-x;

      &:last-child {
        margin-right: 0;
      }
    }

    .k-animation-container {
      z-index: 20;
    }
  }

  &.lv-quick-watch-lists--padded-top {
    padding-top: 2 * $base-padding-y;
  }
}

.lv-quick-watch-lists-popup {
  padding: $base-padding-y;
  margin-top: $base-padding-x;

  .lv-quick-watch-lists-popup-container {
    position: relative;

    width: 460px;
    max-height: 300px;

    overflow-x: hidden;
    overflow-y: auto;

    .lv-watch-list-item {
      @include lv-watch-list-item_selectable;
    }

    .k-nodata {
      color: $base-text-color-inactive;
    }
  }

  .lv-quick-watch-lists-popup-options {
    text-align: right;
    padding-top: $base-padding-y;

    .k-button {
      width: auto;
      &.k-button-flat {
        &:hover {
          color: inherit;
            background-color:#2f4351!important;
          }
        }
      }
    }
}
