.lv-dividend-protection {

  width: 930px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-dividend-checkbox {
    margin-left: 7px;
    margin-right: 0px;
  }

  .lv-label-before-checkbox {
    width: 142px;
  }

  .lv-cash-distribution-left-column {
    width: 338px;
  }

  .lv-threshold-left-column {
    width: 294px;
  }

  .lv-threshold-right-column {
    .lv-label {
      min-width: 195px;
    }
  }

  .lv-label--title {
    margin-top: $base-padding-x - 1;
  }

  .lv-conversion-ratio-fieldset {
    margin-top: $base-padding-x + 5;
    .lv-label {
      width: 230px;
    }
  }

  .lv-fieldset-panel {
    margin-top: $base-padding-x - 1;
    .lv-fieldset-panel-content {
      padding: 12px 8px 8px;
    }
  }

  .lv-dividend-protection-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
          &.k-grid-edit-row {
          .lv-input-field {
            .k-dropdownlist {
              min-width: auto;
              &.k-focus,  &.k-selected,  &:not(:focus) {
                border-top: 0px;
                border-bottom: 0px;
              }
            }
            .k-numerictextbox, .k-datepicker {
              &.k-focus, &:not(:focus) {
                border-top: 0px;
                border-bottom: 0px;
              }
            }
          }
            &:last-child {
              .lv-input-field {
                &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
                .k-dropdownlist {
                  &.k-focus,  &.k-selected, &:not(:focus) {
                    border-top: 1px solid $base-background-color;
                    border-bottom: 1px solid $base-background-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}
