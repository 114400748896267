.lv-advanced-grid {
  display: inline-block;
    &.lv-advanced-grid-stripped {

      .k-grid table tbody tr {
        &:nth-child(odd) {
          .lv-advanced-grid-cell {
            background-color: $base-component-header-color;
          }
        }
       td {
          border-color: $base-border-color;
         .lv-advanced-grid-cell {
           background-color: transparent;
          }
        }
      }
    }

  .k-grid {
    display: inline-block;
    background-color: transparent;
    border-color: transparent;

    &.lv-advanced-grid-scrollable {
      .k-grid-aria-root {
        height: 100%;

        .k-grid-header {
          background-color: transparent;
          border-color: transparent;

          .k-grid-header-wrap {
            display: grid;
            border-color: transparent;
            th {
              border-color: transparent;
            }
          }
        }

        .k-virtual-content {
          // border: 1px solid $base-border-color;
          border-left: none;

          .k-grid-table-wrap {
            // border-right: 1px solid $base-border-color;
          }
        }
      }
    }

    table {
      width: auto;

      tr {
        background-color: transparent;

        td,
        th {
          padding: 0;
          height: 24px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          position: relative;
          vertical-align: middle;

          background-color: transparent;
        }
      }

      thead {
        background-color: transparent;
        tr {
          th {
            padding-left: 5px;
            font-weight: 400;
            color: $base-text-color-dimmed;
          }
        }
      }

      tbody {
        tr {
          td {
            border: 1px solid $base-input-border-color;

            .right-align-placeholder {
              text-align: right;
              padding-right: 5px;
              color: $base-text-color-dimmed;
              font-weight: 600;
            }

            .lv-advanced-grid-cell {
              height: 100%;
              padding: 3px $base-padding-y;
              background-color: $base-input-background-color;
              color: $base-input-color;

              &.lv-advanced-grid-cell--right {
                text-align: right;
              }

              &.lv-advanced-grid-cell--focused {
                background-color: $base-accent-highlight-color;
                color: $base-accent-contrast-color;
              }
              &.lv-advanced-grid-cell--negative {
                color: $base-color-red!important;
              }
            }

            &.lv-input-field--output {
              border: 1px solid $base-border-color;
              .lv-advanced-grid-cell {
                background-color: $base-background-color;
                color: $base-accent-contrast-color;
              }
            }

            &.lv-input-field { // Custom override for advanced grid
              .k-autocomplete,
              .k-combobox,
              .k-dateinput,
              .k-datepicker,
              .k-datetimepicker,
              .k-dropdown,
              .k-checkbox,
              .k-multiselect,
              .k-numerictextbox,
              .k-maskedtextbox,
              .k-timepicker {
                .k-dateinput-wrap,
                .k-dropdown-wrap,
                .k-picker-wrap,
                .k-multiselect-wrap,
                .k-numeric-wrap {
                  height: 25px;
                  border: none;
                  outline: none;
                  padding: 0;

                  input {
                    @include field_height();
                    padding: $base-padding-y $base-padding-x;
                  }
                }
              }

              .k-combobox,
              .k-dropdown {
                .k-dropdown-wrap {
                  .k-input {
                    @include field_height();
                    padding: $base-padding-y $base-padding-x;
                    padding-right: 0;
                  }
                }
              }
            }

            &.k-grid-edit-cell {
              .k-widet {
                margin: 0;
                width: 100%;
              }
            }

            &.k-state-focused {
              .lv-advanced-grid-cell {
                background-color: $base-accent-highlight-color;
                color: $base-accent-contrast-color;
              }
            }
          }

          &.k-grid-edit-row {
            .k-widget {
              margin: 0;
              width: 100%;
            }

            .lv-input-field {
              .k-datepicker, .k-dropdownlist {
                border-left-width: 0px;
                border-right-width: 0px;
              }
            }
          }

          &:hover,
          &.k-state-hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  .lv-advanced-grid-header-wrapper {
    display: flex;
    align-items: baseline;
    label {
      margin-left: 3px;
    }
  }

  .lv-advanced-grid-header-cell {
    display: flex;
    flex-direction: row;

    .lv-xl-label {
      padding-top: 2px;
      padding-left: 5px;

      &.lv-xl-label--not-editable {
        color: $base-checkbox-border-color;
      }
    }
  }

  &.lv-advanced-grid-disabled-values {
    .lv-input-field {
      &.k-state-focused {
        .lv-advanced-grid-cell {
          background-color: $base-input-background-color;
        }
      }

      .lv-advanced-grid-cell {
        opacity: .6;

        .k-input {
          color: $base-input-color;
        }
      }
    }    
  }
}