.lv-outputs {

  .lv-settings-widget {
    .lv-settings-widget-content   {
      padding-top: 2px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .lv-grid-layout {
    .lv-grid-layout-column {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lv-outputs-row {
    height: 25px;

    .lv-label {
      width: 160px;
      line-height: 17px;
      padding-top: 10px;
      padding-left: 6px
    }

    .lv-outputs-row-value {
      width: 105px;
      .lv-negative-label {
        .prefix {
          padding-top: 10px;
          padding-left: 6px;
        }
        .suffix {
          padding-top: 10px;
          padding-left: 10px;
        }
      }
    }

    .lv-date-label {
      color:white;
      display: flex;
      flex-direction: row;

      .lv-date-value {
        flex: 1;
        text-align: right;
        padding: 4px 0;
        line-height: 17px;
        padding-top: 10px;
      }

      .lv-date-suffix {
        width: 25px;
        padding-left: 4px;
        color: #a5a6a9;
        text-align: left
      }
    }
  }
}

.popup-output-content {
  max-width: 450px;
  text-align: justify;

  li {
    padding-bottom: calc($base-padding-x / 2);
  }
}

.lv-outputs-side-panel-window {
  .k-content {
    padding: 0;
  }

  .k-dialog-titlebar {
    border-width: 0px;
    padding-left: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .k-window-actions {
    padding-right: 15px;
  }

  .lv-outputs-tabstrip {
    width: 100%;
    height: 100%;
    padding-top: 5px;

    .k-tabstrip-content {
      border: none;
      padding: 0;
    }
  }
}

