// Color variables (appears count calculates by raw css)
// color palette
$base-color-white: #FFFFFF;
$base-color-black: #000000;
$base-color-red: #FF4A7A;
$base-color-yellow: #FFDD00;
$base-color-green: #73D500;
$base-color-blue: #1E8FE1;
$base-color-blue-dark: #0376E3;
$base-color-purple: #C27AEB;
$base-color-lime-green: #03C4C6;

// colors
$base-background-color: #1e2128 !default;
$base-border-color: #41454d !default;

$base-component-header-color: #32353b !default;
$base-component-border-color: #41454d !default;

$base-text-color: rgba($base-color-white, 0.75);
$base-text-color-inactive: rgba($base-color-white, 0.5);
$base-text-color-highlight: $base-color-white !default;

$base-accent-color: $base-color-blue !default;
$base-accent-contrast-color: $base-color-white !default;

$base-icon-color: $base-text-color !default;

$base-info-color: $base-color-blue !default;
$base-success-color: $base-color-green !default;
$base-warning-color: $base-color-yellow !default;
$base-error-color: $base-color-red !default;

$base-gradient-rainbow: $base-color-red, $base-color-yellow, $base-color-green, $base-color-blue, $base-color-blue-dark, $base-color-purple, $base-color-lime-green;

// ".lm_dragging" is applied to BODY tag during Drag and is also directly applied to the root of the object being dragged

// Entire GoldenLayout Container, if a background is set, it is visible as color of "pane header" and "splitters" (if these latest has opacity very low)
.lm_goldenlayout {
  background: $base-component-header-color;
}

// Single Pane content (area in which final dragged content is contained)
.lm_content {
  background: $base-background-color;
}

// Single Pane content during Drag (style of moving window following mouse)
.lm_dragProxy {
  background-color: $base-component-header-color;
  .lm_content {
    box-shadow: none;
  }
}

// Placeholder Container of target position
.lm_dropTargetIndicator {
  background-color: $base-component-header-color;
  box-shadow: none;
  opacity: 0.6;
  outline: 1px dashed $base-text-color;

  // Inner Placeholder
  .lm_inner {
    background: $base-color-black;
    opacity: 0.1;
  }
}

// Separator line (handle to change pane size)
.lm_splitter {
  background-color: $base-color-black;
  transition: opacity 200ms ease;

  &:hover, // When hovered by mouse...
  &.lm_dragging {
    background: $base-text-color;
    opacity: 1;
  }
}

// Pane Header (container of Tabs for each pane)
.lm_header {
  background-color: $base-component-header-color;


  .lm_tabs {
    // Single Tab container. A single Tab is set for each pane, a group of Tabs are contained in ".lm_header"    
    .lm_tab {
      background-color: transparent;
      
      .lm_title {
        color: $base-text-color-inactive;
      }

      .lm_close_tab {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 1px;
          background-color: $base-icon-color;
          left: 0px;
          top: 4px;
        }

        &:before {
          -webkit-transform:rotate(45deg);
          -moz-transform:rotate(45deg);
          transform:rotate(45deg);
        }

        &:after {
          -webkit-transform:rotate(-45deg);
          -moz-transform:rotate(-45deg);
          transform:rotate(-45deg);
        }
      }

      &.lm_active {
        height: 18px;        
        background-color: $base-background-color;
        // border-top-color: $base-color-blue;

        .lm_title {
          color: $base-text-color-highlight;
        }
      }
    }
  }
}

.lm_dragProxy,
.lm_stack {
  &.lm_right {
    .lm_header .lm_tab {
      &.lm_active {
        box-shadow: none;
      }
    }  
  }
  
  &.lm_bottom {
    .lm_header .lm_tab {
      &.lm_active {
        box-shadow: none;
      }
    }
  } 
}

// If Pane Header (container of Tabs for each pane) is selected (used only if addition of new Contents is made "by selection" and not "by drag")
.lm_selected {
  .lm_header {
    background-color: $base-component-header-color;
  }
}

.lm_tab {
  &:hover, // If Tab is hovered
  &.lm_active // If Tab is active, so if it's in foreground
  {
    
    
  }
}

// Dropdown arrow for additional tabs when too many to be displayed
.lm_header .lm_controls .lm_tabdropdown:before {
  // color: $color1;
}

// Pane controls (popout, maximize, minimize, close)
.lm_controls {
  // All Pane controls shares these
  > li {
    position: relative;
    // background-position: center center;
    // background-repeat: no-repeat;
    // opacity: 0.4;
    // transition: opacity 300ms ease;

    opacity: 1;

    &:hover {
      // opacity: 1;
      opacity: 0.6;
    }
  }

  // Icon to PopOut Pane, so move it to a different Browser Window
  .lm_popout {
    // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_popout_black.png');
  }

  // Icon to Maximize Pane, so it will fill the entire GoldenLayout Container
  .lm_maximise {
    // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_maximise_black.png');
  }

  // Icon to Close Pane and so remove it from GoldenLayout Container
  .lm_close {
    display: none;
    // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_close_black.png');
    position: relative;
    opacity: 1;

    &:hover {
      opacity: 0.6;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 1px;
      background-color: $base-icon-color;
      left: 0px;
      top: 4px;
    }

    &:before {
      -webkit-transform:rotate(45deg);
      -moz-transform:rotate(45deg);
      transform:rotate(45deg);
    }

    &:after {
      -webkit-transform:rotate(-45deg);
      -moz-transform:rotate(-45deg);
      transform:rotate(-45deg);
    }
  }

  // Icon to toggle Pane Docking at mouse hover
  .lm_dock {
    // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_docked_black.png');
    // transform:rotate(-45deg);
    // transition:transform 300ms;
  }
}

.lm_stack.lm_docked {
  .lm_controls .lm_dock {
    transform:rotate(0deg);
  }

  > .lm_items {
    border-color: $base-component-border-color;
    border-image: none;
    box-shadow: none;
  }
}

// If a specific Pane is maximized
.lm_maximised {
  // Pane Header (container of Tabs for each pane) can have different style when is Maximized
  .lm_header {
    background-color: $base-component-header-color;
  }

  // Pane controls are different in Maximized Mode, especially the old Icon "Maximise" that now has a different meaning, so "Minimize" (even if CSS Class did not change)
  .lm_controls {
    .lm_maximise {
      // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_minimize_black.png');
    }
  }
}

.lm_transition_indicator {
  background-color: $base-component-header-color;
  border: 1px dashed $base-component-border-color;
}

// If a specific Pane is Popped Out, so move it to a different Browser Window, Icon to restore original position is:
.lm_popin {
  cursor: pointer;

  // Background of Icon
  .lm_bg {
    background: transparent;
    opacity: 0.7;
  }

  // Icon to Restore original position in Golden Layout Container
  .lm_icon {
    // background-image: data-uri('app/leversys-golden-layout/assets/img/lm_popin_black.png');
    // background-position: center center;
    // background-repeat: no-repeat;
    // opacity: 0.7;
  }

  &:hover {
    .lm_icon {
      opacity: 1;
    }
  }
}