@mixin absolute_position() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lv-loader-panel {
  z-index: 10;
}

.lv-continue-editing-panel {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @include absolute_position();

  z-index: 9;

  .lv-continue-editing-panel-mask {
    @include absolute_position();

    background-color: $base-color-black;
    opacity: 0.5;
  }

  .lv-continue-editing-panel-content {
    width: auto;
    height: auto;

    padding: $base-padding-y $base-padding-x;
    background-color: $base-background-color;

    text-align: center;

    z-index: 1;

    div:not(.lv-continue-editing-panel-options) {
      padding-left: $base-padding-x;
      padding-right: $base-padding-x;

      &:first-child {
        padding-top: $base-padding-x;
      }
    }

    .continue-editing-item-name {
      font-weight: 600;
      color: $base-accent-contrast-color;
    }

    .lv-continue-editing-panel-options {
      margin-top: 3 * $base-padding-x;

      text-align: right;
    
        .k-button {
          width: 100px;
          margin-right: $base-padding-x;

          &:first-child {
            &:hover {
              background-color: $base-button-grayed-hover-color!important;
              color: inherit;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
    }
  }
}

.lv-watch-list-panel-dialog {
  width: 1160px;
  height: 560px;

  display: flex;
  flex-direction: column;

  .lv-watch-list-panel-dialog-header {
    display: flex;
    flex-direction: row;
    margin-top: 2px;

    .lv-watch-list-panel-dialog-header-spacer {
      flex: 1;
    }
    
    .lv-watch-list-panel-dialog-header-option {
      margin-right: $base-padding-x;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lv-watch-list-panel-dialog-content {
    flex: 1;
  }
}

.lv-watch-list-dialog {
  .lv-dialog-actions {
    .save-and-exit {
      &.k-button {
        width: 100px;
      }
    }

    .k-button {
      color: $base-color-white;

      &.k-button-flat {
        color: $base-text-color;

        &:hover {
          background-color: $base-button-grayed-hover-color!important;
        }
      }
    }
  }
}