.lv-copy-and-paste-dialog {

  .lv-copy-and-paste-dialog-content {
    
    width: 100%;
    margin-bottom: 9px;

    .lv-copy-and-paste-dialog-advanced-grid-wrapper {
  
      .lv-advanced-copy-paste-grid 
      {
        width: 100%;
        height: 569px; // grid height + scroll

        .k-grid {
          width: 100%;
          height: 100%!important;
  
          table tbody tr td {
  
            .lv-advanced-grid-cell {
              color: $base-color-gray;
              text-align: right;
              padding-right: 6px;
        
              .lv-advanced-grid-cell-enabled {
                color: $base-color-white;
              }
            }  
          }
        }
      }
    }
  }
}
