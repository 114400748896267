.instrument-authorization {
  display: inline-block;
  width: 100%;
  text-align: center;

  padding: $base-padding-x $base-padding-y;

  .instrument-authorization-instrument-name {
    font-weight: 600;
  }
}