.lv-leversys-data-syncing-settings {
  height: 100%;

  .lv-syncing-equity-container{
    height: 100%;
    .lv-sync-all-equity-data {
      height: inherit;
      padding-top: 7px;
      padding-left: 8px;
      .lv-label {
        font-weight: 600;
      }
    }
    .lv-settings-footer {
      margin-bottom: 3px;
    }
  }
}  