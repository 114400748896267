.lv-send-valuation-query-dialog {
  width: 500px;
  height: 234px;
  

  label {
    color: $base-text-color-dimmed;
  }

  .lv-send-valuation-query-dialog-content {
    width: 460px;
    height: 172px;
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 8px;

    .lv-label-field {
      height: 25px;
      
      margin-bottom: 2px;
      padding-left: 3px;
      padding-top: 4px;
      padding-bottom: 4px;

      font-weight: 600;

      &.important {
        color: #ff6076;

        margin-bottom: 5px;
        padding-left: 0px;

        font-weight: normal;
      }
    }

    .lv-notes-text-area {
      .lv-input-field .k-textarea, .lv-input-field .k-textbox {
        height: 115px;
        width: 460px;
        resize: none;

        border: $base-border-color solid 1px;
        padding: 5px;
      }
    }
  }

  .lv-send-valuation-query-dialog-footer {
    height: 45px;
    border-top: $base-border-color solid 1px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 90px;

      &:last-child {
        margin-right: $base-padding-x*2-2;
        margin-left: $base-padding-x*2+3;
      }
    }
  }
}