.k-tabstrip {
  &.lv-tabstrip {
    height: 100%;
    >.k-tabstrip-content {
      border: none;
      padding: 0;
      overflow: hidden;
      height: 100%;
      padding-top: 4px;
      outline: 0;
    }
  }

  .k-tabstrip-content {
    height: 100%;
    border-color: $base-border-color;
    &:not(.k-active) {
      display: none;
    }
  }

  .k-tabstrip-items-wrapper {

    .k-tabstrip-items {
      background-color: $base-component-header-color;
      border-top-width: 1px;

      .k-item {
        color: $base-text-color-inactive;
        background-color: $base-component-header-color;
        border-width: 1px 1px 1px 0px;
        border-color: $border-color;
        font-weight: 600;
        border-radius: 0px;
        letter-spacing: 0.8px;
        height: 21px;
        margin-top: -1px;
        margin-bottom: 0;

        &.k-active {
          z-index: 15;
        }

        .k-link {
          padding: 2px 6px;
        }

        &:hover,
        &.k-hover {
          color: $base-text-color;
          background-color: $base-component-header-color;
        }

        &.k-active {
          color: $base-text-color-highlight;
          background-color: $base-background-color;
          border-top-color: $base-text-color-dimmed;
          border-bottom-color: transparent;
        }
      }
    }
  }
}
