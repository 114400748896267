.external-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.external-container {
  display: table-cell;
  vertical-align: middle;
  text-align:center;
  padding: 0 20px;
}

.external-content {
  padding: 20px;  
  border-radius: 4px;
  border: 1px solid $base-border-color;
  display: inline-block;

  .external-application-info {
    padding-top: 20px;
    text-align: center;
  }

  @include bp($point_4) {
    display: block;
    width: 100%;
  }
}