@import "helpers/index";
@import "layout/index";
@import "utils/defaults";
@import "components/index";

@font-face {
  font-family: 'icomoon';
  src:  url('../assets/font-icons/icomoon/icomoon.eot?r8eev5');
  src:  url('../assets/font-icons/icomoon/icomoon.eot?r8eev5#iefix') format('embedded-opentype'),
    url('../assets/font-icons/icomoon/icomoon.ttf?r8eev5') format('truetype'),
    url('../assets/font-icons/icomoon/icomoon.woff?r8eev5') format('woff'),
    url('../assets/font-icons/icomoon/icomoon.svg?r8eev5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-externally_shared:before {
  content: "\e900";
  color: #ff7878;
}
.icon-internaly_shared:before {
  content: "\e901";
  color: #d3c253;
}
.icon-private:before {
  content: "\e902";
  color: #79d675;
}

// ngx-toastr
.toast-invisible {
  .toast {
    visibility: hidden;
  }
}

.toast-container {
  z-index: 999!important;
}

// kendo form field
.row,
.inner-row {
  .k-form-field {
    .k-textbox,
    .k-autocomplete,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker,
    .k-textarea {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 12px;
    }
  }
}

.k-form fieldset {
  border: 1px solid rgba(0,0,0,.08);
  padding: 5px;
  margin: 16px 0;
}

.icon-override {
  display: block;

  &:before {
    width: auto;
    margin: 0;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.margin-top5{
  margin-top: 5px;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscriber-access-icon i {
  font-size: 23px;
  margin-right: 10px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  background: $base-color-blue;
  color: $base-color-white;
}