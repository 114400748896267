.lv-coupon-co-pay {

  .lv-co-pay-trigger-type {
    width: 143px;
  }

  .lv-upside-panel {
    margin-top: $base-padding-x;
  }

  .lv-co-pay-upside-col {
    min-width: 400px;
  }

  .lv-downside-button {
    width: 179px;
    margin-top: $base-padding-x;
  }

  .lv-co-pay-scheduile {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
