
.k-dropdownlist {
  height: 25px;
  padding: 0;
  transition: all .1s ease;
  flex-direction: row;
  border-color: #1e2128;
  background-color: $base-input-background-color;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  position: relative;
  box-sizing: border-box;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  min-width: 104px;
  width: auto;
  display: block;
  padding: 4px 6px;
  padding-top: 3px;

  cursor: pointer;

  .k-input-inner {
    margin-right: 17px;

    .k-input-value-text {
      color: $base-input-color;
    }
  }
 
  &.ng-touched.ng-invalid {
    background-color: $base-error-color;
    color: $base-color-black;
    border-color: $base-input-border-color;

    &:hover{
      background-color: $base-error-color;
      color: $base-color-black;
      border-color: $base-input-border-color;
    }
    
    &.k-state-focused {
      background-color: $base-error-color;
      color: $base-color-black;
      border-color: $base-input-border-color;    
    }
  }

  .k-button {
    background-color: inherit;
    width: 23px;
    height: 100%!important;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 4px;
    padding-left: 0;
    padding-top: 3px;

    &:hover {
      background-color: #434e5b;
    }

    .k-icon {
      width: 16px;
      height: 16px;
      outline: 0;
      line-height: 1;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      position: relative;
      padding-left: 1px;
      font-size: 12px;
      color: #eee;
      padding-left: 4px;  
    }
  }
}

.k-list-container {
  .k-searchbox {
    .k-icon-wrapper-host {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .k-input-inner {
      width: 100%!important;
      box-sizing: border-box;
      padding-left: 6px;
      padding-right: calc(12px + 6px);
      border-color: #41454d;
      color: #c7c7c9;
      background-color: #32353b;
      border-radius: 2px;
      padding: 4px 6px;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      font: inherit;
      font-size: 12px;
      outline: 0;

      &:focus {
        border-color: #1e8fe1;
      }

      &:focus-visible {
        border-color: #1e8fe1;
      }
    }
  }
}