@mixin internal_icon($size: 16px) {
  width: $size;
  height: $size;
  line-height: $size;
  font-size: $size;
}

.internal-wrapper {
  width: 100%;
  height: 100%;

  outline: none;

  .header {
    width: 100%;
    height: 34px;
    padding: 8px 8px 0px 8px;    

    .menu {
      .lv-menu-popup {
        z-index: 10!important;
        width: 129px!important;
      }

      margin: 0 16px 0 4px;
      .lv-menu {
        margin-top: 2px;
        
        img {
          height: 20px;
          width: 14.5px;
        }
      }
    }

    .search {
      position: relative;
      width: 300px;

      i {
        position: absolute;
        top: 6px;
        left: 8px;
        font-size: 14px;
        font-weight: 600;
        color: $base-text-color;
      }

      input {
        height: 100%;
        width: 100%;
        padding-left: 30px;
        background-color: $base-component-header-color;
        border-radius: 12px;
        color: $base-text-color;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .messages {
      flex: 1;
      text-align: left;
      line-height: 26px;
      padding: 0px 10px;
    }

    .options {

      padding: 0px 10px;

      .hub-connection {
        margin-right: $base-padding-x * 2;

        .k-i-reset {
          @include internal_icon(14px);
        }

        .connected,
        .connecting,
        .reconnecting,
        .disconnected {
          &.k-i-circle {
            @include internal_icon(20px);
          }
        }

        .connected {
          color: $base-color-green;
        }

        .connecting,
        .reconnecting {
          color: $base-color-yellow;
        }

        .disconnected {
          color: $base-color-red;
        }
      }

      .k-i-help {
        @include internal_icon;

        margin-right: $base-padding-x;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.lv-create-instrument-modal-container {
  .k-window-content {
    &.k-dialog-content {
      padding: 0px 0px;
    }
  }
}

.lv-equity-sync-settings-modal-container {
  .k-window-content {
    &.k-dialog-content {
      padding: 0px 0px;
    }
  }
}