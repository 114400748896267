.lv-market-data-bond-setting {
  .lv-market-data-bond-settings-container {
    height: 100%;

    .lv-credit-bond-settings {
      .lv-section-settings-base {
        .lv-section-settings-system-defaults {
          padding-top: 7px;
        }
      }
    }
  }
}