.lv-terms-section-settings-system-defaults,
.lv-terms-section-settings-my-defaults {
  padding: 10px 8px 20px 8px;
  .space-bottom {
    margin-bottom: 10px;
  }

  .k-disabled {
    .k-input-inner {
      background-color: $base-input-background-color;
      opacity: .6;
      filter: grayscale(.1);
      pointer-events: none;
      box-shadow: none;
    }

    &.k-dropdownlist {
      background-color: $base-input-background-color;
      opacity: .6!important;;
      filter: grayscale(.1);
      pointer-events: none;
      box-shadow: none;

      .k-input-inner {
        opacity: 1!important;
      }
    }
  }
}

.lv-terms-section-settings-system-defaults {
  border-bottom: solid 1px #41454d;
}

.lv-terms-section-settings { 
  overflow-y: scroll;
  height: 100%;
}
