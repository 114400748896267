.lv-fieldset-panel {
  display: block;
  position: relative;
  padding: calc($base-font-size / 2) 0;

  .lv-fieldset-panel-legend,
  .lv-fieldset-panel-content {
    background-color: $base-background-color;
  }

  .lv-fieldset-panel-legend {
    position: absolute;
    top: 0;
    left: $base-padding-x;
    padding: 0 calc($base-padding-x / 2);
    font-weight: 600;
    color: $base-section-header-title-color;
    line-height: $base-font-size;
    text-transform: uppercase;
  }

  .lv-fieldset-panel-content {
    padding: calc($base-font-size / 2) $base-padding-x + 2 $base-padding-x;
    border: 1px solid $base-border-color;
  }

  &.lv-fieldset-panel--full-height {
    height: 100%;

    .lv-fieldset-panel-content {
      height: 100%;
    }
  }
}
