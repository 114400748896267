.lv-market-data {
  display: block;
  position: relative;

  width: 100%;
  height: 100%;

  padding-top: $base-padding-x - 2;

  .centered-spinner {
    z-index: 1;
  }

  .lv-market-data-controls {
    position: absolute;
    top: $base-padding-y;
    right: $base-padding-x;

    z-index: 2;

    @include lv_icon_square(24px, 2px);

    .lv-icon-square {
      margin-right: $base-padding-x;

      &.lv-icon-square--state-changed {
        background-color: $base-color-blue;
      }

      &:last-child {
        margin-right: 0;
      }

      &.lv-icon-square--disabled {
        background-color: darken($base-icon-color, 20%);

        .k-icon {
          color: $base-background-color;
        }

        &:hover {
          cursor: not-allowed;
          background-color: darken($base-icon-color, 20%);
        }
      }
    }
  }

  .lv-market-data-tabstrip {
    width: 100%;
    height: 100%;

    .k-tabstrip-items {
      margin-right: 60px + $base-padding-x;
      flex-wrap: wrap;
      width: auto !important;
      border-bottom-width: 1px;
    }

    .lv-credit-tabstrip {
      .k-tabstrip-items {
        width: 100% !important;
      }
    }

    .k-tabstrip-content {
      border: none;
      padding: 0;
      outline: unset;
    }
    
    .lv-market-data-section-content {
      padding: 0 $base-padding-x + 2 $base-padding-x + 2 $base-padding-x + 2;
    }
  }
}

