.lv-advanced-copy-paste-grid {

    .k-grid .k-grid-header-locked {
        background-color: transparent;
        border: none;
    }

    .k-grid-content-locked {
        background-color: transparent;
        border: none;
    }

    .lv-advanced-copy-paste-grid-header-item {
        height: 25px;
        background-color: $base-background-color;
        text-align: center;
        padding-top: 5px;

        &.text-field {
            text-align: left;
        }
    }

    .k-grid {
        .k-grid-content-locked {
            .lv-advanced-copy-paste-grid-cb-cell {
                padding-left: 18px;
        
                &:hover {
                    background-color: $base-background-color;
                }
            }
        }
    }
}