@include lv_wlpdi_fixed_column("wl-column",220px);

.lv-watch-list-panel-dialog-item-column-footer {
  @include lv_icon_square(16px);

  .lv-icon-square {
    margin-right: $base-padding-x;

    &:last-child {
      margin-right: 0;
    }
  }

  .lv-watch-list-instruments-footer-spacer {
    flex: 1;
  }
}

.lv-watch-lists-editor {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .lv-watch-lists-editor-header {
    font-size: $base-font-size + 2px;
  }

  .lv-watch-lists-editor-content {
    flex: 1;
    padding: 5px 0px;

    .delete-info {
      padding: 2 * $base-padding-x;
    }
    .lv-watch-lists-editor-content-new-watch-list-input {
      float: left;
      margin-left: $grid-col-margin;
      margin-right: $grid-col-margin;
      width: calc(100% * 0.58333 - #{$grid-width-margin});
      & input.k-textbox {
        border-radius: 2px;
        padding: 4px 6px;
        width: 100%;
        border-width: 1px;
        border-style: solid;
      }
    }
  }

  .lv-watch-lists-editor-footer {
    text-align: right;

    .k-button {
      width: auto;
      &.k-button-flat {
        color: $base-text-color;

        &:hover {
          background-color: $base-button-grayed-hover-color!important;
        }
      }
    }
  }
}

.lv-watch-list-panel-dialog-item {
  .text-overflow-ellipsis {
    flex: 1;
  }

  .lv-instrument-monitor-icon {
    position: relative;
    width: 18px;

    i {
      position: absolute;
      font-size: 15px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
