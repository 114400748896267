.lv-environment-system-settings {
  padding-left: 7px;
  padding-top: 6px;

  .lv-environment-system-settings-content {
    display: flex;
    margin-top: 5px;

    .lv-environment-system-settings-content-label {
      margin-top: 5px;
      width: 165px;
    }

    .lv-environment-system-settings-content-item {
      display: flex;
      width: 135px;
      border: 1px solid $base-border-color;
      height: 25px;
      padding-top: 4px;

      &:first-child {
        border-right: none;
      }

      .k-icon {
        margin-left: 4px;
        font-size: 17px;
      }

      .lv-environment-system-settings-content-item-label {
        margin-left: -2px;
      }
    }

    .lv-environment-system-settings-content-edit {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      color: $base-background-color;
      background-color: $base-text-color-inactive;
      cursor: pointer;

      .k-icon {
        margin-left: 4px;
        margin-top: 4px;
        font-size: 17px;
      }
    }
  }
}