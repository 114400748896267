.lv-equity-market-data-dividends {

  .lv-equity-market-data-tabstrip {
    padding-top: 3px;
  }

  .lv-equity-dividends-dividend-ccy {
    padding-left: 7px;
    padding-bottom: 4px;
  }

  .lv-equity-schedule-dividends {
    margin-top: 3px;
    padding-left: 7px;
  }
}