.k-switch {
  .k-switch-container {
    .k-switch-label-on {
      left: 4px;
      font-size: 8px;
    }

    .k-switch-label-off {
      left: 18px;
      font-size: 8px;
    }
  }
}