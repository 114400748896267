.lv-convertible-bond-terms-general-issue {

  .lv-terms-general-instrument {
    width: 440px;
  }

  .lv-terms-general-small-input {
    width: 80px;
    min-width: 80px;
  }

  .lv-general-terms-first-col {
    min-width: 600px;
  }

  .lv-terms-general-instrument-short {
    width: 170px;
  }

  .lv-terms-general-country {
    width: 150px;
  }

  .lv-terms-general-instrument-issuer {
    width: 237px;
  }

  .lv-terms-general-underlying {
    width: 237px;
  }

  .lv-terms-general-checkbox {
    margin-left: 7px;
  }

  .lv-fx-section {
    margin-left: -7px;
    margin-top: $base-padding-x;
  }
  .lv-terms-general-fieldset {
    margin-top: $base-padding-x;
    .lv-label {
      width: 152px;
    }
    .lv-terms-general-small-input {
      .lv-label {
        width: 80px;
        text-align: center;

        &.lv-label-field-left {
          text-align: left;
        }
      }
    }
    .lv-label-before-checkbox {
      width: 127px;
    }
  }

  .lv-pricing-range {
    .lv-fieldset-panel-content {
      padding: 0px 8px 8px 8px;
    }

    .lv-sufix-label {
      margin: $base-padding-y;
      color: $base-text-color-dimmed;
      padding-top: 1px;
    }
  }
  .lv-terms-general-output {
    padding: $base-padding-y 0;
    color: $base-input-color;
    line-height: 1.42;

    &.lv-terms-general-output-underlying-name {
      color: $base-color-orange;
      margin-right: 10px;
    }
  }

  .lv-terms-general-underlying-name {
    .lv-terms-general-search-autocomplete {
      background-color: $base-input-background-color;
      position: relative;
      width: 237px;
      height: 25px;
      margin-left: -15px;
      padding-left: 18px;

      input  {
        color: $base-color-orange;
      }

      &.k-focus {
        border: 1px solid $base-color-blue;
      }

      .k-clear-value {
        display: inline-flex;
        top: 0;
        right: 7px;
      }

      .k-icon {
        position: absolute;
        top: 6px;
        line-height: $base-font-size + 2px;
        font-size: $base-font-size + 2px;
        color: $base-input-color;
      }
    }

    .k-clear-value {
      height: 12px;
      width: 12px;

      .k-icon-wrapper-host {
        height: 100%;
        width: 100%;
      }
    }

    .k-i-search {
      position: relative;
      left: 6px;
      z-index: 1;
      font-size: 14px;
      top: 1px;
      width: 15px;
      height: 15px;
    }
  }

  .lv-terms-general-underlying-name-icon-open {
    padding-top: 2px;
    margin-right: 12px;
    .k-icon {
      font-size: 20px;
      cursor: pointer;
      color: $base-color-blue;
      margin-top: 1px;
    }
  }
}

.lv-terms-general-small-input {
  .lv-label {
    width: 80px;
    text-align: center;
  }
}

.lv-terms-general-stock-ref {
  .lv-field.lv-field--checkbox {
    margin-right: 0px;
  }
}

.lv-label-before-checkbox {
  width: 135px;
}

.lv-terms-general-search-autocomplete-header {
  padding-left: 6px;
}

.lv-terms-general-search-autocomplete-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  table-layout: fixed;
  text-align: left;

  .name,
  .isin,
  .leversys-id {
    display: table-cell;
  }

  .name {
    width: 346px;
  }

  .isin {
    width: 126px;
  }

  .leversys-id {
    flex: 1;
  }
}



