$background-color: $base-color-white;
$color: #f31700;

$background-color1: #d9534f;
$color1: $base-color-white;

.lv-validation-message {
  padding: 8px;
  margin-top: 2px;
  border-radius: 4px;
  background: $background-color1;
  
  .lv-validation-message-content {
    border: none;
    background: transparent;
    color: $color1;
    font-size: $base-font-size;
    text-align: left;
  }
}
