.dividend-protection-settings-column {
  &:nth-child(1) {
    margin-right: 65px;
  }
  &--left {
    .lv-label {
      width: 230px;
    }
  }

  input::placeholder {
    text-align: left;
  }
}
