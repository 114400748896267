.lv-quantity-section-settings-currency-overrides
{
  display: flex;
  .k-grid {
    border-left: 0;
    table thead {
      display: none;
    }

    .k-dropdownlist { 
      min-width: 0!important;
    }
  }

  &.lv-advanced-grid {
    .k-grid {
      table tbody tr {
        //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
        &.k-grid-edit-row {
        .lv-input-field {
          .k-dropdownlist {
            min-width: auto;
            &.k-focus,  &.k-selected,  &:not(:focus) {
              border-top: 0px;
              border-bottom: 0px;
            }
          }
          .k-numerictextbox {
            &.k-focus, &:not(:focus) {
              border-top: 0px;
              border-bottom: 0px;
            }
          }
        }
          &:last-child {
            .lv-input-field {
              &:not(:focus), &:focus {
                border-top: 0px;
                border-bottom: 0px;
              }
              .k-dropdownlist {
                &.k-focus,  &.k-selected, &:not(:focus) {
                  border-top: 1px solid $base-background-color;
                  border-bottom: 1px solid $base-background-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
