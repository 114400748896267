.lv-asw-terms-identifiers {
  flex-direction: column;
  min-width: 400px;
  padding: $base-padding-x + 3 $base-padding-x + 2;
  padding-right: 0px;
  padding-left: 5px;

  .lv-asw-save-reload-controls {
  
    .lv-icon-square {
      margin-right: 6px;
      width: 24px;

      .k-icon {
        line-height: normal;
      }

      &:nth-child(1) {
        height: 25px;
        padding: 0.5px 0px;

        .k-icon {
          font-size: 24px;
        }
      }

      &:nth-child(2) {
        height: 24px;
        padding: 2px;
        margin-right: 8px;

        .k-icon {
          font-size: 20px;
        }
      }
    }
  }

  .lv-advanced-grid .k-grid table thead tr th {
      font-weight: 600;
      color: #d1d1d1;

      &:nth-child(2) {
        padding-left: 3px;
      }
  }

  .lv-asw-terms-identifiers-read-only {
    padding-top: 2px;
    flex-direction: row;
    justify-content: space-between;

    .lv-asw-terms-identifiers-read-only-header {
      
      .lv-asw-terms-identifiers-read-only-label {
        text-transform: uppercase;
      }
    }

    .lv-asw-terms-identifiers-read-only-label {
      width: 140px;
      height: 25px;
      
      &.lv-asw-terms-identifiers-read-only-description {
        padding-top: 3px;
        color: $base-text-color-dimmed;
      }

      &.lv-asw-terms-identifiers-read-only-value {
        padding-top: 3px;
        padding-left: 6px;
      }
    }
  }

  .lv-asw-identifiers-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              &:last-child {
                &.k-focus, &:not(:focus) {
                  .k-textbox {
                    height: 25px;
                    border-top: 0px!important;
                    border-bottom: 0px!important;
                    border-right: 0px!important;
                    padding: 4px 6px;
                    border-left:  0px solid $base-background-color!important;
                    background-color: $base-input-background-color !important;

                  }
                }
              }
              .k-dropdownlist {
                &.k-focus, &:not(:focus) {
                  border: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                &:last-child {
                  &.k-focus, &:not(:focus) {
                    .k-textbox {
                      height: 25px;
                      border-top: 0px!important;
                      border-bottom: 0px!important;
                      border-right: 0px!important;
                      padding: 4px 6px;
                      border-left:  0px solid $base-background-color!important;
                      background-color: $base-input-background-color !important;
                    }
                  }
                }
                .k-dropdownlist {
                  min-width: auto;
                  &.k-focus, &:not(:focus) {
                    border: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}