.lv-tools {
  display: block;
  position: relative;

  width: 100%;
  height: 100%;

  .lv-tools-tabstrip {
    width: 100%;
    height: 100%;
    padding-top: 5px;

    .k-tabstrip-content {
      border: none;
      padding: 0;
    }
  }
}
