.workspace-panel {  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;  
  background-color: $base-background-color;

  .panel-items {
    height: 38px;
    padding-top: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    z-index: 1;

    .lv-icon-square {
      margin-top: 6px;
      margin-left: 6px;
    }
  }

  .panel-spacer {
    z-index: 0;
    height: 2px;
    margin-top: -2px;
    background-color: $base-color-black;
  }

  .panel-item-content {
    z-index: 1;
    width: 100%;
    height: 100%;  
    overflow: auto;
  }
}