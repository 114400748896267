.lv-menu {
  display: flex;
  flex-direction: row;

  .img-icon {
    height: 16px;
  }

  .icon {
    height: 16px;
    min-width: 16px;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    color: $base-icon-color;
  }

  .text {
    padding-left: 8px;
    line-height: 16px;    
  }

  &:hover {
    cursor: pointer;
  }
}

.lv-menu-popup {
  z-index: 10;
  width: max-content!important;
}

.lv-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  .item {
    border-bottom: 1px solid $base-component-border-color;
    padding: 4px 8px;
    transition: all .2s, color .2s;

    &:hover {
      color: $base-accent-contrast-color;
      background: $base-accent-highlight-color;
    }

    &:last-child {
      border-bottom: 0;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.subcomponent {
      padding-left: 20px;
    }
  }
}

.lv-menu-popup-holder {
  z-index: 10;
}