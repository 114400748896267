.lv-side-panel {
  min-height: 125px;
  position: absolute;
  top: 0;
  bottom: 0;

  padding: 7px 9px;
  background-color: $base-background-color;
  border-top: 1px solid $base-component-border-color;

  overflow-x: hidden;
  overflow-y: auto;

  z-index: 9999;

  &.lv-side-panel-left {
    left: 0;
    border-right: 1px solid $base-component-border-color;
  }

  &.lv-side-panel-right {
    right: 0;
    border-left: 1px solid $base-component-border-color;
  }

  &.lv-side-panel--floating {
    bottom: auto;

    background-color: $base-popup-color;

    border-bottom: 1px solid $base-border-color;

    &.lv-side-panel-right,
    &.lv-side-panel-left {
      border-right-color: $base-border-color;
    }
  }
}

.lv-side-panel-link-item {
  @include field_height();
  padding-top: 4px;
  width: 205px;

  .k-icon {
    font-size: $base-font-size;
    padding-right: 16px;
    padding-top: 4px;
    float: right;
    color: $base-icon-color;
  }

  &.lv-side-panel-link-item--disabled {
    color: $base-text-color-inactive;
    user-select: none;

    &:hover {
      cursor: not-allowed;
      color: $base-text-color-inactive;

      .k-icon {
        color: $base-text-color-inactive;
      }
    }
  }

  &.lv-side-panel-link-item--separator {
    height: 30px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid $base-text-color;
  }

  &:hover {
    cursor: pointer;
    color: $base-accent-color;

    .k-icon {
      color: $base-text-color;
    }
  }

}