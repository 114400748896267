.conversion-settings-column {
  &--left {
    .lv-label {
      width: 240px;
    }
  }

  input::placeholder {
    text-align: left;
  }

  &:last-child {
    margin-left: 42px;
  }
}
