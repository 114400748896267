.lv-xl-label {
    font-size: 10px;
    font-weight: 600;
    color: $base-xl-label-color-green;

    &.lv-xl-label--not-editable {
      color: $base-checkbox-border-color;
    }
}

.popup-xl-content {
    max-width: 450px;
    text-align: justify;
  }
