.lv-grid-context-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.lv-grid-context-menu li {
  border-bottom: 1px solid $base-component-border-color;
  padding: 4px 8px;
  transition: all .2s, color .2s;

  &:hover {
    color: $base-accent-contrast-color;
    background: $base-accent-highlight-color;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.k-context-menu li {
  &.lv-context-item-show-border {
    border-bottom: 1px solid $base-border-color;
  }
}