.lv-interest-rates {
  width: 100%;
  height: 100%;

  .lv-interest-rates-content {
    flex-wrap: wrap;
    overflow: auto;

    .lv-interest-rates-currency-section {
      width: 341px;
      min-width: 341px;

      margin-right: 15px;
      margin-bottom: 16px;

      .lv-label {
        &:not(.lv-label--title) {
          width: 125px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      .lv-interest-rates-currency-section-yield-curve {
        .k-input-inner {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .lv-interest-rates-grid-title {
    padding-top: 5px;
  }
}

