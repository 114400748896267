.lv-model-customization-selection-dialog {

  .lv-model-customization-selection-left-content {
    margin-right: 22px;
  }

  .lv-model-customization-selection-section {

    margin-top: 10px;

    &:first-child {
      margin-top: 0px;
    }
  }
  .lv-label {
    width: 200px;
  }
}
