.instrument-tab {

  // apply width when model customization is in tab mode.
  .lv-model-customization-content-scroll {
    max-width: 676px;
  }

  // apply width when model customization is in widget mode.
  .lv-gridster {
    .lv-model-customization {
      max-width: 1258px;
    }
    .lv-model-customization-content-scroll {
      max-width: 1258px;
    }

    .lv-model-customization-exclude-terms-model-type-section--column {
      flex-direction: column;
    }

    .lv-model-customization-exclude-terms-model-type-section--row {
      flex-direction: row;
    }

    .lv-model-customization-call-averaging-other-section--column {
      flex-direction: column;
    }

    .lv-model-customization-call-averaging-other-section--row {
      flex-direction: row;
    }
  }
}

.lv-model-customization {
  height: 100%;
  display: flex;
  flex-direction: column;

  .lv-settings-widget {
    .lv-settings-widget-content {
      padding: 0px
    }
  }

  .lv-model-customization-content {

    display: flex;
    flex-direction: column;
    height: 100%;

    $line-height: 25px;
    $elementWidth: 155px;
    $label-width: 160px;
    $section-width: $elementWidth + $label-width + 20;
    padding-bottom: $base-padding-y + 2;

    .lv-label {
      min-width: 175px;
    }

    .lv-input-field {
      width: $elementWidth;
      min-width: $elementWidth;
      text-align: left;
       &.lv-input-field--auto {
        min-width: 104px;
       }
      .lv-model-customization-dropdown {
        width: $elementWidth;
      }
    }

    .lv-model-customization-exclude-terms-section,
    .lv-model-customization-include-terms-section {
      margin: 0 1px 0 ($base-padding-x + 2);
    }

    .lv-model-customization-model-type-section {
      margin: 0 ($base-padding-x + 1) 0 ($base-padding-x + 2);
    }

    .lv-model-customization-call-averaging-section {
      margin: 0 9px 0 6px;
    }

    .lv-model-customization-other-section { 
      margin: 0 0 0 ($base-padding-x);
    }

    .lv-model-customization-exclude-terms-model-type-section {
      min-width: ($section-width - 5);
    }

    .lv-model-customization-call-averaging-other-section {
      min-width: $section-width;
      margin-left: 2px;
      .lv-label {
        min-width: 175px;
      }
    }
  }

}
.popup-modelCustomization-content {
  max-width: 400px;
  min-width: 320px;
  text-align: justify;

  li {
    padding-bottom: calc($base-padding-x / 2);
  }
}
.lv-model-customization-content {
  .k-dropdownlist {
    display: flex;
    .k-input-inner {
      margin-right: 0px;
      min-width: max-content;
    }
  }
}
