.lv-negative-label {

  $line-hight: 17px;

  display: flex;
  flex-direction: row;

  color: $base-input-color;

  .prefix {
    width: 80px;
    text-align: right;
    line-height: $line-hight;
    padding: $base-padding-y 0;
  }

  .suffix {
    width: 36px;
    line-height: $line-hight;
    font-size: $base-font-size;
    padding: $base-padding-y;
    padding-right: $base-padding-y * 3;
    color: $base-text-color-dimmed;
    text-align: left;

    &.auto-width {
      width: auto;
    }
  }
}

.lv-negative-label-color {
  .prefix {
    color: $base-color-red;
  }
}

.lv-negative-label-border {
  border-style: solid;
  border-width: 1px;
  border-color: $base-border-color;
}