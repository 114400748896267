// Import overrides
@import "app/leversys-core-ui/scss/theme/applications/marketspace.scss";
// Import core ui
@import "app/leversys-core-ui/scss/index";
@import "app/leversys-core-ui/scss/auto-fill";
// Import kendo theme
@import "app/leversys-core-ui/scss/theme/kendo-default/index";
//import application styles
@import "app/scss/index";
// Import kendo theme default
@import "@progress/kendo-theme-default/scss/all";

@import 'ngx-toastr/toastr';
@import "app/leversys-golden-layout/scss/all.scss";

@include scrollbar($base-border-color, $base-background-color);

body {
  background-color: $base-background-color;
  color: $base-text-color;
}

.public-application-modal {
  .lv-modal-container {
    background-color: $base-background-color;

    .lv-modal-title {
      background-color: $base-accent-color;
      padding: 4px 6px;
      color: $base-text-color-highlight;
      font-size: 13px;
      line-height: 13px;
    }

    .lv-modal-content {
      padding: 40px;
    }
  }
}
.k-tooltip-wrapper {
  z-index: 9999999 !important;
}
