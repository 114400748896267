@include lv_wlpdi_fixed_column("im-column",600px);

.lv-watch-list-panel-dialog-item-column-footer {
  .k-button {
    width: 80px;
  }
}

.lv-watch-list-panel-dialog-item {
  .text-overflow-ellipsis {
    flex: 1;
  }

  .lv-instrument-monitor-icon {
    position: relative;
    width: 18px;

    i {
      position: absolute;
      font-size: 15px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
