

.lv-sortable-list {
  overflow-y: auto;
      
  border: 1px solid $base-component-border-color;
  border-radius: 2px;    

  .lv-sortable-list-item {
    width: 100%;
    padding: $base-padding-y $base-padding-x;          
    
    text-align: left;
    color: $base-text-color;
    border: 1px solid $base-component-border-color;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    cursor: pointer;
    
    &:hover {
      color: $base-accent-contrast-color;
      background-color: $base-accent-highlight-color;
    }
  }
}