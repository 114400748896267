.lv-pricing-save-excel {

  .k-window-content {
      padding: 20px;

      button {
          margin-bottom: 20px;

          &:last-child {
              margin-bottom: 0;
          }
      }
    }
  }