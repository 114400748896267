.lv-scenario-grid-wrapper {
  .lv-scenario-2d-grid {
    height: 100%;
    
    display: flex;
    flex-direction: column;
  
    .lv-scenario-grid {
      flex: 1;
      height: auto;
    }
  }
  
  .lv-scenario-grid {
    height: 100%;
  
    th,
    td {
      span.input {
        color: #f1b030;
      }
    }
  
    th {
      text-align: center;
    }
  
    td {
      text-align: right;
  
      span.output-invisible {
        display: none;
    
        &.output-visible {
          display: inline;
        }
      }
    }
  
    div.aligned-left {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .lv-scenario-grid-actions {
    margin-top: $base-padding-y;
  }
}