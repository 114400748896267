.lv-documents {
  width: 100%;
  height: 100%;

  .lv-documents-loader {
    position: relative;
    height: 200px;
  }

  .link {
    color: $base-accent-color;
    &:hover {
      cursor: pointer;
    }
  }

  .width30 {
    width: 30%;
  }

  .lv-documents-contribute {
    padding: 10px;
    text-align: right;
    
    button {
      width: 136px;
    }
  }

  .lv-table {
    thead {
      tr {
        th, td {
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }
    }
  }
}