.lv-settings-widget {
  $header-height: 24px;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  border: 1px solid $base-component-border-color;
  background-color: $base-background-color;

  .lv-settings-widget-header {
    display: flex;
    padding: 0 ($base-padding-x + 2) 0 ($base-padding-x + 2);
    flex-direction: row;
    height: $header-height;
    border-bottom: 1px solid $base-component-border-color;
    user-select: none;

    .lv-settings-widget-title {
      flex: 1;
      text-transform: uppercase;
      line-height: $header-height;
      color: $base-text-color-dimmed;
      font-size: $base-font-size - 2px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .lv-settings-widget-warrning {
      width: 17px;
      height: 17px;
      margin-right: 10px;
      color: #ffc107;
      padding-top: 4px;
      padding-right:10px
    }

    .lv-settings-widget-settings {
      width: $base-font-size;
      height: $base-font-size;
      line-height: $header-height;
      color: $base-text-color;

      .k-icon {
        font-size: $base-font-size;
        color: $base-icon-color;
      }

      &.loading {
        @include loading_state;

        &:hover {
          cursor: default;
        }
      }

      &:hover {
        cursor: pointer;

        .k-icon {
          color: $base-text-color;
        }
      }
    }
  }

  .lv-settings-widget-content {
    flex: 1;
    padding: $base-padding-x;
    overflow-x: auto;
    overflow-y: auto;

    .lv-settings-widget-section {
      padding-bottom: (2 * $base-padding-y);

      .lv-settings-widget-section-title {
        font-size: $base-font-size - 2px;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: $base-accent-color;
        padding-bottom: $base-padding-y;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &.loading {
      @include loading_state;
    }
  }

  .lv-side-panel {
    padding: 0;
    padding: 10px $base-padding-x + 2;

    .lv-side-panel-link-item {

      padding: $base-padding-y $base-padding-x + 1;

      &.lv-side-panel-link-item--separator {
        //height: 25px + padding: 4px + border: 1px
        height: 30px;
      }
    }
  }
}

.lv-settings-dropdown {
  width: 200px;
  margin: -1px -1px 0 0;
  padding: $base-padding-x;

  .lv-settings-dropdown-item {
    cursor: pointer;
    padding-bottom: $base-padding-y;

    .k-icon {
      font-size: $base-font-size;
      line-height: $base-font-size + 2px;
      float: right;
      color: $base-icon-color;
    }

    &.disabled {
      color: $base-text-color-inactive;
      user-select: none;

      &:hover {
        cursor: default;
        color: $base-text-color-inactive;

        .k-icon {
          color: $base-text-color-inactive;
        }
      }
    }

    &:hover {
      cursor: pointer;
      color: $base-accent-color;

      .k-icon {
        color: $base-text-color;
      }
    }
  }
}
