.lv-equity-terms-identifiers {
  flex-direction: column;
  min-width: 400px;
  padding: $base-padding-x + 3 $base-padding-x + 2;
  padding-right: 0px;

  .lv-advanced-grid .k-grid table thead tr th {
      font-weight: 600;
      color: #d1d1d1;

      &:nth-child(2) {
        padding-left: 3px;
      }
  }

  .lv-equity-terms-identifiers-read-only {
    padding-top: 2px;
    flex-direction: row;
    justify-content: space-between;

    .lv-equity-terms-identifiers-read-only-header {
      
      .lv-equity-terms-identifiers-read-only-label {
        text-transform: uppercase;
      }
    }

    .lv-equity-terms-identifiers-read-only-label {
      width: 140px;
      height: 25px;
      
      &.lv-equity-terms-identifiers-read-only-description {
        padding-top: 3px;
        color: $base-text-color-dimmed;
      }

      &.lv-equity-terms-identifiers-read-only-value {
        padding-top: 3px;
        padding-left: 6px;
      }
    }
  }

  .lv-equity-identifiers-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              &:last-child {
                    &.k-focus, &:not(:focus) {
                    .k-textbox {
                        background-color: $base-input-background-color !important;
                        height: 25px;
                        border-top: 0px!important;
                        border-bottom: 0px!important;
                        border-right: 0px!important;
                        padding: 4px 6px;
                        border-left:  0px solid $base-background-color!important;
                      }
                    }
                  }
              .k-dropdownlist {
                &.k-focus, &:not(:focus) {
                  border: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                  &:last-child {
                    &.k-focus, &:not(:focus) {
                    .k-textbox {
                        height: 25px;
                        border-top: 0px!important;
                        border-bottom: 0px!important;
                        border-right: 0px!important;
                        padding: 4px 6px;
                        border-left:  0px solid $base-background-color!important;
                      }
                    }
                  }
                  .k-dropdownlist {
                    min-width: auto;
                    &.k-focus, &:not(:focus) {
                      border: 0px;
                    }
                  }
                }
              }
            }
        }
      }
    }
  }
}