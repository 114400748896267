.lv-numeric-text-box {

  .k-numerictextbox {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    position: relative;
    transition: all .1s ease;
    cursor: default;
    outline: 0;
    flex-direction: row;
    border-color: #1e2128;
  }

  .k-numerictextbox {
    &.lv-negative-label-color {

      .k-input-inner {
        color: $base-color-red;
      }
    }
  }

  &.lv-invalid {
    @include kendo_field_invalid;

    ::placeholder {
      color: darken($base-text-color, 50%)!important;
    }

    .k-numeric-wrap {

      &.k-state-focused {
        @include kendo_field_invalid;
      }
    }
  }

  &.lv-dirty {
    @include kendo_field_dirty;
  }
}
