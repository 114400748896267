@mixin lv_wlpdi_fixed_column($class, $width) {
  .lv-watch-list-panel-dialog-item {
    .lv-watch-list-panel-dialog-item-column {
      &.#{$class} {
        flex: none;
        width: $width;
      }
    }
  }
}

.lv-watch-list-panel-dialog-item {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  .lv-watch-list-panel-dialog-item-column-spacer {
    width: $base-padding-y * 2;
  }

  .lv-watch-list-panel-dialog-item-column {
    display: flex;
    flex-direction: column;

    flex: 1;

    .lv-watch-list-panel-dialog-item-column-header {
      padding-bottom: $base-padding-y;
      .lv-watch-list-panel-dialog-item-column-header-search-input {
        & input.k-textbox {
          border-radius: 2px;
          padding: 4px 6px;
          padding-left: 24px;
          width: 100%;
          border-width: 1px;
          border-style: solid;
        }
        }
    }

    .lv-watch-list-panel-dialog-item-column-content {
      position: relative;
      flex: 1;

      .k-grid {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
  
        .lv-icon-grid-column {
          text-align: center;
          padding: $base-padding-y 0;
        }
  
        .k-grid-table {
          tr {
            td {
              white-space: nowrap;
  
              @include lv_icon_square(16px);
            }
          }  
        }
      }

      &.watch-list {    
        border: 1px solid $base-component-border-color;
    
        .watch-list-container {
          position: absolute;
          top: $base-padding-y;
          right: $base-padding-y;
          bottom: $base-padding-y;
          left: $base-padding-y;
    
          overflow-x: hidden;
          overflow-y: auto;
    
          .lv-watch-list-item {
            @include lv_watch_list_item_selectable;
          }
        }
      }
    }

    .lv-watch-list-panel-dialog-item-column-footer {
      display: flex;
      flex-direction: row;

      padding-top: $base-padding-y;
    }

    &.lv-watch-list-panel-dialog-item-column--disabled {
      @include loading_state;
    }
  }
}