.lv-scenario-inputs {

  .lv-label {
    width: auto;
  }

  .lv-scenario-input {
    display: inline-flex;
    flex-direction: row;
    margin-right: $base-padding-x;

    .lv-label,
    .range-spacer,
    .range-suffix {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: $base-padding-x;
    }

    .range-spacer,
    .range-suffix {
      margin-left: calc($base-padding-x / 2);
    }

    .range-suffix {
      margin-right: 0;
      min-width: 38px;
    }

    .range-spacer {
      margin-right: calc($base-padding-x / 2);
    }

    &.shift {
      .lv-input-field {
        width: 180px;
      }
    }

    &.change {
      min-width: 183px;
      width: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}