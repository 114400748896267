.lv-tabular-outputs {
  padding-left: 6px;

  .lv-label {
    width: 160px;
    height: 25px;
    margin-top: 16px;
    padding: 4px 0px;
    font-weight: 600;
    color: $base-section-header-title-color;
  }

  .lv-label-before-calculation {
    padding-bottom: 0px;
    padding-top: 10px;
    margin-top: 0px;
    font-weight: 600;
    color: $base-section-header-title-color;
  }

  &:first-child {
    .lv-label {
      margin-top: 5px;
    }
    .lv-label-before-calculation {
      margin-top: 0px;
    }
  }

  .lv-tabular-schedule-grid {
    &.lv-advanced-grid {
      thead tr th:nth-child(2) {
        padding-left: 2px;
      }
      .lv-advanced-grid-header-wrapper {
        cursor:auto;
      }

      .k-grid table tbody tr:last-child {
        display: none;
      }
    }
  }
}
