.user-settings {
  width: 1000px;
  max-width: 1168px;
  min-width: fit-content;
  margin: 0 auto;
  border: 1px solid $base-border-color;



  .overflow-sections {
    height: 674px;
    overflow: auto;
  }

  .user-settings-title {
    height: 24px;
    line-height: normal;
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 8px;
    padding-top: 4px;
    border-bottom: 1px solid $base-border-color;
  }

  .user-settings-tabstrip {
    margin-top: 4px;
    flex: 1;
    .k-reset.k-tabstrip-items {
      border-top-width: 0px;
    }
  }
}

.lv-settings {
  height: 100%;

  .lv-section-height {
    height: 100%;
  }

  .lv-settings-menu {
    border: 1px solid $base-border-color;
    width: 272px;
    min-width: 272px;
  
    .lv-settings-header {
      .lv-settings-menu-header-content {
        font-size: 14px;
        font-weight: 600;
        color: $base-input-color;
        margin-left: 6px;
        margin-top: 3px;
      }
    }

    .lv-settings-menu-content {
      flex: 1;
      padding-top: 13px;
  
      .lv-settings-menu-content-item {
        height: 25px;
        padding-left: 10px;
        padding-top: 4px;
        color: $base-text-color-dimmed;
        cursor: pointer;
        margin-bottom: 5px;
        font-weight: 600;

        &.lv-settings-menu-content-item-selected {
          background-color: $base-accent-highlight-color;
          color: $base-color-white;
        }
  
        &:hover {
          background-color: $base-accent-highlight-color;
          color: $base-color-white;
        }
      }
    }
  }

  .lv-settings-data {
    flex: 1;
    border: 1px solid $base-border-color;

    .lv-settings-data-content {
      flex: 1;
      height: 100%;
      padding-top: 4px;
      overflow-y: auto;
    }

    .lv-settings-menu-additional {
      flex: 1;

      .lv-settings-menu-additional-header {
        background-color: $base-component-header-color;
        color: $base-input-color;
        height: 25px;
        padding-left: 7px;
        padding-top: 4px;
      }

      .lv-settings-data-content-item {
        height: 25px;
        color: $base-text-color-dimmed;
        cursor: pointer;
        padding-left: 7px;
        padding-top: 4px;
  
        &.lv-settings-data-content-item-evan {
          background-color: $base-border-color;
        }

        &:hover {
          background-color: $base-accent-highlight-color;
          color: $base-color-white;
        }
      }
    }

    .lv-settings-header {
      .lv-settings-menu-header-content {
        margin-left: 5px;
        margin-top: 4px;
      }
    }
  }

  .lv-settings-header {
    height: 25px;
    border-bottom: 1px solid $base-border-color;
  }

  .lv-settings-footer {
    height: 46px;
    border-top: 1px solid $base-border-color;
    padding: 0 20px;

    &.lv-settings-menu-footer {
      height: 46px;
    }

    .lv-settings-footer-divider {
      flex: 1;
    }

    button {
      width: 113px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px;
      height: 25px;

      &:first-child {
        margin-right: 653px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.save-settings-btn-disabled {
        cursor: default;
        background-color: $base-border-color;
        color: $base-text-color-dimmed;
      }
    }
  }

  .k-tabstrip {
    .k-tabstrip-items-wrapper {
      .k-tabstrip-items {
        .k-item {
          border-width: 1px 1px 1px 1px;
          border-left-color: transparent; 
          .k-link {
            padding: 2px 4px;
          }
        }
      }
    }
  }
}

.lv-settings-content {
  flex: 1;
  overflow-y: auto;

  .lv-section-settings-base {
    overflow: scroll;
    height: 100%;
  }
}