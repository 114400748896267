.lv-outputs-dialog {

  .lv-multi-select {
    width: 596px;
    .lv-multi-select-content {
      height: calc(100% - 43px);
      .lv-sortable-list {
        max-height: 627px;
      }
    }

    .lv-multi-select-header {
      margin-top: 6px;
    }

    .lv-multi-select-left,
    .lv-multi-select-right {
      .lv-multi-select-list-title {
        padding-top: 2px;
      }
    }
  }

  .lv-outputs-dialog-tabstrip {
    width: 100%;
    height: 100%;

    .k-tabstrip-content {
      border: none;
      padding: 0;
      margin-top: 20px;
    }

    .k-tabstrip-items-wrapper {
      position: absolute;
      width: 100%;
      margin-left: -11px;

      .k-tabstrip-items {
        .k-link:first-child {
          padding: 2px 6px;
        }
      }
    }
  }

  .lv-dialog-actions {
    height: 43px;
    margin-bottom: 0px;
    button {
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}

.lv-outputs-custom-selection-dialog {
  .k-window {
    max-height: 791px!important;
    .k-window-content {
      padding: 2px 6px;
      overflow: hidden;
    }
  }
  .k-window-titlebar {
    border-bottom-width: 0px;
    border-top-width: 2px;
    .k-window-title {
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}