@mixin kendo_field_invalid() {
  .k-input,
  .k-dateinput-wrap,
  .k-dropdown-wrap,
  .k-picker-wrap,
  .k-multiselect-wrap,
  .k-chip,
  .k-numeric-wrap {
    .k-input-inner {
      background-color: $base-error-color;
      color: $base-color-black;
      border-color: $base-input-border-color;
  
      &:hover{
        background-color: $base-error-color;
        color: $base-color-black;
        border-color: $base-input-border-color;
      }
      
      &.k-state-focused {
        background-color: $base-error-color;
        color: $base-color-black;
        border-color: $base-input-border-color;    
      }
    }
  }
}

@mixin kendo_field_dirty() {
  .k-dateinput-wrap,
  .k-dropdown-wrap,
  .k-picker-wrap,
  .k-multiselect-wrap,
  .k-numeric-wrap,
  .k-numerictextbox input{

    background-color: $base-color-orange;
    color: $base-color-black;
    border-color: $base-input-border-color;

    &:hover{
      background-color: $base-color-orange;
      color: $base-color-black;
      border-color: $base-input-border-color;
    }
    
    &.k-state-focused {
      background-color: $base-color-orange;
      color: $base-color-black;
      border-color: $base-input-border-color; 
    }
    
    input::placeholder {
      color: darken($base-text-color, 50%);
    }
  }
}

@mixin kendo_field_default {
  border-radius: 0;
  background-color: $base-input-background-color;      
  color: $base-input-color;
  border-color: $base-input-border-color;
}

.lv-input-field {
  .k-textarea,
  .k-textbox {
    width: 104px;

    @include kendo_field_default;

    &:hover {
      @include kendo_field_default;
    }

    &.k-state-focused {
      @include kendo_field_default;
    }
  }

  .k-icon {
    &.k-i-arrow-s,
    &.k-i-close {
      color: $base-input-color;
    }
  }

  input::placeholder {
    color: darken($base-text-color, 20%);
  }
}

.lv-input-field {
  .k-combobox,
  .k-dropdown {
    .k-dropdown-wrap {
      .k-input {
        padding-right: 0;
      }
    }
  }
}

.lv-input-field {
  .k-autocomplete,
  .k-combobox,
  .k-dateinput,
  .k-datepicker,
  .k-datetimepicker,
  .k-dropdown,
  .k-multiselect,
  .k-numerictextbox,
  .k-maskedtextbox,
  .k-timepicker {
    width: 104px;
    background-color: transparent;
    border-color: $base-input-border-color;
    height: 100%;

    .k-dateinput-wrap,
    .k-dropdown-wrap,
    .k-picker-wrap,
    .k-multiselect-wrap,
    .k-chip,
    .k-numeric-wrap {
      @include kendo_field_default();

      input::placeholder {
        color: darken($base-text-color, 20%);
      }

      &:hover {
        @include kendo_field_default;
      }

      &.k-state-focused {
        @include kendo_field_default;
      }

      .k-icon {
        &.k-i-arrow-s {
          color: $base-input-color;
        }
      }
    }

    .k-dropdown-wrap {
      &:hover {
        @include kendo_field_default();

        .k-icon {
          &.k-i-arrow-s {
            color: $base-input-color;
          }
        }
      }

      &.k-state-focused {
        @include kendo_field_default;
      }
    }

    &.ng-touched.ng-invalid {
      @include kendo_field_invalid;
    }
  }

  &.lv-input-field--full {
    .k-textbox,
    .k-autocomplete,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker,
    .k-textarea {
      width: 100%;
    }
  }

  &.lv-input-field--auto {
    .k-textbox,
    .k-autocomplete,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker,
    .k-textarea {
      width: auto;
      min-width: 104px;
    }
  }

  &.lv-input-field--right {
    .k-textbox,
    .k-autocomplete,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker,
    .k-textarea {
      input {
        text-align: right;
      }
        &.k-disabled {
          opacity: .6;
          filter: grayscale(.1);
          background-color: $base-input-background-color;
        }
    }
  }

  .k-dropdownlist.k-disabled {
    opacity: .6;
    background-color: $base-input-background-color;
  }
}

