
.asset-swap{
  height: 100%;
  .asset-swap-container {
    height: 100%;
    position: relative;

    .asset-swap-content {
      .k-tabstrip-items-wrapper {
        padding-top: 4px;
        .k-tabstrip-items {
          .k-item {
            &:first-child {
              .k-link {
                padding: 2px 5px 2px 2px;
              }
            }
            border-width: 1px;
            border-left-color: transparent; 
            .k-link {
              padding: 2px 4px;
            }
          }
        }
      }
    }
  }

  .asset-swap-cog {
    position: absolute;
    top: 6px;
    right: 6px;
    line-height: normal;
  
    .k-icon.k-i-gear {
      font-size: $base-font-size;
      color: $base-icon-color;
  
      &:hover {
        cursor: pointer;
        color: $base-text-color;
      }
    }
  }
}