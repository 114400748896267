.lv-dropdown-loader {
  width: 100%;
  height: 100%;
  position: relative;

  .no-data {
    width: 100%;
    height: 100%;

    div {
      width: 100%;
    }

    .k-i-warning {
      font-size: 36px;
    }
  }
}