.lv-create-instrument-modal {

  .lv-create-instrument-modal-content {
    height: 149.5px;
    padding-top: 17px;
    padding-left: 20px;

    .lv-flex-box:not(:first-child) {
      padding-top: 5px;
    }
  }

  .lv-create-instrument-modal-footer {
    width: 100%;
    height: 41px;
    border-top: $base-border-color solid 1px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 90px;

      &:first-child {
        &:not(:hover) {
          color: $base-text-color;
        }
      }

      &:last-child {
        margin-right: $base-padding-x*2-2;
        margin-left: 25px;
      }
    }
  }
}

.lv-create-instrument-modal-container-shrink {
  .lv-create-instrument-modal-content {
    height: 124.5px;
  }
}