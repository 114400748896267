.lv-equity-market-data-volatility {

  .lv-equity-market-data-tabstrip {
    padding-top: 3px;

    .lv-equity-volatility-flat {
      .lv-flex-box {
        &.lv-flex-box--row {
          padding-left: 7px;
          padding-top: 8px;
        }
      }
    }

    .lv-equity-volatility-terms-structure {
      .lv-volatility-grid-title {
        margin-top: 9px;
        margin-left: 7px;
        .lv-label {
          &.lv-label--title {
            padding-top: 3px;
          }
        }
      }

      .lv-equity-volatility-terms-structure-grid {
        &.lv-advanced-grid {
          padding-left: 7px;
          .k-grid {
            table {
              thead {
                tr {
                  th {
                    padding-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .lv-equity-volatility-surface {
      .lv-volatility-surface {
        margin-top: 9px;
        margin-left: 7px;

        .lv-volatility-surface-container {
          .lv-volatility-grid-title {
            margin-top: 3px;
          }
        }

        .lv-label {
          &.lv-label-field {
            padding-top: 3px;
          }
        }
      }
    }
  }

  .lv-equity-risky-risk-free {
    margin-bottom: 4px;
    .lv-label {
      &.lv-label-field {
        padding-left: 7px;
        padding-top: 4px;
      }
    }

    .lv-input-field {
      margin-left: 6px;
    }
  }
}