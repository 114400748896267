.coupon-settings-column {
  &:nth-child(1) {
    margin-right: 40px;
  }
  .lv-label {
    width: 170px;
  }
  input::placeholder {
    text-align: left;
  }
  .k-dropdownlist {
    min-width: 104px;
  }
}
.k-list-container {
  .k-list {
    .k-list-content {
      .k-list-ul {
        .k-list-item {
          min-height: 23px;
          padding: 5px 6px 4px 7px;
          height: inherit;
          &:nth-child(1) {
            padding-top: 4px;
          }
        }
      }
    }
  }
}

.coupon-settings-second-column {
  .lv-label {
    width: 160px;
  }
}