.lv-section-settings-base {
  height: 100%;
  flex: 1;

  .lv-section-settings-system-defaults {
    padding: 6px 8px 0px 8px;

    .lv-section-settings-system-defaults-title {
      margin-bottom: 10px;
    }
  }

  .lv-section-settings-environment-settings {
    flex: 1;
    // margin-top: 10px;

    >.lv-label-field {
      padding-left: 8px;
      margin-bottom: 5px;
      padding-top: 5px;
      margin-top: 10px;
    }

    .lv-tabstrip {
      flex: 1;
      .k-tabstrip-content {
        padding: 10px 8px;
      }
    }

    .lv-section-settings-system-defaults-override-system-defaults {
      margin-bottom: 10px;
    }

    &.lv-section-settings-environment-settings-my {
      border-top: 1px solid $base-border-color;
      margin-top: 20px;
    }
  }

  .k-disabled {
    .k-input-inner {
      background-color: $base-input-background-color;
      opacity: .6;
      filter: grayscale(.1);
      pointer-events: none;
      box-shadow: none;
    }

    &.k-dropdownlist {
      background-color: $base-input-background-color;
      opacity: .6!important;;
      filter: grayscale(.1);
      pointer-events: none;
      box-shadow: none;

      .k-input-inner {
        opacity: 1!important;
      }
    }
  }

  .lv-section-settings-environment-settings {
    .k-tabstrip-items-wrapper {
      .k-tabstrip-items {
        :nth-child(1) {
          min-width: 29px;
          max-width: 56px;
        }
        :nth-child(2) {
          width: 56px;
        }
      }
    }
  }
}
.lv-environment-content {
  padding-left: 8px;
}

.lv-section-settings-instrument-source {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lv-section-settings-instrument-source-settings-padding-margin {
  .lv-section-settings-base .lv-section-settings-environment-settings {
    .lv-environment-content {
      .lv-input-field {
        .lv-label.lv-label-field {
          padding-top: 1px;
        }
        .lv-field.lv-field--checkbox {
          padding-bottom: 6px;
          padding-top: 0px;
        }
      }
    }
  }
} 