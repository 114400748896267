.settings-confirm-dialog-wrapper {
  .k-dialog-content{
    padding: 0;
  }

  .settings-confirm-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    z-index: 9;
  
    .settings-confirm-content {
      width: 100%;
      height: auto;
      background-color: $base-background-color;
      text-align: center;
      z-index: 1;
  
      .settings-confirm-content-row {
        height: 25px;
        margin-bottom: 16px;
        &:first-child {
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
  
      .settings-confirm-continue-editing {
        font-weight: 600;
        color: $base-accent-contrast-color;
      }
  
      .settings-confirm-options {
        height: 45px;
        padding: 0;
        border-top: 1px solid $base-border-color;
        justify-content: flex-end;
        padding-right: 14px;
        display: flex;
    
        button {
          width: 113px;
          height: 25px;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 11px;
      
          &:last-child {
            margin-right: 7px;
          }
        }
      }
    }  
  }
}
