.lv-bucketing-section-settings-table {

  &.lv-advanced-grid {
    .k-grid {
      height: 390px!important;
      table {
        // Move text in header cells to left, so it is in line with Zeplin.
        thead {
          tr {
            th {
              padding-left: 0px;
            }
          }
        }
        tbody {
          tr {
            td {
              &:first-child {
                &.lv-input-field--output {
                  .lv-advanced-grid-cell {
                    &.lv-advanced-grid-cell--right {
                      width: 98px!important;
                    }
                  }
                }
              }
              &:last-child {
                .lv-advanced-grid-cell {
                  &.lv-advanced-grid-cell--right {
                    width: 97px!important;
                    div {
                      max-width: 88px!important;
                    }
                  }
              }
              }
            }
          }
        }
      }
    }
  }
}