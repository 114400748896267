.lv-grid-layout {
  display: flex;
  flex-direction: row;
  width: 100%;

  .lv-grid-layout-column {
    display: flex;
    flex-direction: column;

    margin-right: 2px;

    .lv-grid-layout-cell {
    }

    &.lv-grid-layout-column--last {
      margin-right: 0;
    }
  }
}