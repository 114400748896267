.lv-asset-swap-terms-section-settings {
    display: flex;

    .lv-asset-swap-section-title {
        margin-top: 11px;
        text-transform: none !important;
    }
    
    .lv-asset-swap-reference-ir-swap-dropdown {
        margin-left: 10px;
    }    

    .lv-asset-swap-terms-settings-column {
        &:nth-child(1) {
          margin-right: 40px;
        }
        .lv-label {
          width: 170px;
        }
        input::placeholder {
          text-align: left;
        }
        .k-dropdownlist {
          min-width: 104px;
        }
    }

    .lv-asset-swap-terms-settigs-second-column {
        margin-left: 42px;
        
        .lv-label {
            width: 160px;
            text-transform: none !important;
          }
    }
}
