.lv-model-customization-equity-settings-settings {
    .lv-settings-content {
        .lv-section-settings-base {
            .lv-section-settings-system-defaults {
                .lv-section-settings-system-defaults-title {
                    padding-top: 5px;
                }
            }
        }
    } 
}