.lv-pricing-side-panel {
  width: 418px;

  .lv-pricing-side-panel-content {
    padding-top: $base-padding-x + 2;
    padding-left: $base-padding-x + 4;
    padding-bottom: 10px;

    .lv-flex-box {
      .lv-field--radio {
        &:first-child {
          width: 200px;
        }
      }

      .lv-label-field {
        width: 190px;
      }
    }

    .lv-label--title {
      margin-top: $base-padding-x+$base-padding-y;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .lv-pricing-side-panel-footer {
    height: 42px;
    border-top: $base-border-color solid 1px;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 90px;

      &:last-child {
        margin-right: $base-padding-x*3+2;
        margin-left: $base-padding-x*2;
      }
    }
  }
}
