.lv-text-box {
  display: block;
  position: relative;

  .k-input {
    &:focus {
      border: 1px solid $base-color-blue;
    }
  }

  input {
    flex: 1;        
    padding-right: ($base-font-size * 2);
  }

  .k-icon {
    position: absolute;
    top: 6px;

    line-height: $base-font-size + 2px;
    font-size: $base-font-size + 2px;

    color: $base-text-color;
  }

  .k-i-search {
    left: calc($base-font-size / 2);
    z-index: 1;
  }

  .k-i-close {
    right: calc($base-font-size / 2);
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &.lv-text-box-searchable {
    input {
      padding-left: ($base-font-size * 2);
    }
  }

  &.lv-invalid {
    input {
      background-color: $base-error-color;
      color: $base-color-black;
    }
    
    input::placeholder {
      color: darken($base-text-color, 50%);
    }
  }
}