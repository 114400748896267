.lv-icon {
  font-size: $base-font-size;
  color: $base-icon-color;

  &.lv-icon--loading {
    @include loading_state;

    &:hover {
      cursor: default;
    }
  }

  &:hover {
    cursor: pointer;
    color: $base-text-color;
  }
}