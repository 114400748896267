@import "variables";

@import "mixins/k-checkbox";
@import "mixins/field";
@import "mixins/loading";
@import "mixins/icon-square";

// size
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
// @include size(300px, 200px);

// - clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// @include clearfix();
// MEDIA QUERIES
@mixin bp($width,$direction:max) {
  @if $direction == min {
    @media screen and (min-width: $width) { @content; }
  }
  @else {
    @media screen and (max-width: $width) { @content; }
  }
}
// @include bp($point_3) { }

@mixin scrollbar($foreground-color, $background-color) {
  // For Google Chrome

  ::-webkit-scrollbar {
    background: $background-color;
  }

  ::-webkit-scrollbar-corner {
    background: $background-color;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
      border: 1px solid $background-color;

      &:hover {
        background: lighten($foreground-color, 10%);
      }
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
