.lv-pricing {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.lv-pricing-hide-cog {
    .k-i-cog{
      visibility: hidden;
    }
  }

  .lv-settings-widget {
    .lv-settings-widget-content {
      overflow: hidden;
      padding: 0;
    }
  }

  .lv-pricing-content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .lv-pricing-inner-content{
    flex: 1;
    overflow: auto;
    height: 100%;

    .lv-pricing-inner-content-inputs {
      margin: 0 9px 0 8px;
    }
  }

  .lv-pricing-widget-buttons-section {
    min-width: 200px;

    &.lv-pricing-buttons-xl-label-position {
      display: block;
    }

    button {
      background-color: #b84c4c;
      border-color: #b84c4c;
      color: $base-color-white;

      &:hover {
        background-color: #b84c4c;
        border-color: #b84c4c;
      }

      &.lv-pricing-widget-buttons-sectio-nia {
        background-color: #45684d;
        border-color: #45684d;

        &:hover {
          background-color: #45684d;
          border-color: #45684d;
        }
      }

      &.lv-pricing-buttons-new-issue{
        width: 65px;
      }

      &.lv-pricing-buttons-estimates{
        width: 73px;
      }
    }

    .lv-pricing-custom-view-settings-wide-form {
      margin-left: 20px;
    }

    .lv-pricing-custom-view-settings-narrow-form {
      margin-bottom: 9px;
      margin-left: 8px;
      margin-top: 9px;
    }
  }

  .lv-conversion-price-estimate {
    margin-left: $base-padding-x + 3;

    .lv-field {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .lv-pricing-cpEstimate-message {
    align-self: center;
    float: right;
    margin-left: auto;
    margin-right: 5px;
  }

  .lv-valuation-inputs {
    display: inline-block;
    vertical-align: top;

    margin-bottom: $base-padding-x * 2;
    margin-right: $base-padding-x * 3;

    .lv-valuation-inputs-row {
      width: 100%;

      .lv-flex-box {
        display: inline-flex;
      }

      //todo solve input dinamical with on the same way..

      &.lv-valuation-inputs-row--right-margin {
        margin-right: 10px;
      }

      .k-input.k-dateinput.k-disabled {
        opacity: 1;
        background-color: #1e2128;
      }
    }

    .k-datepicker {
      border: 1px solid #1e2128;
    }
  }
}

.lv-pricing-side-panel-window {

  .k-window{
    .k-window-content {
      padding: 0px;
    }
  }
}

.lv-side-panel-position {
  position: relative;
}

.lv-side-panel {

  .lv-new-issue-assumptions {
    min-width: 451px;
    
  .lv-new-issue-assumption-column {
    width: 80px;
    height: 25px;
    
    kendo-numerictextbox {
        width: 100%;
      }
      
      &.lv-new-issue-assumption-column-rb {
        text-align: center;
      }
      
      &.lv-new-issue-assumption-column-value {
        text-align: right;
        border: 1px solid $base-border-color;
        padding-right: 6px;
      }
      
      &.lv-new-issue-assumption-first-column {
        width: 131px;
      }
      
      .lv-label {
        vertical-align: sub;
      }
      
      .lv-label-white {
        color: $base-color-white;
      }
      
      &.lv-new-issue-assumption-column--disbled {
        .lv-input-field.lv-input-field--right {
          .k-numerictextbox {
            &.k-disabled {
              opacity: 1;
              border: 1px solid #41454d;
            }
          }
        } 
        .k-input-inner {
          background-color: $base-background-color;
        }
      }

      .lv-xl-label {
        vertical-align: sub;
      }

      &.lv-label-field-left {
        text-align: left;
      }
    }
  }
}