// This should be imported manually in the styles.scss of the application
// It is used to style saved passwords when applied to an input

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px $base-component-header-color inset;    
    -webkit-text-fill-color: $base-text-color !important;    
}