.lv-floating-label {
  position: relative;

  .lv-floating-label-content {
    width: 36px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    
    .lv-floating-label-text-container {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 100%;

      .lv-floating-label-text {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: #8E9AA9;
        font-size: 12px;
      }
    }
  }

  input {
    padding-right: 36px;
  }

  kendo-numerictextbox {
    width: 100%;
    
    .k-input-inner {
      padding-right: 36px;
    }
  }
}