.lv-averaging-data {
  width: 100%;
  height: 100%;
  min-width: 325px;

  .lv-averaging-data-content {
    flex: 1;
    overflow: auto;
  }

  .lv-label {
    min-width: 170px;
  }
}
