.lv-instrument-monitor {
  height: 100%;
  table tbody tr td {
    white-space: nowrap;
    &:first-child {
      max-width: 430px;
    }
  }
  .k-grid-table {
    tr {
      td {
        white-space: nowrap;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }

  .lv-instrument-monitor-icon {
    position: absolute;
    width: 18px;
    right: 0;
    top: 8px;

    i {
      position: absolute;
      font-size: 15px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .lv-instrument-monitor-instrument-name {
    text-overflow: ellipsis;
    width: 95%;
    overflow: hidden;
  }
}

.lv-instrument-monitor-watch-lists-menu-item {
  i {
    margin-left: $base-padding-y;
  }
}

.lv-instrument-monitor-watch-lists {
  position: fixed;

  background-color: $base-popup-color;
  border: 1px solid;
  border-color: $base-border-color;
  padding: $base-padding-y $base-padding-x;

  overflow-x: hidden;
  overflow-y: auto;

  color: $base-text-color;

  .lv-watch-list-item {
    @include lv-watch-list-item_selectable;
  }

  &:hover {
    color: $base-text-color;
  }
}
