.lv-sensitivity-analysis-panel-dialog {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 350px;
  // height: 200px;

  z-index: 9;

  .lv-sensitivity-analysis-panel-dialog-mask {

    background-color: $base-color-black;
    opacity: 0.5;
  }

  .lv-sensitivity-analysis-panel-dialog-content {
    width: auto;
    height: auto;

    padding: $base-padding-y $base-padding-x;
    background-color: $base-background-color;

    text-align: center;

    z-index: 1;

    div:not(.lv-sensitivity-analysis-panel-dialog-options) {
      padding-left: $base-padding-x;
      padding-right: $base-padding-x;

      &:first-child {
        padding-top: $base-padding-x;
      }
    }

    .continue-editing-item-name {
      font-weight: 600;
      color: $base-accent-contrast-color;
    }

    .lv-sensitivity-analysis-panel-dialog-options {
      margin-top: 3 * $base-padding-x;

      text-align: right;
    
      .k-button {
        width: 100px;
        margin-right: $base-padding-x;
    
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }  
}