.lv-terms-settings {
  height: 100%;

  .align-sufix {
    display: flex;
    align-items: center;
    .lv-sufix-label {
      width: auto;
    }
  }
}
