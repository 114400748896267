.k-dropdown-wrap {
  background-color: $base-component-header-color;
  .k-select {
    background-color: transparent;
    border-color: transparent;
  }

  &:hover {
    .k-select {
      background-color: transparent;
      border-color: transparent;
    }
  }
}