.lv-field {
  @include field_height;

  &.lv-field--radio,
  &.lv-field--checkbox {
    display: inline-flex;
    margin-right: $base-padding-x;
    margin-left: 1px;

    .k-radio,
    .k-checkbox,
    .k-radio-label,
    .k-checkbox-label {
      margin-top: auto;
      margin-bottom: auto;
    }

    .k-radio-label,
    .k-checkbox-label {
      margin-top: auto;
      margin-bottom: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
