.not-supported-browser {
  min-width: 420px;
  
  .text {
    margin-bottom: 16px;
  }

  .browsers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 16px;

    .browser {
      width: 120px;
      text-decoration: none;
      color: inherit;

      .browser-name {
        width: 100%;
        text-align: center;
      }
    }
  }
}