.lv-table {
	display: table;
	max-width: 100%;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-weight: 400;

	td,
	th {
		vertical-align: middle;
		padding: 4px 8px;
		border: none;
	}
	th {
		text-align: left;
	}

	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th, > td {
				border-top: 1px solid $base-component-border-color;				
			}
		}
	}

	> thead > tr {
		> th, 
		> td {
			border-top: none;
			border-bottom: 2px solid $base-component-border-color;
			color: $base-text-color;
			font-weight: 600;
		}
	}
}

.lv-display-table {
	display: table;
	table-layout: fixed;
	width: 100%;

	.lv-display-table-row {
		display: table-row;

		.lv-display-table-cell {
			display: table-cell;
			text-align: left;
			vertical-align: middle;

			.lv-display-table-cell-container {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			&.lv-display-table-cell--relative {
				position: relative;
			}

			&.align-right {
				text-align: right;
			}

			&.padded-right {
				padding-right: $base-padding-y;
			}

			&.padded-left {
				padding-left: $base-padding-y;
			}
		}
	}
}
