.lv-equity-borrow-term-structure {
  .lv-borrow-grid-title {
    .lv-label {
      padding-top: 12px;
      padding-left: 7px;
    }
  }

  .lv-equity-borrow-term-structure-table {
    padding-left: 7px;
    margin-top: 9px;
  }
  // Move header text at the begining of cell.
  .lv-advanced-grid .k-grid table thead tr th {
    padding-left: 0px;
  }

  .k-grid {
    th {
      border-left-width: 0px;
    }
  }
}