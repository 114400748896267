.lv-dividends-dividend-parameters {
  .lv-dividends-source-controls {
    margin-top: 15px;
    width: 100%;

    button {
      width: 147px;
      margin-right: $base-padding-y;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lv-dividend-yield-note {
    margin-top: $base-padding-x + 4;
  }

  .lv-header-label {
    font-weight: 600;
    color: $base-color-red;
  }

  .lv-dividend-yield-note-content {
    width: 512px;
    padding-top: 4px;
  }

  .lv-datePicker-container {
    margin-left: 1px;
    .k-button {
      &.k-input-button {
        margin-right: 2px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

  }
}
