.lv-credit-terms-structure-grid {
  padding-top: 6px;
  &.lv-advanced-grid {
    .k-grid {
      table {
        // Move text in header cells to left, so it is in line with Zeplin.
        thead {
          tr {
            th {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
}