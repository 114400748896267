.lv-other {
  padding: $base-padding-x + 4 $base-padding-x + 2;
  .lv-fieldset-panel-content {
    padding: 13px 8px 8px;
  }
    .issue-info-fieldset {
      height: 401px;
      .lv-fieldset-panel-content {
        height: 100%;
      }
      .lv-label {
        width: 133px;
        line-height: 17px;
        padding: 4px 0;
        &-header {
          width: 115px;
          line-height: 17px;
          padding: 4px 0;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
      &-output-value {
        &-cell {
          width: 115px;
          &:first-child {
            margin-right: 10px;
          }
        }
        &-use {
          width: 240px;
          height: 142px;
        }
      }
    }
    .lv-other-output {
      color: $base-color-white;
      line-height: 17px;
      padding: 4px 0;
    }
  .other-fieldset {
    margin-left: 5px;
    height: 401px;
    .lv-fieldset-panel-content {
      height: 100%;
    }
    .lv-label {
      line-height: 17px;
      padding: 4px 0;
    }
    .other-fieldset-output-value {
      width: 195px;
    }
  }
  .involved-parties-fieldset {
    .lv-fieldset-panel-content {
      height: 100%;
      max-height: 171px;
      width: 423px;
      overflow: auto;
    }
    .lv-label-header {
      width: 170px;
      line-height: 17px;
      padding: 4px 0;
    }
    .involved-parties-fieldset-role-value {
      width: 170px;
    }
    .involved-parties-fieldset-organization-value {
      width: 235px;
    }
  }
  .issue-price-fieldset {
    margin-left: 5px;
    .lv-fieldset-panel-content {
      padding: 13px 10px 8px;
    }
    .lv-label-header {
      width: 60px;
      line-height: 17px;
      padding: 4px 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    .lv-label {
      width: 120px;
      line-height: 17px;
      padding: 4px 0;
    }
    .issue-price-fieldset-output-value {
      width: 60px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .no-padding {
    padding: 0;
  }
}
