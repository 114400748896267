.lv-input-field-component {
  display: flex;
  flex-direction: row;
  align-items: center;

  .lv-input-field-label {

    .lv-input-field-label-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .lv-label{
        flex: 1 1 auto;
      }

      .lv-input-field-checkbox {
        padding-left: 2px;

        @include k_checkbox_centered;
      }
    }
  }
  .lv-input-field-value {
    padding: 4px 6px;
    height: calc( 8px + 1.25em );
    box-sizing: border-box;
    border: 0;
    outline: 0;
    color: inherit;
    background: none;
    font: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    width: 103px;
  }
  .lv-input-field-content {
    flex: 1 1 auto;
  }

  .lv-input-field-suffix {
    width: 40px;
    padding-left: 4px;
  }
}
