.k-button-group {
  .k-button {
    width: auto;
    margin-right: $base-padding-x;

    border-radius: $base-border-radius;

    background-color: #505a67;
    color: $base-text-color;
    font-weight: 600 ;

    &.k-active {
      background-color: $base-accent-highlight-color !important;
      color: $base-text-color-highlight !important;
    }

    &.k-selected {
      background-color: $base-accent-highlight-color !important;
      color: $base-text-color-highlight !important;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}