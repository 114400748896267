.lv-model-customization-equity-option-parts {
    display: flex;
    .lv-model-customization-equity-option-warrant-parameters {
        margin-top: 11px;
    }
    .lv-model-customization-equity-option-section-container {
        flex-direction: row;

        .lv-model-customization-equity-option-settigs-second-column {
            margin-left: 160px;
            .lv-model-customization-equity-option-settigs-call-adjustments-fields {
                width: 180px;
            }
        }
    } 
}