.lv-convertible-bond-terms-accretion {
  min-width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-label-before-checkbox {
    width: 142px;
  }

  .lv-accretion-checkbox {
    margin-left: 7px;
  }

  .lv-accretion-checkbox-label {
    margin-left: 6px;
  }

  .lv-field.lv-field--checkbox {
    margin-right: 0px;
  }
 }
