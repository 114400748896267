.schedule-dividends-table-wrapper {
  .lv-schedule-dividends-table {
    padding: 0px 0px 10px 0px;
    max-width: 500px;
    .k-grid{
      height: 100%;
    }

    &.lv-advanced-grid {
      .k-grid {
        table {
          // move text in header cells to left, so it is in line with Zeplin.
          thead {
            tr {
              th {
                padding-left: 0px;
              }
            }
          }

          tbody tr {
            //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
            &.k-grid-edit-row {
            .lv-input-field {
              .k-dropdownlist {
                min-width: auto;
                &.k-focus,  &.k-selected,  &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
              &:last-child {
                .lv-input-field {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                  .k-dropdownlist {
                    &.k-focus,  &.k-selected, &:not(:focus) {
                      border-top: 1px solid $base-background-color;
                      border-bottom: 1px solid $base-background-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .lv-dividend-yield-warning {
    width: 512px;
  }
}