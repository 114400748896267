.underlying-container {
  .underlying-title {
    &.lv-flex-box {
      &.lv-flex-box--column {
        height: 25px;
        color: $base-color-orange;
        padding: 7px 4px;
      }
    }
  }

  .lv-equity { 
    .lv-equity-container {
      .lv-equity-content {
        .k-tabstrip-items-wrapper {
          padding-top: 4px;
          .k-tabstrip-items {
            background-color: $base-background-color;
            border-top: 1px solid $base-border-color;
            border-bottom: 1px solid $base-border-color;
            height: 21px;
              .k-item {
                &:first-child {
                  .k-link {
                    padding: 2px 5px 2px 4px;
                  }
                }
                background-color: $base-background-color;
                border-width: 1px;
                border-left-color: transparent; 
                .k-link {
                  padding: 2px 4px;
                }
              }
          }
        }
      }

      .lv-equity-cog {
        top: 4px;
      }
    }
  }
}