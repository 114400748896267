.workspace {
  display: inline-block;
  width: auto;
  max-width: 156px;
  height: 26px;
  padding: 6px 6px 6px 10px;
  user-select: none;
  // background-color: $base-background-color;
  // border-bottom: 2px solid $base-color-black;
  
  .name {
    min-width: 36px;
    max-width: 126px;
    float: left;
    line-height: 14px;
    font-weight: 600;
    margin-right: 4px;
    color: $base-text-color-inactive;
    
    div {      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input {
    float: left;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: transparent;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: 600;
    color: $base-text-color-highlight;
    margin-top: -3px;
  }

  .options {
    float: left;
    line-height: 12px;
    min-width: 10px;

    i {
      font-size: 10px;

      &:hover {
        cursor: pointer;
        opacity: 0.6;   
      }
    }
  }

  &.selected {
    padding: 4px 6px 6px 10px;
    background-color: $base-component-header-color;
    border-top: 2px solid $base-accent-color;
    // border-bottom: 2px solid $base-component-header-color;

    .name {
      color: $base-text-color-highlight;
    }
  }

  &.disabled {    
    opacity: 0.6;
    cursor: not-allowed;

    i {
      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
  }
}