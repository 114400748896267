.lv-withholding-tax {
  width: 100%;
  height: 100%;
  min-width: 315px;

  .lv-withholding-tax-content {
    flex: 1;
    overflow: auto;
  }
}

