// component loader
.lv-golden-layout-loader-container {
  position: relative;
  width: 100%;
  height: 100%;

  .lv-golden-layout-loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
  }
}

@import "theme/base";
@import "theme/dark";

body {
  overflow: hidden;
}