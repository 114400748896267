.lv-convertible-bond-terms-calls {

  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-call-first-col {
    min-width: 396px;
    &.lv-bond-call-first-col {
      min-width: 390px;
    }
  }

  .lv-checkbox-left {
    margin-left: $base-padding-x + 1;
  }

  .lv-clean-up-margin {
    margin-left: 13px;
  }

  .lv-clean-up-section {
    margin-top: 5px;

    &.lv-bond-clean-up-section {
      margin-top: 12px;
    }

    .lv-fieldset-panel {
      width: 286px;
    }
  }

  .lv-call-fieldset {
    margin-top: $base-padding-x + 3;
    &.lv-bond-call-fieldset {
      margin-top: $base-padding-x + 6;
      .lv-fieldset-panel-content {
        padding: 13px 6px 6px;
      }
    }
  }

  .lv-call-trigger-section {
    margin-top: $base-padding-x - 1;
  }

  .lv-fieldset-panel {
    width: 702px;
  }
  .lv-convertible-bond-terms-calls-top-options {

    .lv-label-callable {
      width: 60px;
    }
    .lv-label-clean-up {
      width: 90px;
    }

  }

  .lv-call-readonly-date {
    .lv-field.lv-field--checkbox {
      display: none;
    }
    .lv-datepicker-readonly {
      .lv-label {
        margin-left: 6px;
      }
    }
  }

  .lv-terms-calls-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
          &.k-grid-edit-row {
          .lv-input-field {
            .k-dropdownlist {
              min-width: auto;
              &.k-focus,  &.k-selected,  &:not(:focus) {
                border-top: 0px;
                border-bottom: 0px;
              }
            }
            .k-numerictextbox, .k-datepicker {
              &.k-focus, &:not(:focus) {
                border-top: 0px;
                border-bottom: 0px;
              }
            }
          }
            &:last-child {
              .lv-input-field {
                &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
                .k-dropdownlist {
                  &.k-focus,  &.k-selected, &:not(:focus) {
                    border-top: 1px solid $base-background-color;
                    border-bottom: 1px solid $base-background-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
