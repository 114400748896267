.lv-equity-option-settings {
  height: 100%;

  .lv-equity-option-general-settings {
    height: inherit;
    flex: 1;
    overflow: hidden;

    .lv-terms-section-settings-system-defaults {
      padding-top: 7px;
      padding-left: 6px;
    }

    .lv-terms-section-settings-my-defaults {
      padding-left: 6px;
    }
  }

  .lv-equity-option-terms-settings-column {
    margin-right: 158px;
    width: 312px;
  }

  .lv-equity-option-terms-section-settings {
    display: flex;

    .lv-equity-option-field {
        margin-right: 10px;
    }

    .lv-equity-option-section-title {
        margin-top: 10px;
        text-transform: none !important;
    }

    .lv-equity-option-terms-settigs-second-column {
        
        .lv-label {
            width: 160px;
            text-transform: none !important;
          }
    }
  }

  .lv-equity-option-dividend-protection-settings {
    .lv-terms-section-settings-system-defaults {
      padding-top: 7px;
      padding-left: 7px;
    }
    .lv-terms-section-settings-my-defaults {
      padding-left: 7px;
    }
  }
}

.lv-market-data-settings-container {
  .lv-section-settings-system-defaults {
    padding-top: 7px;
    padding-left: 6px;;
  }
  
  .lv-section-settings-environment-settings {
    > .lv-label-field {
      padding-left: 6px;
    }
    .lv-tabstrip .k-tabstrip-content {
      padding: 10px 6px;
    }
  }
}
