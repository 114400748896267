.lv-coupon-floating {

  .lv-floating-coupon-first-col {
    min-width: 400px;
  }

  .lv-floating-coupon-index {
    width: 185px;
  }

  .lv-floating-bottom-section {
    margin-top: 5px;
    .lv-floating-custom-dates-schedule {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-floating-spread-schedule {
    margin-right: 25px;
    .lv-spread-schedule {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-numerictextbox, .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-fixing-history-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table {
          // move text in header cells to left, so it is in line with Zeplin.
          thead {
            tr {
              th {
                padding-left: 0px;
              }
            }
          }
          tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-numerictextbox, .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
          
      }
    }
  }

  .lv-floating-coupon-fixing-history-container {
    padding-right: 16px;
  }
}
