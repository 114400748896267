.lv-scenario-chart-wrapper
{
  .lv-scenario-2d-chart {
    height: 100%;
    
    display: flex;
    flex-direction: column;
  
    .lv-scenario-chart {
      flex: 1;
      height: auto;
    }
  }
  
  .lv-scenario-chart {
    height: 100%;
  }
  
  .lv-scenario-chart-actions {
    margin-top: $base-padding-y;
  }
}
