@import "grid";
@import "tabstrip";
@import "button";
@import "button-group";
@import "form";
@import "list";
@import "dropdown-icon";
@import "checkbox";
@import "radio";
@import "switch";
@import "multi-select";
@import "list-scroller";
@import "dialog";
@import "dropdown-list";
@import "time-picker";

.k-textbox,
.k-autocomplete,
.k-combobox,
.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-multiselect,
.k-numerictextbox,
.k-maskedtextbox,
.k-timepicker {
  background-color: $base-input-background-color;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  position: relative;
  box-sizing: border-box;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  
  .k-dropdown-wrap {
    background-color: $base-input-background-color;
  }

  .k-input-inner {
    background-color: $base-input-background-color;
    padding: 4px 6px;
    height: calc(8px + 1.25em);
    box-sizing: border-box;
    border: 0;
    outline: 0;
    color: $base-input-color;
    font: inherit;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
