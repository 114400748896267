// Defaults

html {
	height: 100%;
	box-sizing: border-box;
}

// Default body styles
body {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: $base-background-color;
  color: $base-text-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-style: normal;	
	-webkit-font-smoothing: antialiased;
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

img {
	height: auto;
	max-width: 100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $base-input-placeholder-color;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $base-input-placeholder-color;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: $base-input-placeholder-color;
}