.workspace-panel-dialog {
  p {
    margin: 30px; 
    text-align: center;
  }

  .k-window.k-dialog {
    height: 141px;
    width: max-content;

    .k-actions.k-actions-horizontal {
      justify-content: space-around;
      overflow: hidden;
      margin: 0px;
      .k-button {
        max-width: 100%;
        height: 25px;
        background-color: inherit;

        &:first-child {
          color: #3b6184;
        }

        &:last-child {
          padding: 0px;
        }

        &:hover {
          background-color: $base-color-blue;
          color: $base-color-white;
        }
      }
     
    }
  }
}