// Spacing
$spacer: 1em !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

// Fonts

/// Base font size across all components.
/// @group fonts
$font-size: $base-font-size !default;
$font-size-xs: 9px !default;
$font-size-sm: 10px !default;
$font-size-lg: 13px !default;

/// Font family across all components.
/// @group fonts
$font-family: $base-font-family !default;

/// Line height used along with $font-size.
/// @group fonts
$line-height: $base-line-height !default;
$line-height-sm: 1.2 !default;
$line-height-lg: 1.6 !default;

// Font weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

// Paragraph
$paragraph-margin-bottom: $font-size !default;


// Metrics
$padding-x: $base-padding-x !default;
$padding-y: $base-padding-y !default;

/// Border radius for all components.
$border-radius: $base-border-radius !default;

$panel-padding-x: $padding-x !default;
$panel-padding-y: $padding-y !default;

$popup-padding-x: 0 !default;
$popup-padding-y: 0 !default;

$header-padding-x: $padding-x !default;
$header-padding-y: $padding-y * 2 !default;

$form-line-height: $line-height !default;
$form-line-height-em: $form-line-height * 1em !default;

$button-border-width: $base-button-border-width !default;

$input-border-width: $base-input-border-width !default;

$nav-item-margin: 0 !default;

$cell-padding-x: $base-cell-padding-x !default;
$cell-padding-y: $base-cell-padding-y !default;
$cell-padding: $base-cell-padding !default;

$window-inner-padding-x: 6px !default;
$window-inner-padding-y: 4px !default;
$window-titlebar-padding-x: 6px !default;
$window-titlebar-padding-y: 4px !default;
$window-title-line-height: 1.4 !default;

$progressbar-height: 18px !default;

$zindex-popup: 1;
$zindex-window: 2;

$rgba-transparent: rgba( 0, 0, 0, 0 );

// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
$accent: $base-accent-color !default;

/// The color used along with the accent color denoted by $accent.
/// Used to provide contrast between the background and foreground colors.
$accent-contrast: $base-accent-contrast-color !default;

/// The color for informational messages and states.
$info: $base-info-color !default;
/// The color for success messages and states.
$success: $base-success-color !default;
/// The color for warning messages and states.
$warning: $base-warning-color !default;
/// The color for error messages and states.
$error: $base-error-color !default;

$color-level-step: 8% !default;

$text-color: $base-text-color !default;
$bg-color: $base-background-color !default;
$border-color: $base-border-color !default;

/// The text color of the components' chrome area.
$base-text: $base-text-color !default;
/// The background of the components' chrome area.
$base-bg: $base-background-color !default;
/// The border color of the components' chrome area.
$base-border: $base-component-border-color !default;
/// The gradient background of the components' chrome area.
$base-gradient: none !default;

/// The text color of hovered items.
$hovered-text: $base-accent-contrast-color !default;
/// The background of hovered items.
$hovered-bg: $base-accent-highlight-color !default;
/// The border color of hovered items.
$hovered-border: $base-component-border-color !default;
/// The gradient background of hovered items.
$hovered-gradient: none !default;

/// The background of selected items.
$selected-bg: $base-accent-highlight-color !default;
/// The text color of selected items.
$selected-text: $base-accent-contrast-color !default;
/// The border color of selected items.
$selected-border: $base-accent-highlight-color !default;
/// The gradient background of selected items.
$selected-gradient: null !default;

$selected-hover-bg: shade( $selected-bg ) !default;

$focused-shadow: none !default;

$transition: background-color .2s ease !default;

$invalid-border: rgba($error, .5) !default;

$gradient-transparent-to-black: null;
$gradient-transparent-to-white: null;
$gradient-black-to-transparent: null;
$gradient-white-to-transparent: null;

$gradient-rainbow: $base-gradient-rainbow !default;

// Widget
$widget-border: $border-color !default;

// Panel
$panel-border: $border-color !default;

// Popup
$popup-bg: $base-popup-color !default;
$popup-shadow: none !default;

// Header
$header-bg: $base-component-header-color !default;
$header-border: $border-color !default;
$header-gradient: null !default;
$header-hovered-gradient: null !default;
$header-selected-gradient: null !default;

// Button
$button-text: $base-text-color !default;
$button-bg: $base-button-background-color !default;
$button-border: $base-border !default;
$button-gradient: none !default;
$button-shadow: none !default;
$button-hovered-text: $hovered-text !default;
$button-hovered-bg: darken( $base-button-background-color, 5%) !default;
$button-hovered-border: $hovered-border !default;
$button-hovered-gradient: $hovered-gradient !default;
$button-hovered-shadow: none !default;
$button-pressed-text: $button-text !default;
$button-pressed-bg: $button-bg !default;
$button-pressed-border: $button-border !default;
$button-pressed-gradient: none !default;
$button-pressed-shadow: none !default;
$button-focused-shadow: none !default;


// Primary button
$primary-button-text: $accent-contrast !default;
$primary-button-bg: $accent !default;
$primary-button-border: $primary-button-bg !default;
$primary-button-gradient: none !default;

$primary-button-hovered-text: $primary-button-text !default;
$primary-button-hovered-bg: adjust-hue( $primary-button-bg, 3) !default;
$primary-button-hovered-border: $primary-button-hovered-bg !default;
$primary-button-hovered-gradient: none !default;

$primary-button-pressed-text: $primary-button-text !default;
$primary-button-pressed-bg: $primary-button-bg !default;
$primary-button-pressed-border: $primary-button-pressed-bg !default;
$primary-button-pressed-gradient: none !default;
$primary-button-pressed-shadow: none !default;

$primary-button-focused-shadow: none !default;

$flat-button-hover-opacity: .08 !default;
$flat-button-focused-opacity: false !default;
$flat-button-active-opacity: .16 !default;
$flat-button-selected-opacity: .2 !default;

// Toolbar
$toolbar-gradient: null !default;

// Input
$input-default-width: 100% !default;
$input-shadow: none !default;
$input-hovered-shadow: none !default;
$input-focused-border: $accent !default;
$input-focused-shadow: none !default;

$input-bg: $base-component-header-color !default;

// Checkboxes
$checkbox-icon-size: $base-icon-size - 4px !default;
$checkbox-border: $base-checkbox-border-color;
$checkbox-bg: $base-input-background-color;
$checkbox-hovered-border: $base-checkbox-border-color;

// Link
$link-hover-text: $hovered-text !default;

// Node
$node-gradient: null !default;
$node-hovered-gradient: null !default;
$node-selected-gradient: null !default;

// List
$list-item-text: $text-color !default;
$list-item-bg: $popup-bg !default;

$list-item-hovered-text: $base-accent-contrast-color !default;
$list-item-hovered-bg: $base-accent-highlight-color !default;

$list-item-selected-text: $selected-text !default;
$list-item-selected-bg: $selected-bg !default;
$list-item-focused-shadow: none !default;

// Chart
/// The color of the first series.
/// @group charts
$series-a: $base-color-red !default;

/// The color of the second series.
/// @group charts
$series-b: $base-color-yellow !default;

/// The color of the third series.
/// @group charts
$series-c: $base-color-green !default;

/// The color of the fourth series.
/// @group charts
$series-d: $base-color-blue-dark !default;

/// The color of the fifth series.
/// @group charts
$series-e: $base-color-blue !default;

/// The color of the sixth series.
/// @group charts
$series-f: $base-color-purple !default;

$chart-font-size: $font-size !default;
$chart-font-size-sm: 11px !default;
$chart-font-size-md: 12px !default;
$chart-font-size-lg: 16px !default;

$chart-inactive: rgba( $text-color, .5 ) !default;
/// The color of the Chart grid lines (major).
/// @group charts
$chart-major-lines: rgba(0, 0, 0, .08) !default;
/// The color of the Chart grid lines (minor).
/// @group charts
$chart-minor-lines: rgba(0, 0, 0, .04) !default;

$chart-area-opacity: .8 !default;

// Icons
$icon-size: $base-icon-size !default;
$icon-size-lg: $base-icon-size-lg !default;
$icon-spacing: $base-icon-spacing !default;

// Disabled mixin variables
$disabled-filter: grayscale(.1) !default;
$disabled-opacity: .6 !default;

// Card
$card-padding-x: 6px !default;
$card-padding-y: 4px !default;
$card-border-width: 1px !default;
$card-border-radius: 2px !default;
$card-box-shadow: false !default;
$card-box-focused-shadow: none !default;

$card-deck-gap: 6px !default;

$card-header-border-width: 1px !default;
$card-actions-border-width: 1px !default;

$card-deck-scroll-button-radius: 0 !default;

// Grid
$grid-alt-bg: $base-component-header-color;


// Calendar
$calendar-bg: $popup-bg;
$calendar-header-bg: $popup-bg;

// Custom variables
$checkbox-size: $base-icon-size;

// Switch
$switch-size: 4em !default;

$switch-on-bg: transparent !default;
$switch-on-text: $base-text-color !default;
$switch-on-handle-bg: $base-accent-color !default;
$switch-on-handle-hovered-bg: $base-accent-color !default;

$switch-off-bg: transparent !default;

// Tooltip
$tooltip-bg: $base-accent-highlight-color !default;
$tooltip-text: $base-accent-contrast-color !default;
