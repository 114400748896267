.lv-envrionment-settings-dialog {
  .lv-dialog-content {
    width: 360px;
    height: 400px;
    
    .lv-envrionment-settings-dialog-list {
      position: absolute;
      top: 0;
      right: 0;
      bottom: $base-padding-y * 2;
      left: 0;
    }
  }
}