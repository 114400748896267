.lv-sensitivity-analysis {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .lv-sensitivity-analysis-header {    
    padding: $base-padding-y;

    .lv-sensitivity-analysis-header-inputs {
      min-width: 220px;

      .lv-settings-widget {
        height: 200px;
      }
    }

    .lv-sensitivity-analysis-header-assumptions {
      max-width: 570px;
      min-width: 220px;
    }

    .lv-sensitivity-analysis-header-separator {
      width: $base-padding-y * 2;
    }

    &.break-grid {
      .lv-sensitivity-analysis-header-inputs {
        .lv-settings-widget {
          height: 100%;
        }
      }
    }
  }

  .lv-sensitivity-analysis-content {
    display: flex;
    flex-direction: column;

    position: relative;

    flex: 1;
    padding: $base-padding-y;
    padding-top: 0;

    overflow: hidden;

    .lv-scenario-template {
      min-height: 22px;
      display: flex;
      flex-direction: row;

      padding-bottom: $base-padding-x;

      .lv-scenario-template-title {
        margin-right: $base-padding-y;
      }

      .lv-scenario-template-separator {
        flex: 1;
        height: 1px;
        border-top: 1px solid $base-border-color;
        margin: auto 0;
      }
    }

    .lv-scenarios {
      flex: 1;
      overflow: hidden;
    }

    .lv-sensitivity-analysis-options {
      position: absolute;
      top: 14px;
      right: $base-padding-y;

      display: flex;
      flex-direction: row;
      
      z-index: 1;

      button {
        margin-right: $base-padding-y * 3;
        width: auto;

        &:last-child {
          margin-right: 0;
        }
      }

      .lv-field {
        margin-right: $base-padding-x;

        .lv-icon {
          font-size: $base-font-size + $base-padding-y;
          line-height: $base-font-size + $base-padding-y;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .lv-sensitivity-analysis-templates-panel {
      .lv-side-panel {
        display: flex;
        flex-direction: column;

        bottom: $base-padding-y + 1;
        top: $base-padding-y * 2 + 1;
      }
    }
  }

  .lv-sensitivity-analysis-templates-panel {
    .lv-sensitivity-analysis-templates-panel-content {
      position: relative;
      flex: 1;

      border: 1px solid $base-component-border-color;

      margin: $base-padding-x 0;
      
      .scenario-template-container {
        position: absolute;
        top: $base-padding-y;
        right: $base-padding-y;
        bottom: $base-padding-y;
        left: $base-padding-y;
  
        overflow-x: hidden;
        overflow-y: auto;
  
        .lv-scenario-template-item {
          padding: $base-padding-y $base-padding-x;
          padding-left: 12px;
          
          cursor: pointer;
        
          display: flex;

          .spacer {
            flex: 1
          }

          &.lv-scenario-template-item--normal-padding {
            padding-left: $base-padding-x;
          }
        
          &.lv-scenario-template-item--selected {
            color: $base-accent-contrast-color;
            background-color: $base-accent-highlight-color;
          }

          &.lv-scenario-template-item--edited {
            color: orange;
          }
          
          &:hover {
            color: $base-accent-contrast-color;
            background-color: $base-accent-highlight-color;
          }
        }

        .lv-scenario-template-group {
          color: $base-accent-color;
          text-transform: uppercase;
          font-size: $base-font-size - 2px;
          letter-spacing: 1px;
        }
      }

      .lv-sensitivity-analysis-template-editor-panel {
        .lv-side-panel {
          top: 0;
          bottom: 0;
        }

        .lv-sensitivity-analysis-template-editor-panel-header {
          font-size: $base-font-size + 2px;
        }
      
        .lv-sensitivity-analysis-template-editor-panel-content {
          flex: 1;
          margin: $base-padding-x 0;
          .k-textbox {
            padding-left: 6px;
            padding-right: 6px;
            border: 1px solid $base-border-color;
        
            &:focus {
              border: 1px solid $base-color-blue
            }
          }
        }
      
        .lv-sensitivity-analysis-template-editor-panel-footer {
          text-align: right;
      
          .k-button {
            width: auto;

            &.k-button-flat {
              &:hover {
                background-color: $base-button-grayed-hover-color!important;
              }
            }
          }
        }
      }
    }
  
    .lv-sensitivity-analysis-templates-panel-footer {
      @include lv_icon_square(16px);
      display: flex;
  
      .lv-icon-square {
        margin-right: $base-padding-x;
    
        &:last-child {
          margin-right: 0;
        }
      }
      
      .lv-sensitivity-analysis-templates-panel-footer-spacer {
        flex: 1;
      }
    }
  }
}

.lv-scenario-dialog {
  padding: $base-padding-x;

  input {
    min-width: 200px;
  }
}