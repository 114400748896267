.lv-workspace-panel {  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;  
  background-color: $base-background-color;

  .lv-workspace-panel-items {
    height: 26px;
    min-height: 26px;
    
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    z-index: 1;

    .lv-icon-square {
      margin-top: 6px;
      margin-left: 6px;
    }
  }

  .lv-workspace-panel-spacer {
    z-index: 0;
    height: 1px;
    margin-top: -1px;
    background-color: $base-border-color;
  }

  .lv-workspace-panel-item-content {
    z-index: 1;
    width: 100%;
    height: 100%;  
    overflow: auto;

    background-color: $base-background-color;
    
    border: 1px solid $base-border-color;
    border-top: 0;
  }
}