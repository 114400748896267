$multi-select-angle: 90deg;

.lv-multi-select {
  width: 100%;
  height: 100%;

  .lv-multi-select-header {
    height: 30px;

    input {
      width: 100%;
    }
  }

  .lv-multi-select-content {
    height: calc(100% - 30px);
    padding: 0 1px;

    .lv-multi-select-left,
    .lv-multi-select-center,
    .lv-multi-select-right {
      float: left;
      height: 100%;
    }

    .lv-multi-select-left,
    .lv-multi-select-right {
      width: 276px;
      position: relative;

      .lv-multi-select-list-title {
        position: absolute;
        top: 0;
        left: 5px;
        right: 0;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        color: $base-section-header-title-color;
        text-transform: uppercase;
      }

      .lv-multi-select-list {
        position: absolute;
        top: 30px;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .lv-multi-select-center {
      width: 44px;

      .lv-multi-select-center-table {
        text-align: center;
        height: 100%;
        width: 44px;

        .lv-multi-select-center-table-cell {
          display: table-cell;
          vertical-align: middle;

          &.lv-multi-select-center-table-cell-arrow-right {
              margin-bottom: 11px;
          }

          &.lv-multi-select-center-table-cell-arrow-left {
            margin-top: 11px;
          }

          i {
            font-size: 16px;
            -webkit-transform: rotate($multi-select-angle);
            -moz-transform: rotate($multi-select-angle);
            -o-transform: rotate($multi-select-angle);
            -ms-transform: rotate($multi-select-angle);
            transform: rotate($multi-select-angle);

            &:hover {
              cursor: pointer;
            }

            &.lv-multi-select-center-table-cell-icon--disabled {
              cursor: not-allowed;
              color: $base-border-color;
            }
          }
        }

        .lv-multi-select-center-table-cell-empty-space {
          flex: 1;
        }
      }
    }
  }

  .k-textbox {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid $base-border-color;

    &:focus {
      border: 1px solid $base-color-blue
    }
  }
}

