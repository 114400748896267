.lv-modal-mask,
.lv-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000000;
}

.lv-modal-mask {
  background-color: $base-color-black;
  @include loading_state;
}

.lv-modal-table {
  display: table;
  width: 100%;
  height: 100%;

  .lv-modal-table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align:center;

    .lv-modal-table-cell-content {
      display: inline-block;

      .lv-modal-container {
        display: flex;
        flex-direction: column;
        text-align: center;

        .lv-modal-title {
          text-align: left;
          user-select: none;
        }

        .lv-modal-content {
          user-select: none;
          flex: 1 1 auto;

          .lv-modal-error-message {
            color: $base-color-red;
          }
          .link {
            color: $base-accent-color;
            &:hover {
              cursor: pointer;
            }
          }
        }

        .lv-modal-footer {
          display: flex;

          button {
            margin: 0 2px;
            width: 100%;
          }
        }

        &.lv-error-modal {
          max-width: 600px;

          .lv-modal-content {
            text-align: left;
          }

          .lv-error-modal-message {
            max-height: 100px;
            overflow: auto;
          }

          .lv-error-modal-stack-trace {
            max-height: 300px;
            overflow: auto;
          }

          .lv-modal-footer {
            justify-content: flex-end;

            button {
              width: 150px;

              &.lv-modal-error-button {
                width: 173px;
              }
            }
          }

        }
      }
    }
  }
}

