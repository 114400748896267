.lv-documents-sliding-panel {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .lv-documents-sliding-panel-documents {
    width: 100%;
    height: 100%;
  }

  .lv-documents-sliding-panel-document {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $base-color-white;

    .lv-documents-sliding-panel-back {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: $base-font-size;
      text-transform: uppercase;
      z-index: 99;

      span {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .k-textbox {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid $base-border-color;

    &:focus {
      border: 1px solid $base-color-blue
    }
  }
}