.lv-terms-summary {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .lv-terms-summary-header {
    div {
      display: inline-block;
      
      padding: $base-padding-y $base-padding-x;
      margin-right: $base-padding-x;

      user-select: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $base-accent-contrast-color;
        cursor: pointer;
      }
    }
  }

  .lv-terms-summary-content {
    flex: 1;
    overflow: auto;
  }

  .lv-terms-summary-content-no-data {
    margin: $base-padding-x;
  }

  img {
    min-width: 1300px;
    max-width: 1300px;
  }
}
