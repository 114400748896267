.lv-datepicker {

  display: flex;
  flex-direction: row;;

  .lv-field {
    margin: 0;

    &.lv-field--checkbox {
      .k-checkbox-label {
        margin-left: -1px;
      }
    }
  }

  .lv-label {
    width: 100%;
    margin-left: 12px;
  }

  &.lv-invalid {
    @include kendo_field_invalid;
  }

  &.lv-dirty {
    @include kendo_field_dirty;
  }
}

.k-datepicker {

  .k-input-button {
    width: 23px;
    height: 23px !important;
    padding-top: 7px;
    border-radius: 0;

    svg {
      width: 13px;
      fill: $base-text-color;
      padding-top: 2px;
    }
  }
}

.k-calendar-header.k-hstack {
  .k-button.k-nav-fast.k-button-md.k-rounded-md.k-button-flat.k-button-flat-base.k-calendar-title {
    font-weight: 700;
    margin-left: 0px;
    padding-left: 24px;
    margin-right: 0px;
    padding-right: 4px;
    &:hover {
      color: inherit;
    }
  }
}

.k-calendar-nav.k-hstack {
  button {
    text-transform:uppercase;
    &.k-button::before {
      background: none;
    }
  }

  .k-calendar-nav-today.k-button.k-button-md.k-button-flat.k-button-flat-primary.k-rounded-md {
    color: #1e8fe1;
    &:hover  {
      color: $base-color-white;
    }
  }
}

.k-animation-container.k-animation-container-shown {
  .k-popup {
    .k-selected {
      border-color: #3b6184;
      color: $base-color-white;
      background-color: #3b6184;
    }
    .k-today {
      &.k-selected {
        border-color: #3b6184;
        color: $base-color-white;
        background-color: #3b6184;
      }
    }
  }
}
