.lv-delta-neutral{
  
  $line-height: 25px;
  $wide-min-width: 900px;
  
  position: relative;
  flex-wrap: wrap;
  padding: 8px 0; 

  .lv-label {
    width: 130px;
    line-height: $line-height;

    &.lv-label-top {
      width: 104px;
    }

    &.lv-label-to-bottom{
      padding-top: $line-height
    }
  }

  .lv-input-field {
    width: 104px;
  }

  .lv-output {
    height: $line-height;

    .lv-negative-label {
      width: 100%;
      display: flex;
      flex: 1;
      height: 100%;

      .lv-negative-label-border {
        width: 104px;
        display: flex;
        flex: 1;
        padding: 0 $base-padding-x;
      }

      .prefix {
        width: 100% !important;
        overflow: hidden;
      }

      .suffix {
        width: 31px;
        padding-left: $base-padding-y;
      }

    }
  }

  .lv-label-suffix {
    width: 31px;
    padding-left: $base-padding-y;
  }

  .delta-neutral-top-content {
    //min-width: 450px;
    margin: 0 $base-padding-x + 2;
    margin-bottom: $base-padding-x + 2;
  }

  .delta-neutral-pnl-section {
    min-width: 280px;
    margin: $base-padding-x + 2;
    margin-top: 0;
  }

  .lv-delta-neutral-display-values-in {
    margin-top: $base-padding-x - 1px;
  }

  .lv-display-table {
    width:600px;
    height: 260px;
  }
}

.popup-deltaNeutral-content {
  max-width: 400px;
  min-width: 320px;
  text-align: justify;
}