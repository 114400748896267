.k-timepicker {
  display: block!important;
  border-radius: 0;
  height: 25px;

  .k-input {
    width: 70%;
    .k-input-inner {
      height: 100%!important;
      color: $base-text-color!important;
    }
  }

  .k-input-button {
    width: 25px;
    height: 25px;
    background-color: $base-input-background-color;
    border-radius: 0;

    &:hover {
      background-color: $base-input-background-color;
    }

    .k-button-icon {
      font-size: 17px;
      padding-top: 4px;
      height: 25px;
    }
  }
}

.k-time-footer {
  display: flex;
  flex-direction: row-reverse;

  .k-button {
    background-color: transparent;
    border: 1px inherit solid;
    border-radius: 0;

    &.k-time-accept {
      color: $base-color-blue;
    }

    &:hover {
      background-color: $base-color-blue;
      color: $base-color-white;
    }
  }
}