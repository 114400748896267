.lv-flex-box {
  display: flex;

  position: relative;

  &.lv-flex-box--column {
    flex-direction: column;
  }
  
  &.lv-flex-box--row {
    flex-direction: row;
  }
}
