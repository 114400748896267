.lv-convertible-bond-terms-wrapper {
  height: 100%;

  .lv-convertible-bond-terms {
    height: 100%;
    margin-top: $base-padding-x - 2;

    .k-tabstrip-items-wrapper {
      margin-right: 92px;
    }

    .k-tabstrip-items {
      flex-wrap: wrap;
      background-color: $base-background-color;
      border: 1px solid $base-border-color;
      height: 21px;
      
      .k-item {
        background-color: $base-background-color;
        &.k-disabled {
          opacity: .6;
          filter: grayscale(.1);
          pointer-events: none;
          box-shadow: none;
        }
      }
    }

    &.lv-convertible-bond-terms-two-rows {
      .k-tabstrip-items {
        height: 41px;
      }
    }

    &.lv-convertible-bond-terms-three-rows {
      .k-tabstrip-items {
        height: 61px;
      }
    }

    &.lv-convertible-bond-terms-four-rows {
      .k-tabstrip-items {
        height: 81px;
      }
    }

    &.lv-convertible-bond-terms-five-rows {
      .k-tabstrip-items {
        height: 101px;
      }
    }

    &.lv-convertible-bond-terms-six-rows {
      .k-tabstrip-items {
        height: 121px;
      }
    }

    &.lv-convertible-bond-terms-seven-rows {
      .k-tabstrip-items {
        height: 141px;
      }
    }

    &.lv-convertible-bond-terms-eight-rows {
      .k-tabstrip-items {
        height: 161px;
      }
    }

    .k-content {
      border: none;
    }

    &.k-tabstrip {
      flex: 1;
      overflow: hidden;
  
      .k-tabstrip-content {
        overflow: auto;
        padding: 0;
        border-left-width: 0px;
        outline: none;
      }
    }
  }

  .k-datepicker {
    border: 1px solid $base-input-border-color;
  }

  .k-textbox {
    background-color: $base-input-background-color!important;
    border: 1px solid $base-input-border-color!important;
    padding: 4px 6px;

    &:focus {
      border: 1px solid $base-input-border-color;
      &:not(:hover) {
        border: 1px solid $base-color-blue!important;
      }
    }

    &:hover {
      border: 1px solid $base-input-border-color;
    }

    &:active {
      border: 1px solid $base-color-blue;
    }
  }

  .lv-fieldset-panel-content {
    .lv-flex-box {
      
      .lv-input-field {
        
        .k-textbox {
          border: 1px solid $base-input-border-color;
          padding: 4px 6px;
          background-color: $base-input-background-color;
          &:focus {
            border: 1px solid $base-input-border-color;
            &:not(:hover) {
              border: 1px solid $base-color-blue!important;
            }
          }
      
        }
      }  
    }  

    .lv-quick-terms-sufix {
      margin-bottom: 3px;
    }
  }
}
