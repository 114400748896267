.lv-puts {
  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-label-puttable {
    width: 70px;
  }

  .lv-checkbox-left {
    margin-left: $base-padding-x + 1;
  }

  .lv-label--title {
    margin-top: $base-padding-x - 1;
  }
  .lv-put-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table {
          thead tr th {
            padding-left: 0px;
          }
        }
        &.lv-advanced-grid-index-column {
          tbody tr {
            td {
              &:first-child {
                border: 0px;
                .lv-advanced-grid-cell {
                  background-color: $base-background-color;
                  border: solid 1px $base-border-color;
                  text-align: center;
                }
              }
            }
          }
        }
        table tbody tr {
          // .k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .terms-puts-total-tranche-pct {
    height: 25px;
    .lv-label {
      padding: 4px 0px;
    }
    .lv-negative-label-border {
      width: 104px;
      height: 25px;
      display: flex;
      justify-content: center;
    }
    .lv-negative-label {
      .prefix {
        width: 100%;
        padding: 4px 6px;
      }
    }
    
  }
}
