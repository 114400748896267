.tooltip-content {
    max-width: 400px;
    min-width: fit-content;
    text-align: justify;
  
    p{
     margin: 0px;
     a {
      pointer-events: none;
      text-decoration: none; 
      color: inherit;
      }
    }
  
    li {
      padding-bottom: calc($base-padding-x / 2);
      a {
        pointer-events: none;
        text-decoration: none; 
        color: inherit;
        }
    }
  }