.general-settings-column {
  min-width: 502px;
  .lv-bond-general-ccy {
    .lv-terms-general-small-input {
      min-width: 80px;
    }
  }
  .lv-terms-general-checkbox {
    margin-left: 7px;
  }

  .lv-terms-section-settings-system-defaults {
    padding: 5px 8px 20px 8px;
  }

  .lv-terms-section-settings-my-defaults {
    padding: 10px 8px 20px 8px;
  }
}