.lv-grid-loader {
  width: 100%;
  height: 100%;

  display: block;
  position: relative;

  .lv-grid-loader-content {
    width: 100%;
    height: 100%;

    &.lv-grid-loader-content--loading {
      @include loading_state;
    }
    
    .k-animation-container {
      z-index: 10;
    }
  }
}