.lv-dividends {
  width: 100%;
  height: 100%;

  
  .lv-dividends-source-controls {
    margin-top: 15px;
    width: 100%;

    button {
      width: 147px;
      margin-right: $base-padding-y;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .lv-dividends-content {
    flex: 1;
    overflow: auto;

    .lv-equity-dividends-dividend-ccy {
      margin-bottom: 5px;
      .lv-label {
        &.lv-label-field {
          padding-top: 4px;
          &.lv-underlying-source-schedule {
            padding-top: 9px;
          }
        }
      }
      .lv-input-field {
        margin-left: 72px;
        margin-top: 4px;
        &.lv-underlying-source-schedule {
          padding-top: 5px;
        }
      }
    }

    .lv-advanced-grid-stripped {
      &.lv-advanced-grid {
        .k-grid {
          table {
            thead {
              tr {
                th {
                  padding-left: 6px;
                }
              }
            }
          }
        }
      }
    }

    .lv-equity-dividends-parameters {
      .lv-dividends-dividend-parameters {
        margin-top: 5px;
        margin-left: 0px;
      }
      .lv-equity-dividend-type {
        .lv-input-field {
          margin-top: 5px;
          margin-left: 70px;
        }
      }

      .lv-equity-dividend-value {
        .lv-input-field {
          margin-top: 5px;
          margin-left: 79px;
          .lv-suffix-no-value {
            padding-left: 23px;
          }
        }
      }

      .lv-equity-dividend-frequency {
        .lv-input-field {
          margin-top: 4px;
          margin-left: 27px;
        }
      }

      .lv-equity-dividend-value-growth {
        .lv-input-field {
          margin-top: 4px;
          margin-left: 90px;
          .lv-suffix-no-value {
            padding-left: 13px;
          }
        }
      }

      .lv-equity-dividend-date {
        .lv-input-field {
          margin-top: 4px;
          margin-left: 33px;
        }
      }
  
      .lv-equity-dividend-fixed-start-time {
        .lv-input-field {
          margin-top: 4px;
          margin-left: 90px;
          .lv-suffix-no-value {
            padding-left: 13px;
          }
        }
      }
    }
  }

}

