.lv-label {
  color: $base-text-color-dimmed;
  text-align: left;
  width: 160px;

  @include k_checkbox_centered;

  &.lv-label-field {
    @include field_height;
  }

  &.lv-label--right {
    text-align: right;
  }

  &.lv-label--title {
    color: $base-section-header-title-color;
    font-size: $base-font-size;
    font-weight: 600;
    text-transform: uppercase;
    width: auto;
  }
  &.lv-label--conversion-ratio-history {
    padding-top: 4px;
    padding-left: 2px;
  }
}

.lv-output {
  text-align: left;
  color: $base-output-color;

  &.lv-output-field {
    @include field_height;
  }

  &.lv-output--right {
    text-align: right;
  }

  &.lv-output-bold {
    font-weight: 600;
  }
}

.lv-sufix-label {
  color: $base-text-color-dimmed;
  text-align: left;
  padding-left: 4px;
  width: 31px;
}
