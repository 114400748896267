.lv-convertible-bond-terms-identifiers {
    min-width: 400px;
    padding: $base-padding-x + 3 $base-padding-x + 2;

    .lv-advanced-grid .k-grid table thead tr th {
        font-weight: 600;
        color: #d1d1d1;
    }
    .lvs-convertible-identifiers.lv-advanced-grid .k-grid table tbody tr td.lv-input-field--output {
      border: 0;
      &:first-child .lv-advanced-grid-cell {
       color: $base-text-color-dimmed;
      }
    }

    .lv-convertible-bond-terms-identifiers-read-only {

      .lv-convertible-bond-terms-identifiers-read-only-header {
        
        .lv-convertible-bond-terms-identifiers-read-only-label {
          text-transform: uppercase;
        }
      }

      .lv-convertible-bond-terms-identifiers-read-only-label {
        width: 140px;
        height: 25px;
        &.lv-bond-terms-identifiers-read-only-label {
          height: 22px;
        }

        &.lv-convertible-bond-terms-identifiers-read-only-description {
          color: $base-text-color-dimmed;
        }
      }
    }
  
    .lv-identifiers-schedule {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                &:last-child {
                  &.k-focus, &:not(:focus) {
                  .k-textbox {
                      height: 25px;
                      border-top: 0px!important;
                      border-bottom: 0px!important;
                      border-right: 0px!important;
                      border-left:  0px solid $base-background-color!important;
                    }
                  }
                }
                .k-dropdownlist {
                  &.k-focus, &:not(:focus) {
                    border: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                    &:last-child {
                      &.k-focus, &:not(:focus) {
                      .k-textbox {
                          height: 25px;
                          border-top: 0px!important;
                          border-bottom: 0px!important;
                          border-right: 0px!important;
                          border-left:  0px solid $base-background-color!important;
                        }
                      }
                    }
                    .k-dropdownlist {
                      min-width: auto;
                      &.k-focus, &:not(:focus) {
                        border: 0px;
                      }
                    }
                  }
                }
              }
          }
          table thead tr th {
            padding-left: 0px;
          }
        }
      }
    }
}