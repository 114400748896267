.k-multiselect {
  &.k-multiselect--inline {
    width: auto;
    min-width: 104px;
    
    .k-button {
      width: auto;
    }

    &.k-multiselect--not-empty {
      kendo-taglist {
        ul {
          li {
            &:last-child {
              margin-right: 34px;
            }
          }
        }
      }
  
      .k-searchbar {
        display: inline-flex;
        float: none;
        margin-left: -32px;
      }
    }
  }
}