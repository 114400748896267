.lv-document {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $base-background-color;

  outline: none;
  cursor: default;

  .lv-document-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 28px;
    display: flex;

    padding: 5px 10px;

    .lv-document-close {
      height: 18px;
      width: 18px;
      line-height: 16px;
      border-radius: 2px;
      background-color: $base-icon-color;
      
      text-align: center;

      i {
        color: $base-background-color;
      }
    }

    .lv-document-control {
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 20px;
      
      &.is-downloading {
        @include loading_state;
        opacity: 1;
      }

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .lv-document-control-spacer {
      display: inline-block;
      flex: 1 1 auto;
    }
  }

  .lv-document-bookmarks {
    position: absolute;
    top: 28px;
    bottom: 0px;
    left: 0px;
    width: 160px;

    padding: 0px 10px 10px 10px;

    i {
      color: $base-icon-color;

      &:hover {
        cursor: pointer;
      }
    }

    .lv-document-search {
      position: relative;
      margin-bottom: 6px;

      input {
        padding-right: 22px;
      }

      i {
        position: absolute;
        top: 8px;
        right: 6px;
        font-size: $base-font-size;
        line-height: $base-font-size;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .lv-document-search-navigator {
      height: 16px;
      line-height: 12px;
      text-align: right;

      i {
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }

      .lv-document-search-result {
        display: inline-block;
        vertical-align: middle;
        font-size: $base-font-size - 1px;
        font-weight: 600;
        margin-right: 6px;
      }
    }

    .lv-document-bookmark,
    .lv-document-bookmark-child {
      user-select: none;
      &:hover {
        cursor: pointer;
        color: $base-accent-color;
      }
    }

    .lv-document-bookmark {
      padding: 4px 0px;
    }

    .lv-document-bookmark-child {
      padding: 4px 0px 4px 10px;
    }
  }

  .lv-document-pdf-viewer {
    position: initial;

    .ng2-pdf-viewer-container {
      position: absolute;
      top: 28px;
      right: 0;
      bottom: 0;
      left: 160px;
      height: auto;
      width:auto;

      outline: none;
      cursor: default;

      .textLayer,
      .canvasWrapper {
        user-select: none;
        -moz-user-select: -moz-none;
        cursor: default;

        ::selection {
          background: transparent;
          background-color: $base-accent-highlight-color;
          color: transparent;
        }
      }
    }
  }

  .lv-document-can-select {
    .ng2-pdf-viewer-container {
      .textLayer,
      .canvasWrapper {
        user-select: auto;
        -moz-user-select: -moz-auto;
        cursor: auto;
      } 
    }
  }
  
  .lv-document-download-anchor {
    display: none;
  }
}
