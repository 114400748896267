.lv-edit-environment-settings-dialog {
  padding-left: 4px;
  padding-top: 4px;

  .lv-edit-environment-settings-dialog-content {
    height: 133px;

    .k-textbox {
      padding-left: 6px;
      padding-right: 6px;
      border: 1px solid $base-border-color;
      background-color: #434e5b;
      color: $base-color-white;
  
      &:focus {
        border: 1px solid $base-color-blue;
        color: $base-color-white;
      }
    }
  }

  .lv-label-field-description {
    width: 125px;
  }

  .k-checkbox {
    margin-top: 7px;
  }
  
  .lv-input-field--full {
    width: 400px;
  }

  .lv-environment-settings-update-control-panel {
    height: 46px;
    padding: 0;
    border-top: 1px solid $base-border-color;
    justify-content: flex-end;
    padding-right: 14px;
    padding-top: 6px;

    button {
      width: 90px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 15px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
