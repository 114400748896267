.k-list {
  .k-item {
    &:focus,
    &.k-item.k-state-focused {
      color: $base-accent-contrast-color;
      background-color: $base-accent-highlight-color;
    }
  }
}

.k-list-container {
  .k-list {
    height: 100%;
    display: flex;
  
    .k-list-content {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      height: 100%;
  
      .k-list-ul {
        margin: 0;
        padding: 0;
    
        .k-list-item {
          display: block;
          height: 25px;
          padding-top: 6px;
          padding-left: 12px;
    
          &.k-selected, &:hover, &.k-focus  {
            background-color: $base-accent-highlight-color;
            color: white;
          }
        }
      }

      .k-disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }
  
  .k-no-data {
    height: 150px;
    margin: auto;
    padding-top: 60px;
    color: $base-text-color-inactive;
  }
  :first-child {
    &.k-selected {
      width: 100%;
    }
  }

  :not(:first-child) {
    overflow: auto;
  }

  &.k-popup {
    div {
      overflow: hidden;
    }
  }
}