.lv-loader-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .lv-loader-panel-mask {
    width: 100%;
    height: 100%;

    background-color: $base-background-color;
    @include loading_state;
  }
}