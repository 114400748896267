.call-settings-column {
  &:nth-child(1) {
    margin-right: 35px;
  }
  .lv-label {
    width: 159px;
  }
  input::placeholder {
    text-align: left;
  }
}

.call-settings-second-column {
  margin-left: 100px;
}
