.lv-convertible-bond-terms-terms-general {
  min-width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-bottom-sections {
    margin-top: 5px;
    &.lv-bond-general-bottom-section {
      margin-top: 13px;
    }
  }

  .lv-notes-section {
    margin-right: $base-padding-x - 1;

    .lv-notes-text-area {
      .lv-input-field .k-textarea, .lv-input-field .k-textbox {
        height: 177px;
        width: 518px;
        resize: none;
      }
    }
  }

  .lv-notes-bond-section {
    margin-right: $base-padding-x - 1;
    margin-left: 3px;

    .lv-notes-text-area {
      .lv-input-field .k-textarea, .lv-input-field .k-textbox {
        height: 177px;
        width: 518px;
        resize: none;
      }
    }
  }

  .lv-terms-general-small-label {
    .lv-label {
      width: 104px;
    }
  }

  .lv-fx-section {
     width: 246px;
   }

  .lv-fieldset-panel {
      .lv-fieldset-panel-content {
      padding: 9px 6px 6px 7px;
    }
  }

  .lv-general-sinking-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
