.lv-quick-terms-entry {

  .lv-environment-settings {
    padding-top: $base-padding-x;
    padding-bottom: $base-padding-x;
    justify-content: flex-end;
  
    @include lv_icon_square(24px, 2px);
  
    .lv-icon-square {
      margin-right: $base-padding-x;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
}