.lv-instrument-monitor-search {
  .lv-instrument-monitor-search-autocomplete-container {
    position: relative;

    ::placeholder {
      color: $base-text-color;
      opacity: 50%;
    }

    .k-clear-value {
      height: 12px;
      width: 12px;

      .k-icon-wrapper-host {
        height: 100%;
        width: 100%;
      }
    }

    >.k-icon, .k-svg-icon {
      position: absolute;
      font-size: 13px;
      line-height: 14px;
      color: $base-text-color;
      fill: $base-text-color;
      height: 100%;
      width: 100%;

      svg {
        width: 12px;
        padding-top: 1px;
        stroke-width: 15px;
        stroke: #c7c7c9;
      }
    }

    .k-i-search {
      left: 6px;
      z-index: 1;
      font-size: 14px;
      top: 7px;
      width: 15px;
      height: 15px;
    }

    input {
      color: $base-color-orange;
      background-color: inherit;
    }

    .lv-instrument-monitor-search-autocomplete {
      position: relative;
      width: 330px;
      padding-left: 18px;

      &.k-focus {
        border: 1px solid $base-color-blue;
      }

      .k-clear-value {
        display: inline-flex;
        top: 2px
      }
    }

    .lv-instrument-monitor-hidden {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      visibility: hidden;
      white-space: nowrap;
      font-size: $base-font-size;
      text-transform: none;
    }

    &.instrument-selected {
      .lv-instrument-monitor-search-autocomplete {
        border: 1px solid transparent;
        background: transparent;
        padding-left: 0;
      }

      >.k-i-search {
        display: none;
      }
    }
  }
}

.lv-instrument-monitor-search-autocomplete-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  table-layout: fixed;
  text-align: left;

  .name,
  .isin,
  .leversys-id {
    display: table-cell;
  }

  .name {
    width: 346px;
  }

  .isin {
    width: 126px;
  }

  .leversys-id {
    flex: 1;
  }
}

.lv-instrument-monitor-search-autocomplete-header {
  background-color: $base-component-header-color;
  color: $base-text-color-highlight;
  padding-left: $base-padding-x;
  padding-top: $base-padding-y;
  padding-bottom: $base-padding-y;
  padding-right: $base-padding-x + 17px;
}
