.lv-yield-curve-term-structure {

  &.lv-advanced-grid {
    .k-grid {
      table {
        // Move text in header cells to left, so it is in line with Zeplin.
        thead {
          tr {
            th {
              padding-left: 0px;
            }
          }
        }

        tbody tr {
          // .k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            &:not(:last-child) {
              .lv-input-field {
                .k-dropdownlist {
                  min-width: auto;
                  &.k-focus, &:not(:focus) {
                    border: 0px;
                  }
                }
              }
            }
            &:last-child {
              .lv-input-field {
                &:not(:focus), &:focus {
                  border-top: 0px;
                  &:last-child {
                    border-bottom: 0px;
                    border-left: 0px;
                    .lv-advanced-grid-cell {
                      border-top: 1px solid $base-border-color;
                      border-bottom: 1px solid $base-border-color;
                      border-left: 1px solid $base-border-color;
                      height: 24px;
                      width: auto;
                    }
                  }
                }
                .k-dropdownlist {
                  min-width: auto;
                  &.k-focus, &:not(:focus) {
                    border-top: 1px solid $base-background-color;
                    border-left: 0px;
                    border-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}