.instrument-tab {
  width: 100%;
  height: 100%;

  .k-tabstrip-content {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 1px solid $base-background-color;
    border-top: 0;
  }

  .k-tabstrip-items-wrapper {

    .k-tabstrip-items {
      flex-wrap: wrap;

      .k-item {
        z-index: 1;
        margin-top: -1px;
      }
    }


  &.instrument-two-rows {
    .k-tabstrip-items {
      height: 41px;
    }
  }

  &.instrument-three-rows {
    .k-tabstrip-items {
      height: 61px;
    }
  }

  &.instrument-four-rows {
    .k-tabstrip-items {
      height: 81px;
    }
  }

  &.instrument-five-rows {
    .k-tabstrip-items {
      height: 101px;
    }
  }

  &.instrument-six-rows {
    .k-tabstrip-items {
      height: 121px;
    }
  }

  &.instrument-six-rows {
    .k-tabstrip-items {
      height: 141px;
    }
  }
}
}

.instrument-options {
  position: absolute;
  top: 0px;
  right: 6px;
  line-height: 21px;

  .k-icon.k-i-gear {
    font-size: $base-font-size;
    color: $base-icon-color;

    &:hover {
      cursor: pointer;
      color: $base-text-color;
    }
  }
}

.instrument-options-panel {
  min-height: 125px;
  .lv-side-panel {
    padding-top: 7px;
  }

  .instrument-options-panel-item, .lv-side-panel-link-item {
    
    margin-left: 1px;

    &:first-child {
      i.k-icon {
        padding-top: 5px;
      }
    }

    &:last-child {
      padding-top: 5px;
    }

    .k-i-cancel {
      &.k-button-icon {
        padding-top: 6px;
      }
    }



    .instrument-options-panel-item-title {
        @include field_height();

        display: inline-block;

        &.instrument-options-panel-item--only-widget {
          margin-left: 8px;
        }
      }

      .k-button-group {
        float: right;

        .k-button {
          height: 24px;
          width: 24px;

          padding: 0;
          margin: 0;
          margin-right: $base-padding-x;
          border: 0;
          border-radius: 0;

          background-color: transparent !important;
          color: $base-text-color-inactive !important;

          .k-icon,
          .lv-font-icon {
            font-size: 20px;
          }

          .lv-font-icon {
            line-height: 24px;
          }

          &.k-active, &.k-selected {
            background-color: $base-color-blue !important;
            color: $base-background-color !important;
          }
        }
      }
    }

      .instrument-options-panel-edit-custom-fotter {
        padding: 0;
        height: 45px;
        margin-top:  $base-padding-x+$base-padding-x;
        border-top: $base-border-color 1px solid;
        width: 100%;
        align-items: center;

        button {
          width: 90px;
          margin-left:  $base-padding-x+$base-padding-x;

          &:first-child {
            margin-left: 35px;
          }

          .k-button-text {
            color: $base-color-white;
          }
        }
      }

}

.instrument-header {
  .instrument-header-options {
    @-webkit-keyframes fade-in-out {
      0%, 100% { opacity: 1 }
      50% { opacity: 0; }
    }

    @keyframes fade-in-out {
      0%, 100% { opacity: 1 }
      50% { opacity: 0; }
    }

    .reload-info {
      margin-right: $base-padding-x;

      font-size: $base-font-size;
      color: $base-color-red;

      -webkit-animation: fade-in-out 2.0s infinite ease-in-out;
      animation: fade-in-out 2.0s infinite ease-in-out;
    }

    @include lv_icon_square(24px, 2px);

    .lv-icon-square {
      margin-top: auto;
      margin-bottom: auto;

      margin-right: $base-padding-x;

      &:last-child {
        margin-right: $base-padding-x * 2;
      }
    }
  }
}
.instrument-access-icon {
  position: absolute;
  right: 22px;
  top: 4px;
  i {
    font-size: 14px;
  }
}

.lv-instrument-no-data {
  margin: $base-padding-x;
}