.lv-ratchet-schedule {
  .lv-label--title {
    margin-top: 5px;
  }
  .lv-takeover-protection-ratchet-schedule {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
