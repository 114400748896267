.lv-convertible-bond-terms-coupon {
  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-coupon-first-col {
    min-width: 304px;
  }

  .lv-coupon-second-col {
    .lv-label {
      width: 170px;
    }

    .lv-label-before-checkbox {
      width: 152px;
    }
  }

  .lv-fieldset-panel {
    &:last-child {
      padding-bottom: 0;
    }
  }

  .lv-coupon-floating-container {
    &:last-child {
      margin-top: 4px;
    }
  }

  .lv-fieldset-panel-content {
    padding: 11px 8px 14px 8px;
  }

  .lv-coupon-fieldset {
    margin-top: $base-padding-x + 5;

    &:first-child {
      margin-top: 13px;
    }

    &.lv-bond-coupon-fieldset {
      margin-top: $base-padding-x + 7;

      &:first-child {
        margin-top: 11px;
      }
    }
    .lv-label {
      width: 152px;
    }
    .lv-custom-coupon-schedule {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-numerictextbox, .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-label-before-checkbox {
    width: 142px;
  }

  .lv-coupon-checkbox {
    margin-left: 7px;
  }

  .lv-coupon-date {
    .lv-field.lv-field--checkbox {
      margin-right: 0px;
    }
  }

  .lv-display-projected-button {
    width: 170px;
    margin-top: $base-padding-x + 4;
  }
}
