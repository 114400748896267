.lv-make-whole {
  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-label-ratchet-matrix {
    margin-top: 5px;
    .lv-label--title  {
      z-index: 1;
    }
  }

  .lv-vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: $base-text-color-dimmed;
    font-size: $base-font-size;
    font-weight: 600;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 15px;
    padding-bottom: 10px;
    margin-right: 1px;
    margin-left: -1px;
  }

  .lv-ratchet-matrix {
    margin-top: -25px;
  }

  .lv-make-whole-checkbox {
    margin-left: 7px;
  }

  .lv-field.lv-field--checkbox {
    margin-right: 0px;
  }

  .lv-label-before-checkbox {
    width: 142px;
  }

  .lv-conversion-make-whole-label-ratchet-matrix {
    .lv-label--title  {
      z-index: 1;
    }
  }
  .lv-ratchet-matrix-grid {
    &.lv-advanced-grid {
      .k-grid {
        table tbody tr {
          // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
          &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
            &:last-child {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
