.lv-borrow {
  height: 100%;
  width: 100%;
  min-width: 315px;

  .lv-borrow-content {
    flex: 1;
    overflow: auto;
    .lv-equity-borrow-flat {
      .lv-borrow-flat {
        padding-left: 0px;
        padding-top: 0px;
        .lv-input-field {
          padding-left: 82px;
          padding-top: 4px;
          .lv-suffix-no-value {
            padding-left: 20px;
          }
        }
      }
    }

    .lv-borrow-rebate {
      padding-left: 0px;
      padding-top: 0px;
      .lv-input-field {
        padding-left: 82px;
        padding-top: 4px;
        .lv-suffix-no-value {
          padding-left: 20px;
        }
      }
    }

    .lv-equity-borrow-term-structure {
      .lv-borrow-grid-title {
        .lv-label {
          padding-top: 3px;
          padding-left: 0px;
        }
      }

      .lv-equity-borrow-term-structure-table {
        padding-left: 0px;
        margin-top: 0px;
        .k-grid {
          table {
            thead {
              tr {
                th {
                  padding-left: 6px;
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-borrow-grid-title {
    padding-top: 6px;
  }

  .lv-borrow-term-structure {
    width: 350px;
    .lv-advanced-grid {
      .k-grid {
        table {
          // Move text in header cells to left, so it is in line with Zeplin.
          thead {
            tr {
              th {
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
  }

  .lv-input-field {
    &.lv-underlying-borrow-non-editable-field {
      padding-left: 85px;
      padding-top: 5px;
    }
  }
}
