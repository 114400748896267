@mixin icon_square_disabled {
  background-color: darken($base-icon-color, 20%);

  .k-icon {
    color: $base-background-color;
  }

  &:hover {
    cursor: not-allowed;
    background-color: darken($base-icon-color, 20%);
  }
}

.lv-icon-square {
  display: inline-block;
  height: $base-font-size;
  width: $base-font-size;
  vertical-align: top;
  background-color: $base-icon-color;
  border-radius: $base-border-radius;

  .k-icon {
    display: block;
    margin: auto;
    line-height: $base-font-size;
    font-size: $base-font-size;
    color: $base-background-color;
  }

  &:hover {
    cursor: pointer;
    background-color: $base-text-color;
  }

  &.lv-icon-square--round {
    border-radius: 50%;
  }

  &.lv-icon-square--disabled {
    @include icon_square_disabled;
  }

  &.lv-icon-square--inverted {
    background-color: transparent;

    .k-icon {
      color: $base-icon-color;
      
      &:hover {
        color: $base-text-color;
      }
    }

    &.lv-icon-square--disabled {
      .k-icon {
        color: darken($base-icon-color, 20%);

        &:hover {
          color: darken($base-icon-color, 20%);
        }
      }

      &:hover {
        cursor: not-allowed;
        background-color: transparent;
      }
    }
  }
}

// style applied for danger icon
.lv-icon-square--danger {
  background-color: darken($base-error-color, 10%);
  
  .k-icon {
    color: $base-color-white;
  }

  &:hover {
    cursor: pointer;
    background-color: $base-error-color;
  }

  &.lv-icon-square--disabled {
    @include icon_square_disabled;
  }
}