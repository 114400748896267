.lv-dialog {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding-top: 1px;
  padding-left: $base-padding-y;
  padding-right: $base-padding-y;

  background-color: $base-background-color;

  outline: none;

  .lv-dialog-content {
    outline: 0;
    overflow: auto;
    position: relative;
    flex: 1 1 auto;
    padding-bottom: $base-padding-y * 2;
  }

  .lv-dialog-actions {
    display: flex;
    height: 42px;
    border-top: 1px solid $base-component-border-color;

    margin-left: -($base-padding-y + 6px);
    margin-right: -($base-padding-y + 6px);
    margin-bottom: -$base-padding-y;

    padding-left: $base-padding-y + 6px;
    padding-right: $base-padding-y + 6px;

    justify-content: flex-end;

    button {
      width: 90px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}