.lv-warnings-section-settings {
    .lv-input-field {
        margin-right: 0;

        .k-radio-label {
            margin-left: 13px;
            width: 70px;
        }

        &:nth-child(1) {
            width: 160px;
            .k-radio-label {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}