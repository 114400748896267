.lv-estimates-settings-section {
  .lv-input-field {
    height: 25px;
    padding-top: 6px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .lv-estimates-settings-section-system-defaults {
    width: 225px;
  }

  .lv-estimates-settings-section-price-format {
    .lv-input-field {
      width: 125px;

      &:last-child {
        width: 145px;
      }
    }
  }

  .lv-estimates-settings-section-label-wrapper {
    padding-top: 6px;
    height: 25px;
  }
}
