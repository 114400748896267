.lv-equity-volatility-settings {
  .lv-section-settings-base {
    .lv-section-settings-environment-settings {
      .k-tabstrip-items-wrapper {
        .k-tabstrip-items {
          :nth-child(1) {
            min-width: 30px;
          }
          :nth-child(2) {
            width: 57px;
          }
        }
      }
    }
  }
  .lv-volatility-equity-settings-container {
    height: 100%;
  }
}