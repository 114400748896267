.lv-terms-cds-settings {
    height: 100%;

    .lv-cds-terms-settings {
      height: inherit;
      flex: 1;
      overflow: hidden;

      .lv-terms-section-settings-my-defaults {
        lv-cds-terms-section-settings {
            .lv-label.lv-label-field {
                margin-right: 10px;
            }
        }    
      }

      .lv-terms-section-settings-system-defaults {
        .lv-label.lv-label-field {
            margin-right: 10px;
        }
        .space-bottom{
            margin-bottom: 0px;
        }
      }

      .lv-terms-section-settings-system-defaults {
        padding-top: 8px;
        padding-bottom: 30px;
      }
    }
  }