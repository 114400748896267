// Width variables (appears count calculates by raw css)
$width0: 100%; // Appears 3 times
$width1: 20px; // Appears 2 times
$width2: 100px; // Appears 1 time
$width3: 14px; // Appears 1 time
$width4: 18px; // Appears 1 time
$width5: 15px; // Appears 1 time
$width6: 2px; // Appears 1 time

// Height variables (appears count calculates by raw css)
$height0: 100%; // Appears 4 times
$height1: 20px; // Appears 2 times
$height2: 14px; // Appears 2 times
$height3: 10px; // Appears 1 time
$height4: 19px; // Appears 1 time
$height5: 18px; // Appears 1 time
$height6: 15px; // Appears 1 time

$tabHeight: 18px;

.lm_root {
  position: relative;
}

.lm_row > .lm_item {
  float: left;
}

// Single Pane content (area in which final dragged content is contained)
.lm_content {
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-top-width: 0;
}

// ".lm_dragging" is applied to BODY tag during Drag and is also directly applied to the root of the object being dragged
.lm_dragging,
.lm_dragging * {
  cursor: move !important;
  user-select: none;
}

// If a specific Pane is maximized
.lm_maximised {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
}

.lm_maximise_placeholder {
  display: none;
}

// Separator line (handle to change pane size)
.lm_splitter {
  position: relative;
  z-index: 2;

  &:hover, // When hovered by mouse...
  &.lm_dragging {
    background: black;
  }

  &.lm_vertical {
    .lm_drag_handle {
      width: $width0;
      position: absolute;
      cursor: ns-resize;
    }
  }

  &.lm_horizontal {
    float: left;
    height: $height0;

    .lm_drag_handle {
      height: $height0;
      position: absolute;
      cursor: ew-resize;
    }
  }
}

// Pane Header (container of Tabs for each pane)
.lm_header {
  height: 20px !important;
  padding: 2px 0px 0px 0px;

  overflow: visible;
  position: relative;
  z-index: 1;
  user-select: none;

  &.lm_selectable {
    cursor: pointer;
  }

  // [class^=lm_] {
  //   box-sizing: content-box !important;
  // }

  // Pane controls (popout, maximize, minimize, close)
  .lm_controls {
    position: absolute;
    right: 6px;
    top: 5px;

    > li {
      cursor: pointer;
      float: left;
      width: 10px;
      height: 10px;
      // text-align: center;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .lm_tabs {
    position: absolute;
    z-index: 1;
  }

  // Single Tab container. A single Tab is set for each pane, a group of Tabs are contained in ".lm_header"
  .lm_tab {
    height: $tabHeight;
    display: flex;
    flex-direction: row;

    cursor: pointer;
    float: left;
    position: relative;
    padding: 0px 6px;
    margin-right: 4px;
    // border-top: 1px solid transparent;

    // check what this does
    i {
      width: 2px;
      height: $tabHeight;
      position: absolute;

      &.lm_left {
        top: 0;
        left: -2px;
      }

      &.lm_right {
        top: 0;
        right: -2px;
      }
    }

    .lm_title {
      height: 18px;
      max-width: 192px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 6px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
    }

    // Close Tab Icon
    .lm_close_tab {
      display: inline-block;
      visibility: hidden;
      width: 8px;
      height: 8px;
      margin: auto 0;
    }

    &.lm_active {
      .lm_close_tab {
        visibility: visible;
      }
    }
  }
}

// Change stack style to absolute positioning for docking transition ability
.lm_stack {
  position: relative;

  > .lm_items {
      overflow: hidden;
  }

  &.lm_left {
    > .lm_items {
      position: absolute;
      left: 20px;
      top: 0;
    }
  }
  &.lm_right {
    > .lm_items {
      position: absolute;
      right: 20px;
      top: 0;
    }

    > .lm_header {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &.lm_bottom {
    > .lm_items {
      position: absolute;
      bottom: 20px;
    }

    > .lm_header {
      position: absolute;
      bottom: 0;
    }
  }
}

.lm_stack.lm_docked {
  overflow: visible;
  z-index: 3;

  > .lm_header {
    z-index: 5;
  }

  > .lm_items {
    z-index: 3;
    transition: height .4s ease-in;
    border: none;
    border-image-slice: 1 !important;
    margin: -2px 0;
  }

  &.lm_left,
  &.lm_right {
    > .lm_items {
      transition: width .4s ease-in, height .001s linear;
    }
  }
}

// Headers positions
.lm_left,
.lm_right {
  &.lm_stack {
    .lm_header {
      height: 100%;
    }
  }

  &.lm_dragProxy {
    .lm_header,
    .lm_items {
      float: left;
    }
  }

  &.lm_dragProxy,
  &.lm_stack {
    .lm_header {
      width: 20px;
      vertical-align: top;

      .lm_tabs {
        transform-origin: left top;
        top: 0;
        width: 1000px; /*hack*/
      }

      .lm_controls {
        bottom: 0;
      }
    }
  }
}

.lm_dragProxy.lm_left,
.lm_stack.lm_left {
  .lm_header {
    .lm_tabs {
      transform: rotate(-90deg) scaleX(-1);
      left: 0;
      .lm_tab {
        transform: scaleX(-1);
        margin-top: 1px;
      }
    }

    .lm_tabdropdown_list {
      top:initial;
      right:initial;
      left:20px;
    }
  }
}

.lm_dragProxy.lm_right .lm_content {
  float: left;
}

.lm_dragProxy.lm_right,
.lm_stack.lm_right {
  .lm_header {
    .lm_tabs {
      transform: rotate(90deg) scaleX(1);
      left: 100%;
      margin-left: 0;
    }

    .lm_controls {
      left: 3px;
    }

    .lm_tabdropdown_list {
      top:initial;
      right:20px;
    }
  }
}

.lm_dragProxy.lm_bottom,
.lm_stack.lm_bottom {
  .lm_header {
    width: 100%;
    .lm_tab {
      margin-top:0;
      border-top: none;
    }

    .lm_controls {
      top: 3px;
    }

    .lm_tabdropdown_list {
      top:initial;
      bottom:20px;
    }
  }
}

.lm_drop_tab_placeholder {
  float: left;
  width: $width2;
  height: $height3;
  visibility: hidden;
}

// Dropdown arrow for additional tabs when too many to be displayed
.lm_header {
  .lm_controls .lm_tabdropdown:before {
    content: '';
    width: 0;
    height: 0;
    vertical-align: middle;
    display: inline-block;
    border-top: 5px dashed;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    color: white; // Overriden in specific Themes
  }

  .lm_tabdropdown_list {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 5;
    overflow: hidden;

    .lm_tab {
      clear: both;
      padding-right: 10px;
      margin: 0;

      .lm_title {
        width: 100px;
      }
    }

    .lm_close_tab {
      display: none !important;
    }
  }
}

/***********************************
* Drag Proxy
***********************************/

// Single Pane content during Drag (style of moving window following mouse)
.lm_dragProxy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;

  .lm_header {
    background: transparent;
  }

  .lm_content {
    border-top: none;
    overflow: hidden;
  }
}

// Placeholder Container of target position
.lm_dropTargetIndicator {
  display: none;
  position: absolute;
  z-index: 20;
  transition: all 200ms ease;

  // Inner Placeholder
  .lm_inner {
    width: $width0;
    height: $height0;
    position: relative;
    top: 0;
    left: 0;
  }
}

.lm_transition_indicator {
  display: none;
  width: $width1;
  height: $height1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}

// If a specific Pane is Popped Out, so move it to a different Browser Window, Icon to restore original position is:
.lm_popin {
  width: $width1;
  height: $height1;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9999;

  > * {
    width: $width0;
    height: $height0;
    position: absolute;
    top: 0;
    left: 0;
  }

  > .lm_bg {
    z-index: 10;
  }

  > .lm_icon {
    z-index: 20;
  }
}
