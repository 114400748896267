.analytics {
  width: 100%;
  height: 100%;

  .analytics-content {
    width: 100%;
    height: 100%;
    position: relative;

    .lv-gridster {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background-color: $base-background-color;

      .lv-gridster-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
  
        overflow: hidden;

        background-color: $base-background-color;
        border: 1px solid transparent;
  
        .lv-settings-widget {
          .lv-settings-widget-content {
            flex: 1;
          }
        }

        &.lv-gridster-item--editable {
          border: 1px solid $base-color-orange;
        }
      }

      &.lv-gridster--hidden {
        opacity: 0;
      }
    }
  }
}