.lv-bond-pricing-inner-content {
  .lv-bond-valuation-inputs {

    padding-bottom: 8px;
    padding-left: 8px;

    .lv-bond-valuation-inputs-row {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}