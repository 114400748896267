.lv-convertible-bond-terms-conversion {
  width: 800px;
  padding: $base-padding-x + 4 $base-padding-x + 2;

  .lv-conversion-schedule {
    margin-top: $base-padding-x - 1;
    margin-bottom: $base-padding-x + 4;
    .lv-conversion-schedule-grid {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-numerictextbox, .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-numerictextbox, .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-exercise-schedule {
    margin-left: 14px;
    .lv-exercise-schedule-grid {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-datepicker {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
              &:last-child {
                .lv-input-field {
                  .k-datepicker {
                    &:not(:focus) {
                      border-top: 0px;
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-fieldset-conversion {
    margin-top: $base-padding-x + 4;
  }

  .lv-sub-fieldset-conversion {
    margin-top: $base-padding-x - 2;
  }

  .conversion-history-button {
    width: 169px;
    padding-left: 5px;
  }

  .lv-header-first-col {
    min-width: 411px;

    .lv-label {
      width: 240px;
    }
  }
  .lv-acquisition-shares {
    width: 633px;
    &-first-column {
      min-width: 303px;
    }
    .lv-label-checkbox-short {
      width: 142px;
    }
  }
  .lv-fieldset-panel .lv-fieldset-panel-content {
    padding: 13px 6px 8px 8px;
  }
  .space-top {
    margin-top: 10px;
  }
}
.popup-acquisition-content {
  max-width: 450px;
  text-align: justify;

  li {
    padding-bottom: calc($base-padding-x / 2);
  }
}
