$grid-col-margin: 4px !default;
$grid-width-margin: 8px !default;

.row {
  &:before,
  &:after {
    content: '';
	  display: table;
  }

  &:after {
    clear: both;
  }
}

.inner-row {
	@extend .row;
	margin-left: -$grid-col-margin;
	margin-right: -$grid-col-margin;
}

.col-12,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2 {
	float: left;
	margin-left: $grid-col-margin;
	margin-right: $grid-col-margin;

	&.no-margin {
		margin: 0;
	}
}

.col-12 {
	width: calc(100% * 1 - #{$grid-width-margin});
	
	&.no-margin {
		width: calc(100% * 1);
	}
}

.col-9 {
	width: calc(100% * 0.75 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.75);
	}
}

.col-8 {
	width: calc(100% * 0.66667 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.66667);
	}
}

.col-7 {
	width: calc(100% * 0.58333 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.58333);
	}
}

.col-6 {
	width: calc(100% * 0.5 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.5);
	}
}

.col-5 {
	width: calc(100% * 0.41667 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.41667);
	}
}

.col-4 {
	width: calc(100% * 0.333333333333333 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.333333333333333);
	}
}

.col-3 {
	width: calc(100% * 0.25 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.25);
	}
}

.col-2 {
	width: calc(100% * 0.166666666666667 - #{$grid-width-margin});

	&.no-margin {
		width: calc(100% * 0.166666666666667);
	}
}


.row,
.inner-row {
	&.padded {
		padding: $grid-col-margin 0;
	}
}

.break-grid {
  &.row,
  >.row {
    >.col-2,
    >.col-3,
    >.col-4,
    >.col-5,
    >.col-6,
    >.col-7,
    >.col-8,
		>.col-9,
		>.col-12 {
			width: calc(100% * 1 - #{$grid-width-margin});
			padding-bottom: $grid-width-margin;
			
			&.no-margin {
				width: calc(100% * 1);
			}

			&.no-padding {
				padding-bottom: 0;
			}
		}
	}
}