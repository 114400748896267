.lv-interest-rates-settings {
  display: block;
  height: 100%;

  .lv-section-settings-system-defaults {
    height: 266px;
  }

  .lv-interest-rates-section-settings-advanced-grid-wrapper {
    margin-top: -25px;
    z-index: 1;

    .k-dropdownlist { 
      min-width: 0!important;
    }

    .lv-interest-rates-yield-curve-settings {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            // .k-grid-edit-ro class is applied to row in edit mode. Border set to 0px in below means, no additional border is added when cell is focused.
            &.k-grid-edit-row {
              .lv-input-field {
                .k-dropdownlist {
                  &.k-focus, &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .lv-interest-rates-section-settings-input {
    z-index: 2;
    border-bottom: 1px solid $base-background-color;
  }
}
