.lv-credit {
  width: 100%;
  height: 100%;

  .lv-credit-tabstrip {
    .k-tabstrip-content {
      padding: 7px 7px;
    }
  }

  lv-credit-issuer {
    padding: $base-padding-x + 4 $base-padding-x + 2;
  }

  .lv-credit-content {
    flex-wrap: wrap;
    overflow: auto;

    .lv-credit-section {
      margin-bottom: $base-padding-x + $base-padding-y;
    }

    .lv-credit-parameters-section{
      margin-right: 22px;
      min-width: 289px;
      .lv-credit-grid-title {
        .lv-label--title {
          padding-top: 9px;
        }
      }
    }
  }

  .k-tabstrip {
    margin-left: -$base-padding-x;
    margin-right: -$base-padding-x;
    margin-bottom: -$base-padding-x;
    flex: 1;
    overflow: hidden;

    .k-tabstrip-content {
      overflow: auto;
    }
  }
}
