.lv-reset-conversion {

  .lv-reset-first-col {
    min-width: 285px;
  }

  .lv-reset-schedule {
    margin-top: 5px;
    .lv-reset-schedule-grid {
      &.lv-advanced-grid {
        .k-grid {
          table tbody tr {
            //.k-grid-edit-row class is applied to row in edit mode. Border set to 0px in below means,no additional border is added when cell is focused.
            &.k-grid-edit-row {
            .lv-input-field {
              .k-numerictextbox, .k-datepicker {
                &.k-focus, &:not(:focus) {
                  border-top: 0px;
                  border-bottom: 0px;
                }
              }
            }
              &:last-child {
                .lv-input-field {
                  &:not(:focus) {
                    border-top: 0px;
                    border-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
