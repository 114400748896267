.lv-access-rights-modal {
  
    .k-dialog-titlebar {
      // height: 27px;
      margin-bottom: 1px;
      border-top: $base-border-color solid 1px;
  }

  .lv-flex-box.lv-flex-box--column {
    flex-direction: column;
  }

  .k-window-content {
    padding: 0px 0px;
     
    .lv-access-rights-content {
      margin: 20px 20px 21px 20px;

        .k-radio-label {
          padding-left: 4px;
          margin-left: 8px;
        }
    
      .lv-field {
        padding: 2px 0;
        line-height: 17px;
        height: 25px;
      }

     .lv-field.lv-field--radio {
       .k-radio {
         margin-top: 3px;
       }
     }

     .lv-field.lv-field--radio.lv-access-rights-content-input-private-label {
       .k-radio-label{
         padding-bottom: 4px;
       }
     }

     .lv-field.lv-field--radio.lv-access-rights-content-input-private-radio {
       .k-radio{
         margin-top: 6px;
       }
     }
    }
  }
  
  .lv-access-rights-modal-footer {
    height: 43px;
    border-top: $base-border-color solid 1px;
    padding: 8.5px 0 9px 0;
    justify-content: flex-end;

    button {
      width: 90px;
      margin-top: 2px;

      &:last-child {
        margin-right: 20px;
        margin-left: 15px;
      }
    }
  }
}