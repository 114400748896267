// color palette
$base-color-white: #FFFFFF;
$base-color-black: #000000;
$base-color-red: #ff6076;
$base-color-yellow: #FFDD00;
$base-color-green: #73D500;
$base-color-blue: #1E8FE1;
$base-color-blue-dark: #0376E3;
$base-color-purple: #C27AEB;
$base-color-lime-green: #03C4C6;
$base-color-orange: #f1b030;
$base-color-gray: #8e939d;

//new colors for padlock icons
$base-icon-color-red: #FF7878;
$base-icon-color-yellow: #FEDD07;
$base-icon-color-green: #47B143;
$base-xl-label-color-green: #64ab11;

// colors
$base-background-color: #1e2128 !default;
$base-input-border-color: $base-background-color;
$base-input-background-color: #434e5b !default;
$base-checkbox-border-color: #5c5f68 !default;
$base-input-color: #eeeeee !default;
$base-output-color: $base-input-color;
$base-border-color: #41454d !default;
$base-section-header-title-color: #d1d1d1 !default;

$base-component-header-color: #32353b !default;
$base-component-border-color: $base-border-color !default;

$base-popup-color: #223746 !default;

$base-text-color: #c7c7c9 !default;
$base-text-color-highlight: $base-color-white !default;
$base-text-color-inactive: #8f9094 !default;
$base-text-color-dimmed: #a5a6a9 !default;

$base-accent-color: $base-color-blue !default;
$base-accent-contrast-color: $base-color-white !default;
$base-accent-highlight-color: #3b6184 !default;

$base-icon-color: $base-text-color-inactive !default;

$base-info-color: $base-color-blue-dark !default;
$base-success-color: $base-color-green !default;
$base-warning-color: $base-color-yellow !default;
$base-error-color: $base-color-red !default;
$base-login-label-color: #44566c !default;

$base-gradient-rainbow: $base-color-red, $base-color-yellow, $base-color-green, $base-color-blue, $base-color-blue-dark, $base-color-purple, $base-color-lime-green;

// typography
$base-font-family: 'Open Sans', sans-serif !default;
$base-font-size: 12px !default;
$base-line-height: 1.25 !default;
$base-line-height-em: $base-line-height * 1em;

// icon
$base-icon-size: 12px !default;
$base-icon-size-lg: 24px !default;
$base-icon-spacing: 6px !default;

// metrics
$base-padding-x: 6px !default;
$base-padding-y: 4px !default;

$base-border-radius: 2px !default;

$base-button-border-width: 0px !default;
$base-button-background-color: $base-accent-color !default;
$base-button-grayed-hover-color: #2c2e35;

$base-input-border-width: 1px !default;
$base-input-placeholder-color: #787a7e !default;

$base-cell-padding-x: 10px !default;
$base-cell-padding-y: 4px !default;
$base-cell-padding: 4px !default;

// media points
$point_1: 1200px;
$point_2: 960px;
$point_3: 600px;
$point_4: 480px;
$point_5: 320px;
$point_6: 768px;
